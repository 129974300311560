import type { ComponentMeta } from '@royalaholddelhaize/ah-analytics';
import { CID_COMPONENT_INTERACTION } from '../../analytics/constants';
import { Article as ArticleComp } from '../../components/article/article';
import type {
    ArticleButtonsType,
    ArticleParagraphType,
    ArticleProps,
} from '../../components/article/article.interface';
import { ArticlePlaceholder } from '../../components/article/placeholder/article-placeholder';
import { ContentCmsComponentType } from '../../enums';
import { ContentArticleBlockType } from '../../graphql/_generated-graphql-types';
import { convertToTitleCase } from '../../helpers/convert-to-title-case';
import type { Model } from '../../interfaces';

export const componentInteractionConverter = ({
    blocks,
    documentUUID,
}: ArticleProps): ComponentMeta | null => {
    if (!blocks?.length) {
        return null;
    }

    // get type of first block
    const firstBlock = blocks[0];
    const { type: subType } = firstBlock;

    return {
        componentType: `${convertToTitleCase(ContentCmsComponentType.ARTICLE)} Content`,
        componentSubType: subType?.toLowerCase(),
        ...(subType === ContentArticleBlockType.BUTTONS && {
            componentTargetUrl:
                (firstBlock as ArticleButtonsType).primaryButton?.href || '',
        }),
        ...(subType === ContentArticleBlockType.PARAGRAPH && {
            componentTitle:
                (firstBlock as ArticleParagraphType).titleAnalytics ||
                (firstBlock as ArticleParagraphType).title ||
                '',
        }),
        ...(subType === ContentArticleBlockType.PARAGRAPH && {
            componentSubtitle:
                (firstBlock as ArticleParagraphType).subtitleAnalytics ||
                (firstBlock as ArticleParagraphType).subtitle,
        }),
        ...(documentUUID && { componentAdditional: documentUUID }),
    };
};

export const Article: Model<ArticleProps> = {
    component: ArticleComp,
    name: ContentCmsComponentType.ARTICLE,
    placeholder: ArticlePlaceholder,
    converters: {
        [CID_COMPONENT_INTERACTION]: componentInteractionConverter,
    },
};
