import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './card-properties.module.scss';

type CardPropertiesProps = ComponentProps<'ul'>;

export const CardProperties: FC<CardPropertiesProps> = ({
    className,
    ...restProps
}) => {
    return (
        <ul className={classNames(css.properties, className)} {...restProps} />
    );
};

type CardPropertyProps = ComponentProps<'li'>;

export const CardProperty: FC<CardPropertyProps> = ({
    className,
    ...restProps
}) => {
    return (
        <li className={classNames(css.property, className)} {...restProps} />
    );
};
