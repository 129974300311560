export const convertToTitleCase = (string: string): string => {
    if (!string) {
        return string;
    }
    // Replace hyphens and commas with underscores
    const formattedType = string.replace(/[-,]/g, '_');

    // Split the type string into words using both underscores and spaces as separators
    const words = formattedType.split(/[_\s]+/);

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
        word => word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase(),
    );

    // Join the words back together with a space separator
    return capitalizedWords.join(' ');
};
