import { ARROW_DOWN_KEY, ARROW_UP_KEY } from '@constants/keycode';
import {
    type ChangeEvent,
    type FormEvent,
    type KeyboardEvent,
    useState,
} from 'react';

const getNextSuggestionIndex = (
    current: number | null,
    suggestions: string[],
    e: KeyboardEvent,
): number | null => {
    if (e.code === ARROW_DOWN_KEY) {
        return current === null || current === suggestions.length - 1
            ? 0
            : current + 1;
    }

    if (e.code === ARROW_UP_KEY) {
        return current === null || current === 0
            ? suggestions.length - 1
            : current - 1;
    }
    return null;
};

export interface UseAutosuggestHookProps {
    suggestions: string[];
    defaultInputValue: string;
    fetchSuggestions: (query: string) => void;
    resetSuggestions: () => void;
}

export interface UseAutoSuggestReturn {
    list: {
        items: string[];
        selectedSuggestion: number | null;
        onSuggestionSelect: (suggestion: string) => void;
        isOpen: boolean;
    };
    input: {
        value: string;
        onFocus: () => void;
        onClear: () => void;
        onChange: (e: ChangeEvent<HTMLInputElement>) => void;
        onKeyDown: (e: KeyboardEvent) => void;
    };
    form: {
        onSubmit: (e: FormEvent) => void;
        onBlur: () => void;
    };
    searchQuery: string | null;
}

export const useAutosuggest = ({
    suggestions,
    fetchSuggestions,
    resetSuggestions,
    defaultInputValue,
}: UseAutosuggestHookProps): UseAutoSuggestReturn => {
    const [searchQuery, setSearchQuery] = useState<string>(defaultInputValue);
    const [listIsOpen, setListIsOpen] = useState<boolean>(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState<number | null>(
        null,
    );
    const inputValue =
        selectedSuggestion !== null
            ? suggestions[selectedSuggestion]
            : searchQuery;

    const onSubmit: UseAutoSuggestReturn['form']['onSubmit'] = e => {
        e.preventDefault();
        resetSuggestions();
        setListIsOpen(false);
        setSearchQuery(inputValue);
        setSelectedSuggestion(null);
    };

    const onFocus: UseAutoSuggestReturn['input']['onFocus'] = () => {
        setListIsOpen(true);
    };

    const onBlur = () => {
        setListIsOpen(false);
        setSelectedSuggestion(null);
    };

    const onSuggestionSelect: UseAutoSuggestReturn['list']['onSuggestionSelect'] =
        suggestion => {
            resetSuggestions();
            setSearchQuery(suggestion);
            setListIsOpen(false);
            setSelectedSuggestion(null);
        };

    const onClear = () => {
        setSearchQuery('');
        resetSuggestions();
        setSelectedSuggestion(null);
    };

    const onChange: UseAutoSuggestReturn['input']['onChange'] = e => {
        const { value } = e.target;
        if (value) {
            setListIsOpen(true);
            setSearchQuery(value);
            fetchSuggestions(value);
            setSelectedSuggestion(null);
        } else {
            onClear();
        }
    };

    const onKeyDown: UseAutoSuggestReturn['input']['onKeyDown'] = e => {
        const newSelectedSuggestion = getNextSuggestionIndex(
            selectedSuggestion,
            suggestions,
            e,
        );
        if (newSelectedSuggestion !== null) {
            setSelectedSuggestion(newSelectedSuggestion);
        }
    };

    return {
        input: {
            value: inputValue,
            onFocus,
            onClear,
            onChange,
            onKeyDown,
        },
        list: {
            items: suggestions,
            selectedSuggestion,
            onSuggestionSelect,
            isOpen: listIsOpen,
        },
        form: {
            onSubmit,
            onBlur,
        },
        searchQuery,
    };
};
