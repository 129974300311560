import { BackToTop } from '@components/elements/back-to-top/back-to-top';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import classNames from 'clsx';
import type { ComponentProps, FC, PropsWithChildren } from 'react';

import css from './allerhande-page.module.scss';

export interface AllerhandePageProps extends ComponentProps<'div'> {
    variant?: 'white' | 'gray';
    hasContainer?: boolean;
    padding?: boolean;
}

export const MAX_CONTAINER_WIDTH = 1488;

export const AllerhandePage: FC<PropsWithChildren<AllerhandePageProps>> = ({
    variant = 'white',
    hasContainer = true,
    padding = true,
    children,
    className,
    ...restProps
}) => {
    let content = (
        <>
            {children}
            <BackToTop />
        </>
    );

    if (hasContainer) {
        content = (
            <Container
                maxWidth={MAX_CONTAINER_WIDTH}
                className={classNames(padding && css.padding, className)}
                {...restProps}
            >
                {content}
            </Container>
        );
    } else {
        content = (
            <div
                className={classNames(padding && css.padding, className)}
                {...restProps}
            >
                {content}
            </div>
        );
    }

    return variant === 'white' ? (
        content
    ) : (
        <div className={css.wrapper}>{content}</div>
    );
};
