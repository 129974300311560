import {
    AnchorButton,
    AnchorButtonText,
} from '@components/elements/button/button';
import { RecipeSteps } from '@components/elements/recipe-steps/recipe-steps';
import {
    RecipeIngredients,
    RecipeIngredientsAddButton,
    RecipeIngredientsKitchen,
    RecipeIngredientsList,
    RecipeIngredientsServing,
    RecipeIngredientsTitle,
} from '@components/features/recipe-ingredients/recipe-ingredients';
import type { RecipeFragment } from '@graphql/_generated-operation-types';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { RecipeProductSuggestionsContext } from '@royalaholddelhaize/ah-ui-recipe-card/src/contexts/recipe-product-suggestions-context';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { type FC, useContext } from 'react';

import css from './recipe-details-content.module.scss';

export interface RecipeDetailsContentProps {
    recipe: RecipeFragment;
    tagLevel: number;
}

export const recipeDetailsButtonLabel = 'Bekijk het volledige recept';
export const shoppableRecipeButtonLabel = 'Zet producten op mijn lijst';

export const RecipeDetailsContent: FC<RecipeDetailsContentProps> = ({
    recipe,
}) => {
    const {
        servings,
        handleOnServingsChange,
        excludedIngredients,
        handleOnExcludeIngredients,
        handleOnOpen,
    } = useContext(RecipeProductSuggestionsContext);
    const { t } = useTranslations('recipe-details');

    return (
        <Grid>
            <GridItem xs={4} md={6}>
                <div className={css.ingredients}>
                    <RecipeIngredients>
                        <RecipeIngredientsTitle variant="heading-1" />

                        <RecipeIngredientsServing
                            recipe={recipe}
                            servings={servings}
                            onChange={handleOnServingsChange}
                        />

                        <RecipeIngredientsList
                            ingredients={recipe.ingredients}
                            excludedIngredients={excludedIngredients}
                            handleOnExcludeIngredients={
                                handleOnExcludeIngredients
                            }
                            servingNumber={servings.number}
                            title={recipe.title}
                        />

                        <RecipeIngredientsAddButton onClick={handleOnOpen}>
                            {t('button.choose-products')}
                        </RecipeIngredientsAddButton>

                        {Boolean(recipe.kitchenAppliances.length) && (
                            <RecipeIngredientsKitchen
                                kitchenAppliances={recipe.kitchenAppliances}
                            />
                        )}
                    </RecipeIngredients>
                </div>
            </GridItem>

            <GridItem xs={4} md={6} className={css.bereiding}>
                <div className={css.buttonHeadingContainer}>
                    <Typography variant="heading-1" as="h3">
                        {t('steps.alternateTitle')}
                    </Typography>

                    <AnchorButton
                        level="secondary"
                        variant="secondary"
                        className={css.receiptDetailsButton}
                        href={recipe.href}
                    >
                        <AnchorButtonText>
                            {recipeDetailsButtonLabel}
                        </AnchorButtonText>
                    </AnchorButton>
                </div>
                <RecipeSteps steps={recipe.preparation.summary} />
            </GridItem>
        </Grid>
    );
};
