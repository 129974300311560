import { memo } from 'react';
import type { ItemList, ListItem } from 'schema-dts';
import { JsonLdSchema } from './json-ld-schema';

export interface JsonLdListItem {
    name: string;
    url: string;
    positionOffset?: number;
}

export interface JsonLdItemListProps {
    name?: string;
    url?: string;
    items: JsonLdListItem[];
}

const createItemListElements = (items: JsonLdListItem[]): ListItem[] =>
    items.map<ListItem>(({ name, url, positionOffset = 0 }, i) => ({
        '@type': 'ListItem',
        position: i + positionOffset,
        name,
        url,
    }));

export const ItemListSchema = memo(
    ({ name, url, items }: JsonLdItemListProps) => {
        const json: ItemList = {
            '@type': 'ItemList',
            name,
            url,
            itemListElement: createItemListElements(items),
        };

        return <JsonLdSchema content={json} />;
    },
);
