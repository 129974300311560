import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { ContentImageVariantType } from '@graphql/_generated-operation-types';
import { createImageSrcSet } from '@helpers/image';
import { getCMSLinkUrl } from '@helpers/url';
import {
    type ComponentMeta,
    EventName,
    createAnalyticsHook,
} from '@royalaholddelhaize/ah-analytics';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { createThemeListCardCollectionLaneCardLink } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-24';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { ThemeListerCard as ThemeListerCardProps } from '../../theme-list-card-collection-lane.interface';
import { ThemeListCTAAnchor } from '../theme-list-cta-anchor/theme-list-cta-anchor';
import { ThemeListCTA } from '../theme-list-cta/theme-list-cta';

import css from './theme-list-card.module.scss';

export const ThemeListCard: FC<ThemeListerCardProps> = ({
    image,
    title,
    links,
    anchor,
    testHook,
}) => {
    const isAnchorId = anchor?.target === 'anchorId';

    const images = image.variants.filter(
        e => e.variant !== ContentImageVariantType.THUMBNAIL,
    );

    return (
        <div className={css.root} {...createTestHook(testHook)}>
            <div className={css.imageWrapper}>
                <div className={css.figure}>
                    <ThemeListCTAAnchor anchor={anchor} isAnchorId={isAnchorId}>
                        <img
                            alt={title}
                            srcSet={createImageSrcSet(images)}
                            className={css.image}
                        />
                    </ThemeListCTAAnchor>
                </div>
            </div>

            <div className={css.content}>
                <div>
                    <ThemeListCTAAnchor anchor={anchor} isAnchorId={isAnchorId}>
                        <Typography variant="heading-3" className={css.heading}>
                            {title}
                        </Typography>
                    </ThemeListCTAAnchor>

                    <ul className={css.linkList}>
                        {links.map((link, idx) => {
                            const cidComponent: ComponentMeta = {
                                componentType: 'Regular link',
                                componentSubType: 'link',
                                componentInnerText: link.label,
                                componentTitle: title,
                                componentTargetUrl: link.url,
                                componentSection: 'Allerhande - Theme pages',
                            };

                            return (
                                <li
                                    key={`${link.label}-${idx}`}
                                    className={css.linkContainer}
                                >
                                    <LinkOrAnchor
                                        className={css.link}
                                        href={getCMSLinkUrl(link.url)}
                                        target={link.target}
                                        aria-label={link.label}
                                        title={link.label}
                                        {...createAnalyticsHook(
                                            EventName.COMPONENT_INTERACTION,
                                            cidComponent,
                                        )}
                                        {...createTestHook(
                                            createThemeListCardCollectionLaneCardLink(
                                                link.label,
                                            ),
                                        )}
                                    >
                                        <ChevronRight24Icon
                                            size="8"
                                            className={css.linkIcon}
                                        />
                                        <span>{link.label}</span>
                                    </LinkOrAnchor>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <ThemeListCTA anchor={anchor} isAnchorId={isAnchorId} />
            </div>
        </div>
    );
};
