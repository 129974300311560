import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import classNames from 'clsx';
import type { FC } from 'react';
import type { RecipeDetailsProps } from '../../recipe-details.interface';
import { RecipeDetailsContentSkeleton } from '../recipe-details-content/recipe-details-content-skeleton';
import css from './recipe-details.module.scss';

export const RecipeDetailsSkeleton: FC<RecipeDetailsProps> = ({ anchorId }) => {
    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            className={classNames({ [css.containerMobile]: isMobile })}
            id={anchorId || undefined}
        >
            <section className={css.root}>
                <div className={css.heroImageContainer} />

                <div className={css.contentContainer}>
                    <Container>
                        <Grid>
                            <GridItem xs={4} className={css.titleContainer}>
                                <Skeleton className={css.headingSkeleton} />
                            </GridItem>

                            <GridItem xs={4}>
                                <div
                                    className={
                                        css.recipeDetailsContentContainer
                                    }
                                >
                                    <RecipeDetailsContentSkeleton
                                        numSteps={3}
                                    />
                                </div>
                            </GridItem>
                        </Grid>
                    </Container>
                </div>
            </section>
        </Container>
    );
};
