import { isBrowser } from '@royalaholddelhaize/ah-next-core/src/utils/is-browser';
import type { KeyboardEvent, MouseEvent } from 'react';

export const PAGE_ANCHOR_OFFSET = 60;

export const pageAnchorOnItemClick = (
    e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>,
) => {
    const target = e.currentTarget.getAttribute('href') || '';
    const isExternalLink = target.substring(0, 1) !== '#';

    if (!isExternalLink) {
        const element =
            typeof document !== 'undefined' &&
            document.getElementById(target.substring(1));
        if (element) {
            window.scrollTo({
                top: element.offsetTop - PAGE_ANCHOR_OFFSET,
                behavior: 'smooth',
            });

            if (isBrowser() && window.history) {
                const currentUrl = window.location.href;

                if (window.location.hash) {
                    window.history.pushState(
                        null,
                        '',
                        currentUrl.replace(window.location.hash, `${target}`),
                    );
                } else {
                    window.history.pushState(
                        null,
                        '',
                        `${currentUrl}${target}`,
                    );
                }
            }

            e.preventDefault();
            return false;
        }
    }

    return true;
};
