import type { ComponentMeta } from '@royalaholddelhaize/ah-analytics';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { onComponentImpression } from '../../analytics/cid-impression';
import { CID_COMPONENT_INTERACTION } from '../../analytics/constants';
import { Impression } from '../../analytics/impression';
import {
    ContentCmsComponentStatus,
    ContentComponentType,
    type LayoutContext,
} from '../../enums';
import type {
    ContentBaseCmsComponent,
    ContentComponent,
} from '../../interfaces';
import type { LegacyModel, Model } from '../../interfaces/model';

type ContentBlockComponentProps = {
    component: ContentComponent;
    model: Model | LegacyModel | null;
    locale: Locale;
    layout?: LayoutContext;
};

export const ContentBlockComponent = ({
    component,
    model,
    locale,
    layout,
}: ContentBlockComponentProps) => {
    if (!model) {
        return null;
    }

    const { component: Component } = model;

    if (component.type === ContentComponentType.COMPONENT) {
        return <Component {...component} />;
    }

    if (
        'componentStatus' in component &&
        component.componentStatus === ContentCmsComponentStatus.INCOMPLETE
    ) {
        return model.placeholder ? (
            //biome-ignore lint/suspicious/noExplicitAny: Needed because of the legacy model
            <model.placeholder {...(component as any)} key={component.id} />
        ) : null;
    }

    const cmsComponent = component as ContentBaseCmsComponent;

    const props = {
        id: cmsComponent.id,
        anchorId: cmsComponent.anchorId,
        tagLevel: cmsComponent.tagLevel,
        locale,
        layout,
        ...cmsComponent.data,
    };

    const componentConverter = model?.converters
        ? model.converters[CID_COMPONENT_INTERACTION]
        : null;

    return (
        <Impression
            onImpression={() => {
                if (
                    !(
                        'componentStatus' in component &&
                        component.componentStatus ===
                            ContentCmsComponentStatus.INCOMPLETE
                    ) &&
                    componentConverter &&
                    onComponentImpression
                ) {
                    onComponentImpression(
                        componentConverter(props) as ComponentMeta,
                    );
                }
            }}
            threshold={componentConverter ? 0 : 0.5}
        >
            <Component {...props} />
        </Impression>
    );
};
