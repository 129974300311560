import {
    type BreadcrumbProps,
    Breadcrumbs,
} from '@components/features/breadcrumbs/breadcrumbs';
import type { FC, PropsWithChildren } from 'react';

export type HeaderBreadcrumbsProps = BreadcrumbProps;

export const HeaderBreadcrumbs: FC<
    PropsWithChildren<HeaderBreadcrumbsProps>
> = props => {
    return <Breadcrumbs {...props} />;
};
