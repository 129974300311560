/*
    adding an HTML class to ensure comments are not added more than once
    this is because the comments are added through the DOM and not by React
    so this function is fired on every re-render of the parent component

    https://github.com/onehippo/hippo-demo-spa-integration/blob/master/spa/react/src/utils/add-html-comment.js
*/

// TODO: Hippo team to accept the component id as data attribute
// so all of this is no longer necessary.
// But they haven't delivered this yet:
// https://issues.onehippo.com/projects/AHOLD/issues/AHOLD-38

export const BEGIN_ATTRIBUTE = 'cms-edit-start';
export const END_ATTRIBUTE = 'cms-edit-end';
export const CONTENT_LINK_ATTRIBUTE = 'cms-edit-link';
export const PAGE_META_DATA_ID = 'cms-page-meta-data';

export const addComment = (
    element: HTMLElement,
    comment: string,
    position: InsertPosition,
    attribute: string,
) => {
    if (element && comment && !element.getAttribute(attribute)) {
        element.insertAdjacentHTML(position, comment);
        element.setAttribute(attribute, '0');
    }
};

export const addPageComments = (element: HTMLDivElement, comment: string) => {
    // bottom outer
    addComment(element, comment, 'afterend', END_ATTRIBUTE);
};

export const addContainerComments = (
    element: HTMLDivElement,
    begin: string,
    end: string,
) => {
    // inner
    addComment(element, begin, 'afterbegin', BEGIN_ATTRIBUTE);
    addComment(element, end, 'beforeend', END_ATTRIBUTE);
};

export const addComponentComments = (
    element: HTMLDivElement,
    begin: string,
    end: string,
) => {
    // outer
    addComment(element, begin, 'beforebegin', BEGIN_ATTRIBUTE);
    addComment(element, end, 'afterend', END_ATTRIBUTE);
};

export const addContentLinkComments = (
    element: HTMLElement,
    comment: string,
) => {
    // top inner
    addComment(element, comment, 'afterbegin', CONTENT_LINK_ATTRIBUTE);
};

export function addBodyComments(
    element: HTMLElement | null,
    id: string,
    comment: string,
) {
    let htmlElement = element;

    if (htmlElement) {
        // cleanup container
        htmlElement.innerHTML = '';
    } else {
        // create container
        htmlElement = document.createElement('div');
        htmlElement.id = id;
        document.body.appendChild(htmlElement);
    }

    htmlElement.insertAdjacentHTML('beforeend', comment);
}
