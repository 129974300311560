import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { type ComponentProps, type FC, useMemo } from 'react';
import { ContentImageVariantType } from '../../../../enums';
import {
    type Maybe,
    QuoteImageAlignmentType,
} from '../../../../graphql/_generated-graphql-types';
import { createImageSrcSet } from '../../../../helpers/create-image-src-set';
import type { ArticleQuoteProps } from './article-quote.interfaces';
import css from './article-quote.module.scss';

export const ArticleQuote: FC<ArticleQuoteProps & ComponentProps<'div'>> = ({
    quote,
    ...restProps
}) => {
    const image = useMemo(() => {
        const small = quote.imageSet?.variants.find(
            v => v.variant === ContentImageVariantType.SMALL,
        );

        if (small) {
            return small;
        }

        return quote.imageSet?.variants[0] || undefined;
    }, [quote]);

    const imageAlignedRight = useMemo(() => {
        return quote.imageAlignment === QuoteImageAlignmentType.RIGHT;
    }, [quote]);

    function getTextAlignment() {
        // When there is no image, the text should be centered
        if (!image) {
            return 'center';
        }

        //otherwise it should be aligned to the left when the image is aligned to the right
        if (imageAlignedRight) {
            return 'left';
        }

        return 'right';
    }

    const textAlign = getTextAlignment();

    const srcset = quote.imageSet
        ? createImageSrcSet(quote.imageSet)
        : undefined;

    const sanitizeId = (str?: Maybe<string>) =>
        str?.replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();

    return (
        <div className={css.root} {...restProps}>
            {!!image && (
                <div
                    className={classNames(imageAlignedRight && css.imageRight)}
                >
                    <img
                        className={css.image}
                        src={image.url}
                        srcSet={srcset}
                        alt={quote.author || ''}
                    />
                </div>
            )}
            <blockquote
                className={classNames(
                    css.quote,
                    textAlign === 'right' ? css.quoteRight : undefined,
                    textAlign === 'center' ? css.textCenter : undefined,
                )}
                aria-labelledby={`quote-${sanitizeId(quote.quote)}`}
                aria-describedby={`quote-author-${sanitizeId(quote.author)}`}
            >
                <Typography
                    aria-label={quote.quote}
                    id={`quote-${sanitizeId(quote.quote)}`}
                    variant="heading-1"
                    as="p"
                    className={css.text}
                    align={textAlign}
                >
                    {`“ ${quote.quote} ”`}
                </Typography>
                {quote.author && (
                    <cite id={`quote-author-${sanitizeId(quote.author)}`}>
                        <Typography
                            variant="heading-3"
                            as="p"
                            align={textAlign}
                            className={css.author}
                        >
                            {quote.author}
                        </Typography>
                    </cite>
                )}
            </blockquote>
        </div>
    );
};
