'use client';

import {
    emitInitializeFavoriteRecipes,
    emitSelectRecipe,
    recipeBatchService,
} from '@royalaholddelhaize/ah-analytics';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_CARD } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { FavoriteFilled24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/favorite-filled-24';
import { FavoriteOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/favorite-outlined-24';
import { VisuallyHidden } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/visually-hidden/visually-hidden';
import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import { type FC, type MouseEvent, type ReactElement, useRef } from 'react';
import { RecipeOriginType } from '../../../graphql/_generated-graphql-types';
import { useRecipeCard } from '../hooks/use-recipe-card';
import type { RecipeCardRecipe } from '../interfaces/recipe-card';
import { ariaTitle } from '../utils/aria-title';
import { getRecipeHref } from '../utils/href';
import { generateRecipeImageRenditions } from '../utils/image-renditions';
import { getCardTranslations } from '../utils/translations';
import { CardBody } from './elements/card-body/card-body';
import { CardContent } from './elements/card-content/card-content';
import { CardFloatingIconCTA } from './elements/card-floating-icon-cta/card-floating-icon-cta';
import { CardImage } from './elements/card-image/card-image';
import {
    CardProperties,
    CardProperty,
} from './elements/card-properties/card-properties';
import { CardText } from './elements/card-text/card-text';
import { Card, type CardProps } from './elements/card/card';

export type RecipeCardProps = {
    recipe: RecipeCardRecipe;
    locale: Locale;
    collectionId?: number;
    cid?: {
        index?: number;
        listType: string;
        listName: string;
    };
    cta?: ReactElement;
    basePath?: string;
} & CardProps;

export const RecipeCard: FC<RecipeCardProps> = ({
    recipe,
    locale,
    collectionId,
    cid,
    cta,
    basePath,
    onClick,
    ...restProps
}) => {
    const { isFavorite, isFavoriteEnabled, openFavorite } = useRecipeCard(
        {
            id: recipe.id,
            type: recipe.author?.origin?.type || RecipeOriginType.ALLERHANDE,
            images: recipe.images || [],
        },
        collectionId,
    );
    const { t } = getCardTranslations(locale);
    const ref = useRef<HTMLAnchorElement>(null);

    useInView({
        ref,
        once: true,
        callback: isInView => {
            if (isInView) {
                recipeBatchService.load({
                    ...recipe,
                    index: cid?.index,
                    listType: cid?.listType,
                    listName: cid?.listName,
                });
            }
        },
    });

    const slug = 'slug' in recipe ? recipe.slug : recipe.slugifiedTitle;
    const time = 'time' in recipe ? recipe.time.cook : recipe.cookTime;

    const isExternalRecipe =
        recipe.author?.origin?.type === RecipeOriginType.MEMBER_SCRAPED;
    const href = getRecipeHref({
        recipeId: recipe.id,
        slug,
        author: recipe.author,
        basePath,
    });

    const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (cid) {
            emitSelectRecipe({
                ...recipe,
                index: cid.index || 0,
                listType: cid.listType,
                listName: cid.listName,
            });
        }

        onClick && onClick(e);
    };

    const handleOnAddToCollectionClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (cid) {
            emitInitializeFavoriteRecipes({
                ...recipe,
                listName: cid.listName,
            });
        }

        openFavorite();
    };

    return (
        <Card
            ref={ref}
            href={href}
            title={`Recept: ${recipe.title}`}
            aria-label={`Recept: ${ariaTitle(recipe, locale)}`}
            target={isExternalRecipe ? '_blank' : '_self'}
            onClick={handleOnClick}
            {...createTestHook(RECIPE_CARD)}
            {...restProps}
        >
            <CardImage
                alt={recipe.title}
                srcSet={generateRecipeImageRenditions(recipe.images)}
            />

            <CardBody>
                <CardProperties>
                    {recipe.author?.origin?.type !==
                        RecipeOriginType.MEMBER_SCRAPED &&
                        Boolean(time) && (
                            <CardProperty>
                                <span aria-hidden>{time} min</span>

                                <VisuallyHidden>
                                    {t('aria.cooktime', { time })}
                                </VisuallyHidden>
                            </CardProperty>
                        )}
                </CardProperties>

                <CardContent>
                    <CardText>{recipe.title}</CardText>
                    {cta}
                </CardContent>
            </CardBody>

            {isFavoriteEnabled && (
                <CardFloatingIconCTA
                    selected={isFavorite}
                    onClick={handleOnAddToCollectionClick}
                    aria-label={
                        isFavorite ? t('editFavorite') : t('addFavorite')
                    }
                >
                    {isFavorite ? (
                        <FavoriteFilled24Icon aria-hidden />
                    ) : (
                        <FavoriteOutlined24Icon aria-hidden />
                    )}
                </CardFloatingIconCTA>
            )}
        </Card>
    );
};
