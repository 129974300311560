import { useRef } from 'react';
import type { Model } from '../../interfaces';
import { findModelWithComponent } from '../helpers/component.helper';
import { getContent } from '../helpers/get-content-by-container.helper';
import { Preview } from '../preview';
import type { ContentBlockProps } from './content-block';

export const ContentBlockPreview = ({
    content,
    models,
    containerName,
}: ContentBlockProps) => {
    // create function only once
    const { current: findModel } = useRef(
        findModelWithComponent(models as Model[]),
    );

    if (!content || !content.components) {
        return null;
    }

    const previewContent = getContent(content, containerName);

    return <Preview content={previewContent} findModel={findModel} />;
};
