import type { ComponentProps, FC } from 'react';

export const StickerGall: FC<ComponentProps<'svg'>> = props => (
    <svg viewBox="0 0 101 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Gall</title>
        <g transform="translate(.75)" fillRule="nonzero" fill="none">
            <circle fill="#FFF" cx="50" cy="50" r="50" />
            <path
                d="M13.565 49.02h5.584l-.04 4.832c0 2.36-2.069 5.247-5.622 5.247-3.437 0-5.448-2.925-5.448-5.247C8.019 52.512 8 51.267 8 49.72c0-1.057.02-2.434.02-3.378C8.039 44.02 10.07 41 13.467 41c3.632 0 5.643 2.963 5.643 5.36h-2.968c0-1.057-.879-2.491-2.655-2.491-1.621 0-2.48 1.528-2.48 2.434 0 .415-.059 1.793-.059 3.397 0 1.944.059 4.133.059 4.133 0 1.057.937 2.397 2.558 2.397 1.679 0 2.577-1.283 2.577-2.66 0-1.379.039-1.7.039-1.7h-2.616v-2.85zM34.124 41.057h2.968v17.967h-2.968zM40.274 41.057h2.968v17.967h-2.968zM31.176 50.644c-.039-2.472-2.128-4.473-4.705-4.473-2.52 0-4.57 1.925-4.686 4.397 0 .076-.02.227-.02.359-.02 1.17 0 3.076 0 3.076v.604c.04 2.623 1.699 4.473 4.003 4.416 1.093-.019 1.972-.623 2.538-1.075.039-.038.117-.095.117-.095v1.151h2.773v-6.586c.02-.472-.02-1.774-.02-1.774zm-2.831 3.812s0 .245-.059.472c-.195.793-.937 1.378-1.815 1.378-.879 0-1.562-.491-1.777-1.227-.078-.34-.078-.604-.078-.604 0-.019-.04-1.963 0-3.642.02-.265.039-.416.058-.472a1.878 1.878 0 0 1 1.816-1.453c.86 0 1.601.585 1.796 1.358.02.095.059.284.059.529v3.661z"
                fill="#393537"
            />
            <path
                d="M50.994 53.57l-1.093.905 1.171 1.34c-.37.264-.898.415-1.386.415-.976 0-1.835-.698-1.835-1.755 0-.528.253-1 .78-1.34.332-.208.762-.34 1.29-.434 1.561-.245 2.42-1.321 2.42-2.586 0-1.434-1.21-2.585-2.675-2.585-1.484 0-2.675 1.17-2.675 2.585 0 .85.39 1.491.86 1.888-.899.547-1.504 1.415-1.504 2.453 0 1.812 1.289 3.152 3.3 3.152a3.99 3.99 0 0 0 2.323-.774l.703.774 1.094-.906-2.773-3.133zm2.773 3.17l-2.773-3.152 2.773 3.152zm-4.1-7.795c.683 0 1.23.548 1.23 1.208 0 .66-.547 1.19-1.23 1.19-.684 0-1.23-.548-1.23-1.19-.02-.68.546-1.208 1.23-1.208z"
                fill="#D27019"
            />
            <path
                fill="#393537"
                d="M81.902 41.057h2.968v17.967h-2.968zM88.052 41.057h2.968v17.967h-2.968zM78.954 50.644c-.04-2.472-2.128-4.473-4.706-4.473-2.518 0-4.569 1.925-4.686 4.397 0 .076-.02.227-.02.359-.019 1.17 0 3.076 0 3.076v.604c.02 2.605 1.68 4.454 3.984 4.416 1.093-.019 1.972-.623 2.538-1.075.04-.038.117-.095.117-.095v1.151h2.773v-6.586c.039-.472 0-1.774 0-1.774zm-2.831 3.812s0 .245-.059.472c-.195.793-.937 1.378-1.816 1.378-.878 0-1.562-.491-1.776-1.227-.079-.34-.079-.604-.079-.604 0-.019-.039-1.963 0-3.642.02-.265.04-.416.059-.472a1.878 1.878 0 0 1 1.816-1.453c.859 0 1.6.585 1.796 1.358.02.095.059.284.059.529v3.661zM61.342 49.02h5.584l-.039 4.832c0 2.36-2.07 5.247-5.623 5.247-3.436 0-5.33-2.925-5.447-5.247-.117-2.189-.137-5.775-.02-7.511.137-2.36 2.05-5.341 5.448-5.341 3.631 0 5.642 2.963 5.642 5.36H63.92c0-1.057-.879-2.491-2.656-2.491-1.62 0-2.42 1.528-2.48 2.434-.175 2.605-.175 5.209 0 7.53.079 1.02.938 2.397 2.558 2.397 1.68 0 2.578-1.283 2.578-2.66 0-1.379.039-1.7.039-1.7h-2.617v-2.85z"
            />
        </g>
    </svg>
);
