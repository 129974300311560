import type { Model } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Article } from '@royalaholddelhaize/ah-ui-cms/src/models/article';
import { ContentListLaneModel } from './content-list-lane/content-list-lane-model';
import { FaqLaneModel } from './faq-lane/faq-lane-model';
import { ImageCardCollectionLaneModel } from './image-card-collection-lane/image-card-collection-lane-model';
import { ProductLaneModel } from './product-lane/product-lane-model';
import { RecipeDetailLaneModel } from './recipe-details/recipe-details-model';
import { RecipeGridLaneModel } from './recipe-grid-lane/recipe-grid-lane-model';
import { RecipeGroupHeaderLaneModel } from './recipe-group-header-lane/recipe-group-header-lane-model';
import { RecipeSearchBannerLaneModel } from './recipe-search-banner-lane/recipe-search-banner-lane-model';
import { ShopInShopLaneModel } from './shop-in-shop-lane/shop-in-shop-lane-model';
import { SpotlightLaneModel } from './spotlight-lane/spotlight-lane-model';
import { SuperShopsModel } from './super-shops/super-shops-model';
import { TextImageItemsContentLaneModel } from './text-image-items-collection-lane/text-image-items-content-lane-model';
import { ThemeLaneModel } from './theme-lane/theme-lane-model';
import { ThemeListCardCollectionLaneModel } from './theme-list-card-collection-lane/theme-list-card-collection-lane-model';
import { TitleLaneModal } from './title-lane/title-lane-model';
import { VideoLaneModal } from './video-lane/video-lane-model';
import { WeekmenuLaneModel } from './weekmenu-lane/weekmenu-lane-model';

export const models: Model[] = [
    TitleLaneModal,
    RecipeSearchBannerLaneModel,
    ThemeListCardCollectionLaneModel,
    ContentListLaneModel,
    VideoLaneModal,
    FaqLaneModel,
    ThemeLaneModel,
    ImageCardCollectionLaneModel,
    TextImageItemsContentLaneModel,
    RecipeDetailLaneModel,
    RecipeGroupHeaderLaneModel,
    WeekmenuLaneModel,
    RecipeGridLaneModel,
    SpotlightLaneModel,
    Article,
    ShopInShopLaneModel,
    SuperShopsModel,
    ProductLaneModel,
];
