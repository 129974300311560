import { PaginationSkeleton } from '@components/features/pagination/pagination-skeleton';
import { QuickFiltersSkeleton } from '@components/features/quick-filters/quick-filters-skeleton';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { RECIPE_GRID_LANE_PLACEHOLDER } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { SkeletonCard } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/skeleton-card';
import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import classNames from 'clsx';
import type { FC } from 'react';
import type { RecipeGridProps } from '../../component.interface';
import { RECIPE_SIZE } from './recipe-grid.constants';
import css from './recipe-grid.module.scss';

export const RecipeGridSkeleton: FC<RecipeGridProps> = ({
    title,
    subtitle,
}) => {
    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            className={css.root}
            {...createTestHook(RECIPE_GRID_LANE_PLACEHOLDER)}
        >
            <Grid className={css.sectionGrid}>
                {(title || subtitle) && (
                    <GridItem xs={4}>
                        {title && (
                            <Skeleton
                                className={classNames(
                                    css.title,
                                    css.titleSkeleton,
                                )}
                            />
                        )}
                        {subtitle && (
                            <Skeleton className={css.subtitleSkeleton} />
                        )}
                    </GridItem>
                )}

                <GridItem xs={4}>
                    <QuickFiltersSkeleton numItems={15} />
                </GridItem>

                {Array(RECIPE_SIZE)
                    .fill(null)
                    .map((_, index: number) => (
                        <GridItem
                            key={`recipe-grid-skeleton-${index.toString()}`}
                            xs={4}
                            md={6}
                            lg={4}
                        >
                            <SkeletonCard />
                        </GridItem>
                    ))}

                <GridItem xs={4}>
                    <PaginationSkeleton />
                </GridItem>
            </Grid>
        </Container>
    );
};
