import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { WEEK_MENU_LANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import { FoodPreferencesSelection } from './elements/food-preferences-selection/food-preferences-selection';
import { FoodPreferencesSkeleton } from './elements/food-preferences-selection/food-preferences-skeleton';
import { Weekmenu, type WeekmenuProps } from './elements/weekmenu/weekmenu';
import { useWeekmenuLane } from './weekmenu-lane-hook';
import type { WeekMenuLaneProps } from './weekmenu-lane.interfaces';

import css from './weekmenu-lane.module.scss';

export const WeekmenuLane: FC<WeekMenuLaneProps> = ({ anchorId, tagLevel }) => {
    const { isMobile, isLoading, firstName, recipeRecommendations } =
        useWeekmenuLane();

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId || undefined}
            className={css.root}
            {...createTestHook(WEEK_MENU_LANE)}
        >
            <Grid>
                <GridItem xs={4}>
                    {isLoading && <FoodPreferencesSkeleton />}

                    {!isLoading &&
                        recipeRecommendations &&
                        recipeRecommendations.length > 0 && (
                            <Weekmenu
                                isMobile={isMobile}
                                firstName={firstName}
                                recipeRecommendations={recipeRecommendations}
                                tagLevel={
                                    (tagLevel || 2) as WeekmenuProps['tagLevel']
                                }
                            />
                        )}

                    {!isLoading &&
                        (!recipeRecommendations ||
                            recipeRecommendations?.length === 0) && (
                            <FoodPreferencesSelection />
                        )}
                </GridItem>
            </Grid>
        </Container>
    );
};
