const isValid = (params: string[]) =>
    params.every(param => {
        // Check if the parameters match the required characters.
        const match = param.match(/[\d\w-]*/i);
        return match ? param === match[0] : false;
    });

export const linkForward = (href: string, currentPage?: string): string => {
    /* eslint-disable no-template-curly-in-string */
    if (!currentPage) {
        return href;
    }

    if (['campaignId', 'uuid'].every(el => href?.includes(el))) {
        const appieUrl =
            decodeURIComponent(currentPage).match(/appieUrl=([^&]*)/);
        const params = appieUrl && appieUrl[1].split('/');

        if (params && !isValid(params)) {
            return '';
        }

        if (params?.length === 2) {
            // The ContentLink href Hippo serves contains a JS template string expression to point out the variables.
            return href
                .replace('${campaignId}', params[0])
                .replace('${uuid}', params[1]);
        }
    }

    return href;
};
