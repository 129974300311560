import type { ContentShopInShopItem } from '../../../graphql/_generated-graphql-types';
import { getAllImageVariants } from '../../../helpers/get-image-variant.helper';
import type { ResponsivePromoImageType } from '../elements/promo-image/promo-image';

export const normalizeImage = (
    item: ContentShopInShopItem,
): ResponsivePromoImageType | undefined => {
    const { images } = item;

    if (!images) {
        return undefined;
    }

    const variants = getAllImageVariants(images);

    return {
        desktop: {
            width: 300,
            url: variants?.large?.url || '',
            title: images.description || '',
        },
        mobile: {
            width: 100,
            url: variants?.small?.url || '',
            title: images.description || '',
        },
    };
};
