import { ComposedImage } from '@components/elements/composed-image/composed-image';
import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { AllerhandeRoute } from '@enums/route';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { THEME_LANE_CATEGORY_CARD } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { CardBody } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/elements/card-body/card-body';
import { CardContent } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/elements/card-content/card-content';
import { Card } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/elements/card/card';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { HighlightedTheme } from '../../component.interface';

import css from './theme-lane-grid.module.scss';

export interface ThemeGridProps {
    highlightedThemes: HighlightedTheme[];
}

export const ThemeLaneGrid: FC<ThemeGridProps> = ({
    highlightedThemes = [],
}) => {
    const { t } = useTranslations();

    return (
        <Grid className={css.grid}>
            {highlightedThemes.map(theme => {
                const path = `${AllerhandeRoute.Home}${theme.link?.url}`;

                return (
                    <GridItem key={path} xs={4} md={6} lg={4} xl={3}>
                        <Card
                            as={LinkOrAnchor}
                            href={path}
                            {...createTestHook(THEME_LANE_CATEGORY_CARD)}
                        >
                            <ComposedImage
                                className={css.imageWrapper}
                                imageGroups={theme.recipes?.map(
                                    recipe => recipe.images,
                                )}
                            />

                            <CardBody>
                                <CardContent>
                                    <Typography variant="body-strong">
                                        {theme.title}
                                    </Typography>
                                    <Typography variant="body-regular">
                                        {theme.count}{' '}
                                        {t('recipe', {
                                            count: theme.count,
                                        })}
                                    </Typography>
                                </CardContent>
                            </CardBody>
                        </Card>
                    </GridItem>
                );
            })}
        </Grid>
    );
};
