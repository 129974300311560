import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';

import css from './article-placeholder.module.scss';

export const ArticlePlaceholder: FC = () => (
    <Container className={css.container} maxWidth={1488}>
        <Grid className={css.root}>
            <GridItem
                xs={4}
                md={6}
                lg={8}
                xl={6}
                lgOffset={2}
                xlOffset={3}
                mdOffset={1}
            >
                <Skeleton variant="rectangle" height={40} />
            </GridItem>
            <GridItem
                xs={4}
                md={6}
                lg={8}
                xl={6}
                lgOffset={2}
                xlOffset={3}
                mdOffset={1}
            >
                <Skeleton variant="rectangle" height={28} width="68%" />
            </GridItem>
            <GridItem
                xs={4}
                md={6}
                lg={8}
                xl={6}
                lgOffset={2}
                xlOffset={3}
                mdOffset={1}
            >
                <Skeleton
                    variant="rectangle"
                    height={24}
                    className={css.text}
                />
                <Skeleton
                    variant="rectangle"
                    height={24}
                    className={css.text}
                    width="80%"
                />
                <Skeleton
                    variant="rectangle"
                    height={24}
                    className={css.text}
                    width="65%"
                />
                <Skeleton
                    variant="rectangle"
                    height={24}
                    className={css.text}
                    width="90%"
                />
            </GridItem>
            <GridItem
                xs={4}
                md={6}
                lg={8}
                xl={6}
                lgOffset={2}
                xlOffset={3}
                mdOffset={1}
            >
                <Skeleton variant="rectangle" height={200} />
            </GridItem>
            <GridItem
                xs={4}
                md={6}
                lg={8}
                xl={6}
                lgOffset={2}
                xlOffset={3}
                mdOffset={1}
            >
                <Skeleton variant="rectangle" height={48} width="50%" />
            </GridItem>
        </Grid>
    </Container>
);
