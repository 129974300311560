import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import type { FC } from 'react';
import {
    ThemeListCTAAnchor,
    type ThemeListerCTAAnchorProps,
} from '../theme-list-cta-anchor/theme-list-cta-anchor';

import ArrowDownIcon from '@assets/icons/arrow-down.svg';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import {
    THEME_LIST_CARD_COLLECTION_LANE_CTA,
    THEME_LIST_CARD_COLLECTION_LANE_EXTERNAL_LINK_ICON,
    THEME_LIST_CARD_COLLECTION_LANE_TARGETED_SCROLL_ICON,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';

import css from './theme-list-cta.module.scss';

type ThemeListerCTAProps = ThemeListerCTAAnchorProps;

export const ThemeListCTA: FC<ThemeListerCTAProps> = ({
    anchor,
    isAnchorId = false,
    ...rest
}) => {
    return (
        <ThemeListCTAAnchor
            anchor={anchor}
            testHook={THEME_LIST_CARD_COLLECTION_LANE_CTA}
            isAnchorId={isAnchorId}
            {...rest}
        >
            <div className={css.root}>
                <span>{anchor.label}</span>
                <span
                    {...createTestHook(
                        isAnchorId
                            ? THEME_LIST_CARD_COLLECTION_LANE_TARGETED_SCROLL_ICON
                            : THEME_LIST_CARD_COLLECTION_LANE_EXTERNAL_LINK_ICON,
                    )}
                >
                    {isAnchorId ? (
                        <ArrowDownIcon width="24" height="24" />
                    ) : (
                        <ArrowRightIcon width="24" height="24" />
                    )}
                </span>
            </div>
        </ThemeListCTAAnchor>
    );
};
