import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import type { ContentBaseCmsComponentProps } from '../../interfaces';

export const ShopInShopPlaceholder: FC<ContentBaseCmsComponentProps> = () => (
    <Container>
        <Skeleton height={32} width={660} />
        <Grid>
            <GridItem xs={4} md={4} lg={4}>
                <Skeleton height={176} width="100%" />
            </GridItem>
        </Grid>
    </Container>
);
