import type { ComponentProps, FC } from 'react';
import { CardImage } from '../card-image/card-image';
import { PlayIcon } from './assets/play-icon';

import css from './card-video.module.scss';

export const CardVideo: FC<ComponentProps<'img'>> = ({ ...restProps }) => {
    return (
        <div className={css.video}>
            <CardImage {...restProps} />
            <div className={css.overlay}>
                <PlayIcon />
            </div>
        </div>
    );
};
