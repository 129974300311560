'use client';

import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_PAGINATION_PLACEHOLDER } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import {
    Pagination as PaginationComp,
    PaginationContent,
    PaginationItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/pagination/pagination';
import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import type { ComponentProps, FC } from 'react';
import { PaginationEllipsis } from './elements';
import css from './pagination.module.scss';

export const PaginationSkeleton: FC<ComponentProps<'nav'>> = props => {
    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);

    return (
        <PaginationComp
            {...props}
            className={css.root}
            {...createTestHook(SEARCH_PAGINATION_PLACEHOLDER)}
        >
            <PaginationContent>
                <PaginationItem>
                    <Skeleton className={css.buttonSkeleton} />
                </PaginationItem>

                {!isMobile ? (
                    <>
                        <PaginationItem>
                            <Skeleton className={css.buttonSkeleton} />
                        </PaginationItem>
                        <PaginationItem>
                            <Skeleton className={css.buttonSkeleton} />
                        </PaginationItem>
                        <PaginationItem>
                            <Skeleton className={css.buttonSkeleton} />
                        </PaginationItem>
                        <PaginationEllipsis className={css.ellipsis} />
                        <PaginationItem>
                            <Skeleton className={css.buttonSkeleton} />
                        </PaginationItem>
                    </>
                ) : (
                    <PaginationItem className={css.pageSummary}>
                        <Skeleton />
                    </PaginationItem>
                )}

                <PaginationItem>
                    <Skeleton className={css.buttonSkeleton} />
                </PaginationItem>
            </PaginationContent>
        </PaginationComp>
    );
};
