import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { Pagination } from '@components/features/pagination/pagination';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { RecipeInteractionProperties } from '@enums/recipe-interaction';
import { AllerhandeRoute } from '@enums/route';
import { listNameCreator } from '@helpers/analytics';
import { PAGE_ANCHOR_OFFSET } from '@helpers/anchor';
import { typographyTagFromTaglevel } from '@helpers/cms';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    RECIPE_GRID_LANE,
    RECIPE_GRID_LANE_SUBTITLE,
    RECIPE_GRID_LANE_TITLE,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { RecipeCard } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/recipe-card';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { RecipeGridProps } from '../../component.interface';
import { RecipeGridLaneQuickLinks } from '../recipe-grid-lane-quick-links/recipe-grid-lane-quick-links';
import { useRecipeGridLane } from './recipe-grid-hook';
import css from './recipe-grid.module.scss';

export const RecipeGrid: FC<RecipeGridProps> = ({
    id,
    anchorId,
    tagLevel,
    title,
    recipeSearchQuery,
    subtitle,
}) => {
    const {
        recipes,
        changePage,
        currentPage,
        totalPages,
        filters,
        originalFilters,
        changeFilter,
    } = useRecipeGridLane(recipeSearchQuery);

    const { t, locale } = useTranslations();

    const scrollReset = () => {
        if (!anchorId) {
            return;
        }

        const element = document.getElementById(anchorId);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - PAGE_ANCHOR_OFFSET * 2,
                behavior: 'smooth',
            });
        }
    };

    const handlePagination = (pageNumber: number) => {
        scrollReset();
        changePage(pageNumber);
    };

    if (recipes && recipes.length > 0) {
        return (
            <Container
                maxWidth={MAX_CONTAINER_WIDTH}
                as="section"
                id={anchorId || undefined}
                className={css.root}
                aria-label={
                    title ||
                    t('search:recipe.result.total', { count: totalPages })
                }
                {...createTestHook(RECIPE_GRID_LANE)}
            >
                <Grid className={css.sectionGrid}>
                    {(title || subtitle) && (
                        <GridItem xs={4}>
                            {title && (
                                <Typography
                                    variant="heading-1"
                                    as={typographyTagFromTaglevel(
                                        tagLevel || 2,
                                    )}
                                    className={css.title}
                                    {...createTestHook(RECIPE_GRID_LANE_TITLE)}
                                >
                                    {title}
                                </Typography>
                            )}

                            {subtitle && (
                                <Typography
                                    variant="body-regular"
                                    as="span"
                                    {...createTestHook(
                                        RECIPE_GRID_LANE_SUBTITLE,
                                    )}
                                >
                                    {subtitle}
                                </Typography>
                            )}
                        </GridItem>
                    )}

                    <GridItem xs={4}>
                        <RecipeGridLaneQuickLinks
                            filters={filters}
                            changeFilter={changeFilter}
                            originalFilters={originalFilters}
                        />
                    </GridItem>

                    {recipes.map((recipe, index: number) => (
                        <GridItem
                            key={`${id}-${recipe.id}-${index}`}
                            xs={4}
                            md={6}
                            lg={4}
                        >
                            <RecipeCard
                                as={LinkOrAnchor}
                                recipe={recipe}
                                locale={locale}
                                cid={{
                                    index,
                                    listType:
                                        RecipeInteractionProperties.RecipeList,
                                    listName: listNameCreator(title),
                                }}
                                basePath={AllerhandeRoute.Home}
                            />
                        </GridItem>
                    ))}

                    <GridItem xs={4}>
                        <Pagination
                            totalPages={totalPages}
                            onPagination={handlePagination}
                            currentPage={currentPage}
                        />
                    </GridItem>
                </Grid>
            </Container>
        );
    }

    return null;
};
