export const apostropheHelper = (name: string): string => {
    /**
     * Rules :
     * So… officially it’s always just an s (example = Daves, Sharons, Jelles weekmenu) , except for:
     *  1. Name’s ending with a, i, o, u of y = ‘s (example = Do’s weekmenu)
     *  2. Name’s ending with umlaut ¨ on the last letter = ‘s (example = Zoë’s weekmenu) - ï, ë, ü, ö
     *  3. People who have filled in only one letter = ‘s (example = S’s weekmenu) (?)
     *  4. Name’s ending with s, x, z = ' (example = Boaz' weekmenu)
     */

    const lastLetter = name.slice(-1).toLowerCase();

    // Rule 1, Rule 2 and Rule 3
    if (/[aiouyëïüö]$/.test(lastLetter) || name.length < 2) {
        return `${name}'s`;
    }
    // Rule 4
    if (/[sxz]$/.test(lastLetter)) {
        return `${name}'`;
    }
    // Default
    return `${name}s`;
};
