import type { RecipeAuthorFragment } from '@graphql/_generated-operation-types';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';

import css from './image-sheet-sponsor.module.scss';

interface ImageSheetSponsorProps {
    brand: RecipeAuthorFragment['brand'];
}

export const ImageSheetSponsor: FC<ImageSheetSponsorProps> = ({ brand }) => {
    return (
        <div className={css.root}>
            {brand?.logo && (
                <img
                    className={css.logo}
                    src={brand?.logo.url}
                    alt={`${brand?.name} logo`}
                />
            )}
            <Typography className={css.name}>{brand?.name}</Typography>
        </div>
    );
};
