import type { ContentThemeConfiguration } from '../../../graphql/_generated-graphql-types';

const DEFAULT_THEME_NAME = 'ah';

export const getContentThemeFromConfig = (
    name: string,
    config?: ContentThemeConfiguration[],
): ContentThemeConfiguration | string => {
    if (!config) {
        return DEFAULT_THEME_NAME;
    }

    const theme = config.find(set => set.theme === name);

    if (!theme) {
        return (
            config.find(set => set.theme === DEFAULT_THEME_NAME) ||
            DEFAULT_THEME_NAME
        );
    }

    return theme;
};
