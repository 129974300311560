import { useMemberQuery } from '@graphql/_generated-hooks';

export const useMember = () => {
    const { data, loading, error } = useMemberQuery();

    return {
        firstName: data?.member?.name?.first || '',
        isLoading: loading,
        error,
    };
};
