import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import type { ContentMediaVideoYoutube } from '../../../../../../graphql/_generated-graphql-types';
import { VideoPreview } from '../video-preview/video-preview';
import css from './article-media-youtube-player.module.scss';

const YOUTUBE_VIDEO_URL = 'https://www.youtube.com/embed/';

interface ArticleMediaYoutubePlayerProps {
    media: ContentMediaVideoYoutube;
    testHookPrefix?: string;
}

export const ArticleMediaYoutubePlayer: FC<
    ArticleMediaYoutubePlayerProps & ComponentProps<'div'>
> = ({ className, media, testHookPrefix = 'content', ...restProps }) => {
    const { imageSet } = media;
    return (
        <div className={classNames(css.root, className)} {...restProps}>
            <VideoPreview
                previewSrc={imageSet}
                blockUntilConsent={true}
                {...createTestHook(`${testHookPrefix}-media-video-preview`)}
                className={css.root}
            >
                <iframe
                    className={css.player}
                    src={`${YOUTUBE_VIDEO_URL}${media.videoId}?autoplay=1`}
                    title="YouTube video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                />
            </VideoPreview>
        </div>
    );
};
