import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { ArrowLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/arrow-left-24';
import { ArrowRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/arrow-right-24';
import { IconButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/icon-button/icon-button';
import { Carousel as CarouselComp } from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel';
import type { CarouselProps as PantryCarouselProps } from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel.interfaces';
import { useScrollButtons } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll-buttons/use-scroll-buttons';
import { type FC, useRef } from 'react';

import css from './carousel.module.scss';

export interface CarouselProps extends PantryCarouselProps {
    locale: Locale;
}

export { CarouselItem } from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel';

export const Carousel: FC<CarouselProps> = ({
    children,
    locale,
    ...restProps
}) => {
    const ref = useRef(null);

    const { t } = useTranslations();

    const { canScrollLeft, canScrollRight, scrollLeft, scrollRight } =
        useScrollButtons({ ref, scrollPercentage: 0.75 });

    return (
        <div className={css.root}>
            <CarouselComp className={css.carousel} ref={ref} {...restProps}>
                {children}
            </CarouselComp>

            {canScrollLeft && (
                <IconButton
                    className={css.buttonLeft}
                    aria-label={t('carousel.buttons.previous')}
                    onClick={scrollLeft}
                    buttonStyle="neutral"
                >
                    <ArrowLeft24Icon size={26} />
                </IconButton>
            )}

            {canScrollRight && (
                <IconButton
                    className={css.buttonRight}
                    aria-label={t('carousel.buttons.next')}
                    onClick={scrollRight}
                    buttonStyle="neutral"
                >
                    <ArrowRight24Icon size={32} />
                </IconButton>
            )}
        </div>
    );
};
