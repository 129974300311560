import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { FC } from 'react';
import {
    ContentListLinkGroup,
    type LinkGroupProps,
} from '../content-list-link-group/content-list-link-group';
import css from './content-list-link-groups.module.scss';

export interface ContentListLinkGroupsProps {
    title?: string;
    groups: LinkGroupProps[];
    fullWidth: boolean;
    tagLevel: number;
    className?: string;
}

const GROUP_SIZE = 5;

/**
 * Why this
 * If one group has links more than max group size, it should be splitted into multiple groups
 * and their columns are conjoined.
 * But then if it's the first or the last group in grid row then conjunction styles should not be applied
 *
 * I haven't splitted this code into JS + CSS, because it's nice to keep the bad part in one place.
 * So in future it's easier to get rid of.
 */
const getSplittedGroups = (
    groups: LinkGroupProps[],
    fullWidth: boolean,
    isMobile: boolean,
): LinkGroupProps[] => {
    return groups.reduce((result, group) => {
        let linksOffset = 0;
        const canBreakIntoSubGroups = group.links.length > GROUP_SIZE;
        let groupsPerColumn = 2;
        if (!isMobile) {
            groupsPerColumn = fullWidth ? 4 : 3;
        }
        while (linksOffset < group.links.length) {
            const isFirstGroupInRow = result.length % groupsPerColumn === 0;
            const isLastGroupInRow =
                (result.length + 1) % groupsPerColumn === 0;
            const hasRightSibling =
                !isLastGroupInRow &&
                canBreakIntoSubGroups &&
                linksOffset + GROUP_SIZE < group.links.length;
            const hasLeftSibling =
                !isFirstGroupInRow && canBreakIntoSubGroups && linksOffset > 0;
            result.push({
                title: linksOffset === 0 ? group.title : '', // conjoined column should not have a title
                links: group.links.slice(linksOffset, linksOffset + GROUP_SIZE),
                hasRightSibling,
                hasLeftSibling,
            });
            linksOffset += GROUP_SIZE;
        }
        return result;
    }, [] as LinkGroupProps[]);
};

export const ContentListLinkGroups: FC<ContentListLinkGroupsProps> = ({
    tagLevel,
    title,
    groups,
    fullWidth,
    className,
}) => {
    const { viewport } = useViewport();
    const isMobile = [Viewport.EXTRA_SMALL, Viewport.SMALL].includes(viewport);

    const resultGroups = isMobile
        ? [...groups]
        : getSplittedGroups(groups, fullWidth, isMobile);

    return (
        <section className={classNames(css.root, className)}>
            {title && (
                <Typography className={css.title} variant="heading-1" as="p">
                    {title}
                </Typography>
            )}
            <Grid>
                {resultGroups.map((group, index) => (
                    <GridItem
                        key={`${group.title}-${index}`}
                        xs={4}
                        md={6}
                        lg={6}
                        xl={fullWidth ? 3 : 4}
                    >
                        <ContentListLinkGroup
                            title={group.title}
                            links={group.links}
                            hasLeftSibling={group.hasLeftSibling}
                            hasRightSibling={group.hasRightSibling}
                            tagLevel={tagLevel}
                        />
                    </GridItem>
                ))}
            </Grid>
        </section>
    );
};
