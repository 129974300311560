import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { Locale } from '@royalaholddelhaize/ah-web-core';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import {
    type ComponentProps,
    type FC,
    type ReactNode,
    type SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import { ContentImageVariantType } from '../../../../../../enums';
import { createImageSrcSet } from '../../../../../../helpers/create-image-src-set';
import { getImageByVariant } from '../../../../../../helpers/get-image-variant.helper';
import { useCookieConsent } from '../../../../../../hooks/use-cookie-consent';
import type { ContentImage } from '../../../../../../interfaces';
import { InformationBlock } from './elements/information-block/information-block';
import { PlayButton } from './elements/play-button/play-button';
import { resources } from './locale';

import css from './video-preview.module.scss';

declare global {
    interface Window {
        gtmdataLayer: unknown[];
    }
}

export interface VideoPreviewProps extends ComponentProps<'div'> {
    /**
     * The preview's image src
     */
    previewSrc?: ContentImage;
    /**
     * Did user accept cookie consent for ads?
     */
    blockUntilConsent?: boolean;

    /**
     * Custom class name
     */
    className?: string;
    /**
     * Video title
     */
    title?: string;
    /**
     * Video decription
     */
    description?: string;
    /**
     * On click preview
     */
    onClick?(event: SyntheticEvent): void;

    /*
     * Elements to be placed within the component
     */
    children?: ReactNode;

    locale?: Locale;
    labels?: {
        youtubeCookieNotAccepted: string;
    };
}

export const VideoPreview: FC<VideoPreviewProps> = ({
    previewSrc,
    className = '',
    title,
    description,
    onClick,
    children,
    blockUntilConsent = false,
    locale = Locale.nl_NL,
    labels,
    ...restProps
}) => {
    const [shouldShowCookieConsent, setShouldShowCookieConsent] =
        useState(false);
    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const { isAdConsent } = useCookieConsent();

    const i18n = {
        ...resources[locale === Locale.en_US ? Locale.nl_NL : locale],
        ...labels,
    };

    const small = getImageByVariant(
        previewSrc?.variants || [],
        ContentImageVariantType.SMALL,
    );
    const srcset = previewSrc ? createImageSrcSet(previewSrc) : '';

    const shouldShowVideoPlayer = blockUntilConsent
        ? isAdConsent && isVideoVisible
        : isVideoVisible;

    const openCookieConfig = () => {
        window.gtmdataLayer = window.gtmdataLayer || [];
        window.gtmdataLayer.push({
            event: 'CONSENT_CONFIG_LAUNCH',
        });
    };

    const VideoCookieNotice = () => (
        <InformationBlock
            testHook="video_preview_cookie-consent"
            className={css.cookieInfo}
            onClick={openCookieConfig}
        >
            <Typography as="span" variant="body-regular">
                {i18n.youtubeCookieNotAccepted}
                <ChevronRight16Icon className={css.icon} />
            </Typography>
        </InformationBlock>
    );

    const handleVideoPreviewClick = () => {
        if (blockUntilConsent && !isAdConsent) {
            return openCookieConfig();
        }

        return setIsVideoVisible(true);
    };

    const PreviewComponent = () => (
        <div
            className={css.imageWrapper}
            {...createTestHook('video_preview_image')}
        >
            {small && (
                <Image
                    className={css.lazyImage}
                    src={small?.url}
                    srcSet={srcset}
                    alt={description || ''}
                    {...createTestHook('lazy_video_image')}
                />
            )}

            <PlayButton
                onClick={handleVideoPreviewClick}
                {...createTestHook('video_preview_play_button')}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        handleVideoPreviewClick();
                    }
                }}
            />
        </div>
    );

    useEffect(() => {
        setShouldShowCookieConsent(!isAdConsent && blockUntilConsent);
    }, [isAdConsent, blockUntilConsent]);

    return (
        <div
            {...createTestHook('video_preview')}
            {...restProps}
            className={classNames(className, css.root)}
        >
            {!shouldShowVideoPlayer && (
                <>
                    <PreviewComponent />

                    {title && (
                        <Typography
                            className={css.heading}
                            variant="heading-3"
                            as="h6"
                        >
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography
                            className={css.description}
                            variant="body-regular"
                        >
                            {description}
                        </Typography>
                    )}
                </>
            )}
            {shouldShowCookieConsent && <VideoCookieNotice />}
            {shouldShowVideoPlayer && children}
        </div>
    );
};
