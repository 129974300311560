import { ButtonGroup } from '@royalaholddelhaize/design-system-pantry-web/components/button/button-group/button-group';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import { ArticleButton } from './article-button';
import type { ArticleButtonsProps } from './article-buttons.interfaces';
import css from './article-buttons.module.scss';

export const ArticleButtons: FC<
    ArticleButtonsProps & ComponentProps<'div'>
> = ({ className, buttons, themeConfig, ...restProps }) => {
    const { primaryButton, secondaryButton } = buttons;

    if (!primaryButton?.href) {
        return null;
    }

    return (
        <ButtonGroup
            className={classNames(css.root, className)}
            {...restProps}
            isNegativePrimaryAction
        >
            {primaryButton && (
                <ArticleButton
                    button={primaryButton}
                    level="primary"
                    themeConfig={themeConfig}
                />
            )}
            {secondaryButton && (
                <ArticleButton
                    button={secondaryButton}
                    level="secondary"
                    themeConfig={themeConfig}
                />
            )}
        </ButtonGroup>
    );
};
