'use client';

import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    VIDEO_CARD,
    VIDEO_CARD_PROPERTIES,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import type { FC } from 'react';
import type { RecipeVideo } from '../../../graphql/_generated-graphql-types';
import { generateVideoImageRenditions } from '../utils/image-renditions';
import { CardBody } from './elements/card-body/card-body';
import { CardContent } from './elements/card-content/card-content';
import {
    CardProperties,
    CardProperty,
    CardPropertyDash,
} from './elements/card-properties/card-properties';
import { CardText } from './elements/card-text/card-text';
import { CardVideo } from './elements/card-video/card-video';
import { Card, type CardProps } from './elements/card/card';

type RecipeVideoCardProps = {
    recipeVideo: Pick<
        RecipeVideo,
        'id' | 'title' | 'href' | 'images' | 'category' | 'duration'
    >;
} & CardProps;

export const RecipeVideoCard: FC<RecipeVideoCardProps> = ({
    recipeVideo,
    ...restProps
}) => (
    <Card
        href={recipeVideo.href || ''}
        title={`Video: ${recipeVideo.title}`}
        {...createTestHook(VIDEO_CARD)}
        {...restProps}
    >
        <CardVideo
            alt={recipeVideo.title}
            srcSet={generateVideoImageRenditions(recipeVideo.images)}
        />

        <CardBody>
            <CardProperties {...createTestHook(VIDEO_CARD_PROPERTIES)}>
                <CardProperty>{recipeVideo.category}</CardProperty>
                <CardPropertyDash />
                <CardProperty>{recipeVideo.duration}</CardProperty>
            </CardProperties>

            <CardContent>
                <CardText>{recipeVideo.title}</CardText>
            </CardContent>
        </CardBody>
    </Card>
);
