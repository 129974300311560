import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { ArrowLeft24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/arrow-left-24';
import { ArrowRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/arrow-right-24';
import { IconButton } from '@royalaholddelhaize/design-system-pantry-web/components/button/icon-button/icon-button';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Carousel } from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { useScrollButtons } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll-buttons/use-scroll-buttons';
import clsx from 'clsx';
import { type FC, type ReactNode, useRef } from 'react';
import { ContentLinkColor } from '../../enums';
import type {
    ContentLink,
    Maybe,
} from '../../graphql/_generated-graphql-types';
import { typographyTagFromTaglevel } from '../../helpers/tag-level';
import { getTranslations } from '../../helpers/translations';
import css from './carousel-wrapper.module.scss';

type title = {
    text: Maybe<string>;
    tagLevel?: Maybe<number>;
};

type CarouselWrapperProps = {
    title: title;
    subTitle?: title;
    seeMoreLink?: Maybe<ContentLink>;
    locale: Locale;
    carouselClassName?: Maybe<string>;
    children: ReactNode;
};

export const CarouselWrapper: FC<CarouselWrapperProps> = ({
    title: { text: title, tagLevel: titleTag } = {},
    subTitle: { text: subTitle, tagLevel: subTitleTag } = {},
    seeMoreLink,
    locale,
    carouselClassName,
    children,
    ...restProps
}) => {
    const ref = useRef(null);
    const { canScrollLeft, canScrollRight, scrollLeft, scrollRight } =
        useScrollButtons({ ref, scrollPercentage: 0.75 });

    const { t } = getTranslations(locale);
    const carouselClasses = clsx(css.carousel, carouselClassName);

    return (
        <Grid className={css.root} {...restProps}>
            <GridItem xs={4}>
                <div className={css.titleRow}>
                    {title && (
                        <Typography
                            variant="heading-1"
                            as={typographyTagFromTaglevel(titleTag || 2)}
                        >
                            {title}
                        </Typography>
                    )}

                    {seeMoreLink && seeMoreLink.href && (
                        <LinkAnchor
                            href={seeMoreLink.href}
                            target={seeMoreLink.target || undefined}
                            rel={
                                seeMoreLink.isExternalLink
                                    ? 'noopener noreferrer'
                                    : undefined
                            }
                            linkButtonStyle={
                                seeMoreLink.theme === ContentLinkColor.BONUS
                                    ? 'discount'
                                    : 'regular'
                            }
                        >
                            <LinkText className={css.seeMoreText}>
                                {seeMoreLink.title}
                            </LinkText>
                        </LinkAnchor>
                    )}
                </div>

                {subTitle && (
                    <div>
                        <Typography
                            variant="body-regular"
                            as={
                                subTitleTag
                                    ? typographyTagFromTaglevel(subTitleTag)
                                    : 'span'
                            }
                        >
                            {subTitle}
                        </Typography>
                    </div>
                )}
            </GridItem>

            <GridItem xs={4} className={css.carouselRow}>
                <Carousel className={carouselClasses} ref={ref}>
                    {children}
                </Carousel>

                {canScrollLeft && (
                    <IconButton
                        className={css.buttonLeft}
                        aria-label={t('carousel.buttons.previous')}
                        onClick={scrollLeft}
                        buttonStyle="neutral"
                        aria-disabled
                        tabIndex={-1}
                    >
                        <ArrowLeft24Icon />
                    </IconButton>
                )}

                {canScrollRight && (
                    <IconButton
                        className={css.buttonRight}
                        aria-label={t('carousel.buttons.next')}
                        onClick={scrollRight}
                        buttonStyle="neutral"
                        aria-disabled
                        tabIndex={-1}
                    >
                        <ArrowRight24Icon />
                    </IconButton>
                )}
            </GridItem>
        </Grid>
    );
};
