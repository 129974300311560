import type { ContentComponent } from '../../interfaces/content-component.interface';
import type { Model } from '../../interfaces/model';

export const isVisible = (component: ContentComponent): boolean => {
    const componentPreview = component?.previewData;
    return Boolean(
        (component && !componentPreview) || componentPreview?.visible,
    );
};

export const findModel = (
    // @ts-ignore
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    models: Record<string, Model<any, any>[]>,
    component: ContentComponent,
): Model | null => {
    if (!isVisible(component)) {
        return null;
    }

    const { type } = component;
    const item = models[type];

    return item?.length ? item[0] : null;
};

export const findModelWithComponent = (
    models: Model[],
): ((component: ContentComponent) => Model | null) => {
    const modelsGrouped: { [key: string]: Model[] } = models.reduce(
        (acc, model) => {
            const key = model.name.toString().toUpperCase();
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(model);
            return acc;
        },
        {} as { [key: string]: Model[] },
    );

    return component => findModel(modelsGrouped, component);
};
