import type { ComponentProps, FC } from 'react';
import { StickerAllerhande } from './images/allerhande';
import { StickerBeter } from './images/beter';
import { StickerEtos } from './images/etos';
import { StickerGall } from './images/gall';
import { StickerNieuw } from './images/nieuw';

export enum StickerType {
    NONE = 'none',
    NEW = 'new',
    BETTER = 'better',
    ETOS = 'etos',
    GALL = 'gall',
    ALLERHANDE = 'allerhande',
}

const stickerMapper: Record<StickerType, FC<ComponentProps<'svg'>>> = {
    [StickerType.NONE]: () => null,
    [StickerType.NEW]: StickerNieuw,
    [StickerType.BETTER]: StickerBeter,
    [StickerType.ETOS]: StickerEtos,
    [StickerType.GALL]: StickerGall,
    [StickerType.ALLERHANDE]: StickerAllerhande,
};

export interface StickerProps extends ComponentProps<'span'> {
    type: StickerType;
}

export const Sticker: FC<StickerProps> = ({ type, className }) => {
    if (!type || type === StickerType.NONE) {
        return null;
    }

    const Sticker = stickerMapper[type];

    return <Sticker className={className} />;
};
