import {
    type ProductsInBasket,
    useProductsInBasket,
} from '@hooks/use-products-in-basket';
import { type FC, type PropsWithChildren, createContext } from 'react';

export interface BasketContext {
    basket: ProductsInBasket | null;
    loading: boolean;
    refetchBasket: () => void;
}

export const BasketContext = createContext<BasketContext>({
    basket: null,
    loading: false,
    refetchBasket: () => {},
});

export const BasketProvider: FC<PropsWithChildren> = ({ children }) => {
    const { data, refetchBasket, loading } = useProductsInBasket();

    return (
        <BasketContext.Provider
            value={{ basket: data, refetchBasket, loading }}
        >
            {children}
        </BasketContext.Provider>
    );
};
