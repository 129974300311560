import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { SuperShops as SuperShopsComp } from '@royalaholddelhaize/ah-ui-cms/src/components/super-shops/super-shops';
import type { SuperShopsProps } from '@royalaholddelhaize/ah-ui-cms/src/components/super-shops/super-shops.interface';
import type { FC } from 'react';

import css from './super-shops.module.scss';

export const SuperShops: FC<SuperShopsProps> = props => {
    return (
        <SuperShopsComp
            {...props}
            maxWidth={MAX_CONTAINER_WIDTH}
            className={css.root}
            data-brand="ah"
        />
    );
};
