export const highlightQueryKeyword = (
    rawSuggestion: string,
    rawQuery: string,
) => {
    const query = rawQuery.toLowerCase();
    const suggestion = rawSuggestion.toLowerCase();
    if (query === suggestion) {
        return query;
    }
    if (suggestion.startsWith(query)) {
        const queryWithOpenStrongTag = `${query}<strong>`;
        return `${suggestion.replace(query, queryWithOpenStrongTag)}</strong>`;
    }
    if (suggestion.endsWith(query)) {
        const queryWithCloseStrongTag = `</strong>${query}`;
        return `<strong>${suggestion.replace(query, queryWithCloseStrongTag)}`;
    }
    return suggestion
        .toLowerCase()
        .split(query)
        .map(text => `<strong>${text}</strong>`)
        .join(query);
};
