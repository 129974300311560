'use client';

import {
    Checkbox,
    CheckboxLabel,
    CheckboxTick,
} from '@components/elements/checkbox/checkbox';
import { ENTER_KEY } from '@constants/keycode';
import type { FilterItem as FilterItemType } from '@interfaces/filter';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_FILTERS_GROUP_ITEM } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { type KeyboardEvent, type PropsWithChildren, forwardRef } from 'react';

import css from './filter-item.module.scss';

export type FilterItemProps = FilterItemType & {
    onSelect: (selected: boolean) => void;
};

export const FilterItem = forwardRef<
    HTMLLIElement,
    PropsWithChildren<FilterItemProps>
>(({ count, label, name, selected, onSelect }, ref) => {
    const { t } = useTranslations();

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === ENTER_KEY) {
            onSelect(!selected);
        }
    };

    return (
        <li
            ref={ref}
            {...createTestHook(SEARCH_FILTERS_GROUP_ITEM)}
            className={classNames(css.header, {
                [css.selected]: selected,
                [css.disabled]: !count,
            })}
        >
            <div className={css.outline}>
                <div className={css.item}>
                    <Checkbox
                        aria-label={`Filter: ${name}, ${count} ${t('recipe', { count })}`}
                        id={name}
                        tabIndex={0}
                        checked={selected}
                        aria-checked={selected}
                        onClick={() => onSelect(!selected)}
                        onKeyDown={e => count && handleKeyDown(e)}
                    >
                        <CheckboxTick aria-hidden />
                        <CheckboxLabel aria-hidden>
                            {label || name}
                        </CheckboxLabel>
                    </Checkbox>
                    <Typography
                        variant={selected ? 'body-strong' : 'body-regular'}
                        as="span"
                        aria-hidden
                    >
                        {count}
                    </Typography>
                </div>
            </div>
        </li>
    );
});
