import { useRecipeRecommendationsQuery } from '@graphql/_generated-hooks';
import type { RecipeSummaryFragment } from '@graphql/_generated-operation-types';

export const useRecipeRecommendations = (skip = false) => {
    const {
        data,
        loading: isLoading,
        error,
    } = useRecipeRecommendationsQuery({
        fetchPolicy: 'network-only',
        skip,
    });

    let filteredRecipeRecommendations: RecipeSummaryFragment[] = [];

    if (data) {
        const {
            recipeRecommendations,
            selectedNutritionAndAllergyPreferences,
        } = data.recipeRecommendationLane;

        const memberPreferences = selectedNutritionAndAllergyPreferences.map(
            pref => pref.value,
        );

        filteredRecipeRecommendations = recipeRecommendations.map(recipe => {
            return {
                ...recipe.recipe,
                flags: recipe.recipe.flags.filter(flag =>
                    memberPreferences?.includes(flag),
                ),
            };
        });
    }

    return {
        recipeRecommendations: filteredRecipeRecommendations || [],
        isLoading,
        error,
    };
};
