import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import type { FC } from 'react';

import css from './promo-image.module.scss';

export type PromoImageType = {
    title: string;
    url: string;
    width: number;
};

export type ResponsivePromoImageType = {
    desktop: PromoImageType;
    mobile: PromoImageType;
};

export interface PromoImageProps {
    images: ResponsivePromoImageType;
    className?: string;
}

export const PromoImage: FC<PromoImageProps> = ({ images, className }) => {
    const imageMobile = images && images.mobile;
    const imageDesktop = images && images.desktop;

    if (!imageMobile || !imageDesktop) {
        return null;
    }

    return (
        <div className={className} aria-hidden="true">
            <Image
                src={imageDesktop.url}
                alt={imageDesktop.title}
                className={css.lazyImage}
            />
        </div>
    );
};
