import {
    ContentCmsComponentType,
    RenditionKey,
} from '@graphql/_generated-operation-types';
import type { Model } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { RecipeDetailsLane } from './recipe-details-lane';
import type { RecipeDetailsProps } from './recipe-details.interface';

const recipeDetailsLaneConverter = (props: RecipeDetailsProps) => {
    if (!props.recipe) {
        return {};
    }
    const { id, type, recipe } = props;

    return {
        ...recipe,
        type,
        id: id.toString(),
        image:
            recipe?.images.find(
                image => image.rendition === RenditionKey.D890X594,
            )?.url || '',
        creative: null,
    };
};

export const RecipeDetailLaneModel: Model = {
    name: ContentCmsComponentType.ALLERHANDE_RECIPE_DETAIL_LANE,
    component: RecipeDetailsLane,
    converters: {
        highlightedRecipe: recipeDetailsLaneConverter,
    },
};
