import {
    AnchorButton,
    AnchorButtonText,
} from '@components/elements/button/button';
import { ItemListSchema } from '@components/elements/json-ld/item-list-schema';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { ContentAllerhandeSpotlightType } from '@graphql/_generated-operation-types';
import { useHostUrl } from '@helpers/client/host';
import { typographyTagFromTaglevel } from '@helpers/cms';
import { spotlightToItemList } from '@helpers/json-ld';
import {
    EventName as CidEventName,
    type ComponentMeta,
    createAnalyticsHook,
} from '@royalaholddelhaize/ah-analytics';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SPOTLIGHT_LANE,
    SPOTLIGHT_LANE_CTA,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import {
    Carousel,
    CarouselItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { SpotlightCard } from './elements/spotlight-card/spotlight-card';
import type { SpotlightLaneProps } from './spotlight-lane.interface';
import css from './spotlight-lane.module.scss';

export const SpotlightLane: FC<SpotlightLaneProps> = ({
    id,
    anchorId,
    tagLevel,
    title,
    subtitle,
    spotlightItems = [],
    cta,
}) => {
    const host = useHostUrl();
    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);

    const cidComponent: ComponentMeta = {
        componentType: 'Grid',
        componentTitle: 'title',
        componentSubType: 'link',
        componentInnerText: cta?.label || '',
        componentTargetUrl: cta?.url || '',
        componentSection: 'Allerhande',
    };

    return (
        <section id={anchorId || undefined} {...createTestHook(SPOTLIGHT_LANE)}>
            {Boolean(title || subtitle) && (
                <Container maxWidth={MAX_CONTAINER_WIDTH}>
                    <Grid>
                        <GridItem className={css.headingWrapper}>
                            {title && (
                                <Typography
                                    variant="display"
                                    as={typographyTagFromTaglevel(
                                        tagLevel || 2,
                                    )}
                                >
                                    {title}
                                </Typography>
                            )}
                            {subtitle && (
                                <Typography variant="heading-2" as="p">
                                    {subtitle}
                                </Typography>
                            )}
                        </GridItem>
                    </Grid>
                </Container>
            )}

            {Boolean(spotlightItems && spotlightItems.length) && (
                <div className={css.cards}>
                    {!isMobile && (
                        <Container maxWidth={MAX_CONTAINER_WIDTH}>
                            <Grid>
                                {spotlightItems.map((spotlight, index) => (
                                    <GridItem
                                        xs={1}
                                        key={`${id}-${spotlight.type === ContentAllerhandeSpotlightType.RECIPE ? spotlight.recipe?.id : spotlight.recipeVideo?.id}-${index}`}
                                    >
                                        <SpotlightCard
                                            index={index}
                                            item={spotlight}
                                            title={title}
                                        />
                                    </GridItem>
                                ))}
                            </Grid>
                        </Container>
                    )}

                    {isMobile && (
                        <Carousel
                            className={css.carousel}
                            gap={'var(--spacing-core-6)'}
                        >
                            {spotlightItems.map((spotlight, index) => (
                                <CarouselItem
                                    key={`${id}-${spotlight.type === ContentAllerhandeSpotlightType.RECIPE ? spotlight.recipe?.id : spotlight.recipeVideo?.id}-${index}`}
                                    className={css.carouselItem}
                                >
                                    <SpotlightCard
                                        index={index}
                                        item={spotlight}
                                        title={title}
                                    />
                                </CarouselItem>
                            ))}
                        </Carousel>
                    )}

                    <ItemListSchema
                        items={spotlightToItemList(host, spotlightItems)}
                    />
                </div>
            )}

            {Boolean(cta) && (
                <Container maxWidth={MAX_CONTAINER_WIDTH}>
                    <Grid>
                        <GridItem className={css.cta}>
                            <AnchorButton
                                href={cta?.url}
                                target={cta?.target}
                                {...createTestHook(SPOTLIGHT_LANE_CTA)}
                                {...createAnalyticsHook(
                                    CidEventName.COMPONENT_INTERACTION,
                                    cidComponent,
                                )}
                            >
                                <AnchorButtonText>
                                    {cta?.label}
                                </AnchorButtonText>
                            </AnchorButton>
                        </GridItem>
                    </Grid>
                </Container>
            )}
        </section>
    );
};
