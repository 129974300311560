import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import css from './rich-content.module.scss';

interface RichContentProps {
    html?: string;
}

export const RichContent: FC<RichContentProps & ComponentProps<'div'>> = ({
    className,
    html,
    ...restProps
}) => {
    return (
        <Typography
            variant="body-regular"
            as="div"
            className={classNames(css.defaultroot, className)}
            //biome-ignore lint/security/noDangerouslySetInnerHtml: This is a CMS component and the HTML is provided by the CMS
            dangerouslySetInnerHTML={{
                __html: html || '',
            }}
            {...restProps}
        />
    );
};
