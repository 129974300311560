import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { AllerhandeRoute } from '@enums/route';
import {
    RecipeFlag,
    type RecipeSummaryFragment,
} from '@graphql/_generated-operation-types';
import { useAuthentication } from '@royalaholddelhaize/ah-next-core/src/authentication/client';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import type { TFunction } from '@royalaholddelhaize/ah-next-core/src/i18n/types';
import { RecipeProductSuggestionsProvider } from '@royalaholddelhaize/ah-ui-recipe-card/src/providers/recipe-product-suggestions-provider';
import { Filter24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/filter-24';
import {
    Carousel,
    CarouselItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { usePathname } from 'next/navigation';
import type { FC } from 'react';
import { apostropheHelper } from '../../helpers/name';
import { WeekmenuRecipeCard } from '../weekmenu-recipe-card/weekmenu-recipe-card';

import css from './weekmenu.module.scss';

export interface WeekmenuProps {
    isMobile: boolean;
    firstName: string;
    recipeRecommendations: RecipeSummaryFragment[];
    tagLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const createClassifications = (flags: RecipeFlag[], t: TFunction) => {
    const recipeFlagMap = {
        [RecipeFlag.GLUTEN_FREE]: 'food-preferences:nutrition.glutenFree',
        [RecipeFlag.LACTOSE_FREE]: 'food-preferences:nutrition.lactoseFree',
        [RecipeFlag.KETO]: 'food-preferences:nutrition.keto',
        [RecipeFlag.LOW_CALORIES]: 'food-preferences:nutrition.lowCalories',
        [RecipeFlag.LOW_CARBS]: 'food-preferences:nutrition.lowCarbs',
        [RecipeFlag.PROTEIN_RICH]: 'food-preferences:nutrition.proteinRich',
    };

    return flags.map(flag => t(recipeFlagMap[flag]));
};

export const Weekmenu: FC<WeekmenuProps> = ({
    isMobile,
    firstName,
    recipeRecommendations,
}) => {
    const { isRegistered } = useAuthentication();
    const { t } = useTranslations('cms');
    const pathname = usePathname();

    const name = firstName ? apostropheHelper(firstName) : t('weekmenu.your');

    return (
        <div className={css.weekmenuWrapper}>
            <Container className={css.headingWrapper}>
                <Typography variant="display" as="p">
                    {t('weekmenu.title', {
                        firstName: name,
                    })}
                </Typography>
                <div className={css.subtitleWrapper}>
                    <Typography variant="heading-2" as="p">
                        {t('weekmenu.subtitle')}

                        <Filter24Icon className={css.icons} aria-hidden />

                        <LinkOrAnchor
                            className={css.preferencesLink}
                            href={`${AllerhandeRoute.FoodPreferences}?webRedirect=${encodeURIComponent(pathname)}`}
                        >
                            {t('weekmenu.subtitle-cta')}
                        </LinkOrAnchor>
                    </Typography>
                </div>
            </Container>

            {isMobile && (
                <Carousel className={css.carousel} snapAlign="start">
                    {recipeRecommendations.map(recipe => (
                        <CarouselItem
                            className={css.carouselItem}
                            key={recipe.id}
                        >
                            <RecipeProductSuggestionsProvider
                                recipeId={recipe.id}
                                initialServings={{
                                    number: recipe.serving.number || 4,
                                    type: recipe.serving.type || 'personen',
                                    min: 0,
                                    max: 40,
                                    isChangeable: true,
                                    scale: 1,
                                }}
                                member={{ isRegistered }}
                            >
                                <WeekmenuRecipeCard recipe={recipe} />
                            </RecipeProductSuggestionsProvider>
                        </CarouselItem>
                    ))}
                </Carousel>
            )}

            {!isMobile && (
                <Container>
                    <div className={css.desktopWeekmenuWrapper}>
                        {recipeRecommendations.map(recipe => (
                            <RecipeProductSuggestionsProvider
                                key={recipe.id}
                                recipeId={recipe.id}
                                initialServings={{
                                    number: recipe.serving.number || 4,
                                    type: recipe.serving.type || 'personen',
                                    min: 0,
                                    max: 40,
                                    isChangeable: true,
                                    scale: 1,
                                }}
                                member={{ isRegistered }}
                            >
                                <WeekmenuRecipeCard recipe={recipe} />
                            </RecipeProductSuggestionsProvider>
                        ))}
                    </div>
                </Container>
            )}
        </div>
    );
};
