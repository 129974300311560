import { HeaderBreadcrumbs } from '@components/features/header/elements/header-breadcrumbs/header-breadcrumbs';
import { HeaderTitle } from '@components/features/header/elements/header-title/header-title';
import { Header } from '@components/features/header/elements/header/header';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import type { ContentTitleFragmentFragment } from '@graphql/_generated-operation-types';
import type { ContentBaseCmsComponent } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';

export type TitleLaneProps = Omit<ContentBaseCmsComponent, 'type'> &
    ContentTitleFragmentFragment['data'];

export const TitleLane: FC<TitleLaneProps> = ({
    title,
    hasOffset,
    tagLevel,
}) => {
    if (!title) {
        return null;
    }

    return (
        <Container maxWidth={MAX_CONTAINER_WIDTH}>
            <Grid>
                <GridItem
                    xs={4}
                    xl={hasOffset ? 8 : undefined}
                    xlOffset={hasOffset ? 2 : undefined}
                >
                    <Header>
                        <HeaderBreadcrumbs />
                        <HeaderTitle tagLevel={tagLevel}>{title}</HeaderTitle>
                    </Header>
                </GridItem>
            </Grid>
        </Container>
    );
};
