import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import {
    ContentMediaBlockType,
    type ContentMediaImage,
    type ContentMediaVideoBlueBillyWig,
    type ContentMediaVideoYoutube,
} from '../../../../graphql/_generated-graphql-types';
import type { ArticleMediaType } from '../../article.interface';
import { ArticleMediaBluebillywigPlayer } from './elements/article-media-bluebillywig-player/article-media-bluebillywig-player';
import { ArticleMediaImage } from './elements/article-media-image/article-media-image';
import { ArticleMediaYoutubePlayer } from './elements/article-media-youtube-player/article-media-youtube-player';

import css from './article-media.module.scss';

const keyExtractor = (media: ArticleMediaType['media'][0], index: number) =>
    `${media.type}-${index}`;

interface ArticleMediaProps {
    id: string;
    media: ArticleMediaType;
}

export const ArticleMedia: FC<ArticleMediaProps & ComponentProps<'div'>> = ({
    className,
    media: { media },
    ...restProps
}) => {
    const hasImagesOnly =
        media?.filter(item => item?.type === ContentMediaBlockType.IMAGE)
            .length === 2;

    return (
        <div
            className={classNames(
                css.root,
                hasImagesOnly && css.hasImagesOnly,
                className,
            )}
            {...restProps}
        >
            {media?.map((m, i) => {
                if (m.type === ContentMediaBlockType.IMAGE) {
                    return (
                        <ArticleMediaImage
                            key={keyExtractor(m, i)}
                            className={css.image}
                            image={m as ContentMediaImage}
                        />
                    );
                }

                if (m.type === ContentMediaBlockType.BLUEBILLYWIG) {
                    return (
                        <ArticleMediaBluebillywigPlayer
                            key={keyExtractor(m, i)}
                            className={css.video}
                            media={m as ContentMediaVideoBlueBillyWig}
                        />
                    );
                }

                if (m.type === ContentMediaBlockType.YOUTUBE) {
                    return (
                        <ArticleMediaYoutubePlayer
                            key={keyExtractor(m, i)}
                            className={css.video}
                            media={m as ContentMediaVideoYoutube}
                        />
                    );
                }

                return null;
            })}
        </div>
    );
};
