import {
    type PromoMeta,
    emitClickPromo,
    promoBatchService,
} from '@royalaholddelhaize/ah-analytics';
import {
    AH_UI_CMS_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { CarouselItem } from '@royalaholddelhaize/design-system-pantry-web/components/layout/carousel/carousel';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import type { FC } from 'react';
import { Impression } from '../../analytics/impression';
import { ContentImageVariantType, ViewType } from '../../enums';
import { convertToTitleCase } from '../../helpers/convert-to-title-case';
import { getImageVariant } from '../../helpers/get-image-variant.helper';
import { getPromotionId } from '../../helpers/promotion';
import { CarouselWrapper } from '../carousel-wrapper/carousel-wrapper';
import { ScrollToAnchorId } from '../scroll-to-anchor-id';
import { SuperShopCard } from './elements/card/super-shop-card';
import type { SuperShopItem, SuperShopsProps } from './super-shops.interface';

export const SuperShops: FC<SuperShopsProps> = ({
    id,
    anchorId,
    title,
    seeMoreLink,
    entries,
    tagLevel,
    componentStatus,
    previewData,
    type,
    locale,
    __typename, // to prevent passing it to the component
    ...restProps
}) => {
    if (!entries?.length) {
        return null;
    }

    const analyticsType = convertToTitleCase(ViewType.SUPER_SHOPS);

    const cidPromo = {
        promoListType: analyticsType,
        promoListName: title,
        promoType: 'Bloomreach CMS',
    } as PromoMeta;

    const shops = entries.map((item, index): SuperShopItem => {
        const promotionId = getPromotionId(id, index);

        return {
            ...item,
            position: index + 1,
            id: promotionId,
        };
    });

    return (
        <Container maxWidth={1488} {...restProps}>
            <ScrollToAnchorId anchorId={anchorId} />
            <CarouselWrapper
                title={{ text: title, tagLevel }}
                seeMoreLink={seeMoreLink}
                locale={locale}
                {...createTestHook(AH_UI_CMS_TESTHOOKS.SUPER_SHOP_LANE)}
            >
                {shops.map(
                    ({
                        href,
                        images,
                        title: shopTitle,
                        titleAnalytics,
                        subtitle,
                        id,
                        isExternalLink,
                        position,
                        campaignTag,
                        documentUUID,
                    }) => {
                        const promo = {
                            ...cidPromo,
                            creativeId: campaignTag || undefined, // comkal id
                            id: documentUUID || '',
                            type: 'Bloomreach CMS',
                            href:
                                getImageVariant(
                                    images.variants,
                                    ContentImageVariantType.SMALL,
                                ) || undefined, // key visual
                            position,
                            title: titleAnalytics || shopTitle,
                        };

                        return (
                            <CarouselItem key={id}>
                                <Impression
                                    onImpression={() =>
                                        promoBatchService.load(promo)
                                    }
                                >
                                    <SuperShopCard
                                        key={id}
                                        href={href || ''}
                                        target={
                                            isExternalLink
                                                ? '_blank'
                                                : undefined
                                        }
                                        rel={
                                            isExternalLink
                                                ? 'noopener noreferrer'
                                                : undefined
                                        }
                                        images={images}
                                        title={shopTitle}
                                        subTitle={subtitle}
                                        onClick={() => emitClickPromo(promo)}
                                    />
                                </Impression>
                            </CarouselItem>
                        );
                    },
                )}
            </CarouselWrapper>
        </Container>
    );
};
