export const PlayIcon = () => {
    return (
        <svg
            width="80"
            height="80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Play</title>
            <circle cx="40" cy="40" r="39" stroke="#fff" strokeWidth="2" />
            <path
                d="M32 30.792v18.416c0 1.609 1.802 2.559 3.13 1.65l13.458-9.207a2 2 0 000-3.302l-13.459-9.208c-1.327-.908-3.129.042-3.129 1.65z"
                fill="#fff"
            />
        </svg>
    );
};
