import { Locale } from '@royalaholddelhaize/ah-web-core';
import { useCallback } from 'react';
import { ContentComponentType, type LayoutContext } from '../../enums';
import type {
    ContentComponent,
    ContentPage,
    ContentRootComponent,
} from '../../interfaces/content-component.interface';
import type { LegacyModel, Model } from '../../interfaces/model';
import { CookieConsentProvider } from '../../providers/cookie-consent-provider';
import { findModelWithComponent } from '../helpers/component.helper';
import { getContent } from '../helpers/get-content-by-container.helper';
import { isContainerComponent } from '../helpers/is-container-component.helper';
import { ContentBlockComponent } from './content-block.component';

export type ContentBlockProps = {
    content: ContentPage;
    isAdConsent: boolean;
    isSocialConsent: boolean;
    locale?: Locale;
    models: (Model | LegacyModel)[];
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onImpression?: (promotionModel: any) => void;
    containerName?: string;
    layout?: LayoutContext;
};

export const ContentBlock = ({
    content,
    models,
    isAdConsent,
    isSocialConsent,
    locale = Locale.nl_NL,
    containerName,
    layout,
}: ContentBlockProps) => {
    // create function only once
    const findModel = useCallback(
        findModelWithComponent(models as Model[]),
        [],
    );

    if (!content?.components) {
        return null;
    }

    const { components } = getContent(content, containerName);

    const submenu = content?.submenu
        ? {
              ...content.submenu,
              type: ContentComponentType.SUBMENU,
          }
        : null;

    const submenuModel = submenu
        ? findModel(submenu as unknown as ContentComponent)
        : null;

    if (submenu?.data && !submenuModel) {
        console.error('Submenu Model is not provided in the app');
    }

    return (
        <CookieConsentProvider
            isAdConsent={isAdConsent}
            isSocialConsent={isSocialConsent}
        >
            {submenu?.data && submenuModel && (
                <ContentBlockComponent
                    key={submenu.id}
                    component={submenu as unknown as ContentComponent}
                    model={submenuModel}
                    locale={locale}
                    layout={layout}
                />
            )}
            {components.map(component =>
                isContainerComponent(component as ContentRootComponent) ? (
                    (component as ContentRootComponent).items
                        ?.filter(
                            nestedComponent =>
                                'data' in nestedComponent &&
                                nestedComponent.data,
                        )
                        ?.map(nestedComponent => (
                            <ContentBlockComponent
                                key={nestedComponent.id}
                                component={nestedComponent}
                                model={findModel(nestedComponent)}
                                locale={locale}
                                layout={layout}
                            />
                        ))
                ) : (
                    <ContentBlockComponent
                        key={component.id}
                        component={component}
                        model={findModel(component)}
                        locale={locale}
                        layout={layout}
                    />
                ),
            )}
        </CookieConsentProvider>
    );
};
