import type { RecipeFragment } from '@graphql/_generated-operation-types';
import { getRecipeSchema } from '@helpers/json-ld';
import type { FC } from 'react';
import { JsonLdSchema } from './json-ld-schema';

interface RecipeDetailsSchemaProps {
    recipe: RecipeFragment;
    host: string;
}

export const RecipeSchema: FC<RecipeDetailsSchemaProps> = ({
    recipe,
    host,
}) => {
    const json = getRecipeSchema(recipe, host);

    return <JsonLdSchema content={json} />;
};
