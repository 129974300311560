import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import {
    ContentImageVariantType,
    type ContentMediaImage,
} from '../../../../../../graphql/_generated-graphql-types';
import { createImageSrcSet } from '../../../../../../helpers/create-image-src-set';
import { getImageByVariant } from '../../../../../../helpers/get-image-variant.helper';
import css from './article-media-image.module.scss';

export interface ArticleMediaImageProps extends ComponentProps<'div'> {
    testHookPrefix?: string;
    image: ContentMediaImage;
}

export const ArticleMediaImage: FC<ArticleMediaImageProps> = ({
    className,
    image,
    testHookPrefix = 'content',
    ...restProps
}) => {
    const { imageSet } = image;

    if (!imageSet || !imageSet.variants) {
        return null;
    }

    const small = getImageByVariant(
        imageSet.variants,
        ContentImageVariantType.SMALL,
    );
    const srcset = createImageSrcSet(imageSet);

    return (
        <div
            className={classNames(css.root, className)}
            {...createTestHook(`${testHookPrefix}-media-image`)}
            {...restProps}
        >
            <img
                src={small?.url}
                srcSet={srcset}
                alt={small?.description || imageSet?.description || ''}
            />
        </div>
    );
};
