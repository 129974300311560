import { useScrollOnHash } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll-on-hash/use-scroll-on-hash';
import { type PropsWithChildren, useRef } from 'react';

import css from './scroll-to-anchor-id.module.scss';

export type AnchorIdProps = {
    anchorId?: string | null;
};

export const ScrollToAnchorId = ({
    anchorId,
    children,
}: PropsWithChildren<AnchorIdProps>) => {
    const ref = useRef<HTMLSpanElement>(null);
    useScrollOnHash({
        ref,
        hash: anchorId,
    });

    if (!anchorId) {
        return children;
    }

    return (
        <>
            <span ref={ref} id={anchorId} className={css.root} />
            {children}
        </>
    );
};
