import type { PriceV2Fragment } from '@graphql/_generated-operation-types';
import { PromotionLabel } from '@royalaholddelhaize/design-system-pantry-web/components/promotion-label/templates/promotion-label/promotion-label';
import { PromotionLabelVariant } from '@royalaholddelhaize/design-system-pantry-web/components/promotion-label/templates/promotion-label/promotion-label.interfaces';

export const getPromotionShield = (price: PriceV2Fragment) => {
    const { promotionShields } = price;
    const shieldWithTitle = promotionShields?.find(shield =>
        Boolean(shield?.title),
    );

    if (shieldWithTitle) {
        return (
            <PromotionLabel
                variant={PromotionLabelVariant.DISCOUNT}
                title={shieldWithTitle.title || ''}
                size="m"
                forceVerticalOutline
                bottomText={shieldWithTitle.defaultDescription || ''}
            />
        );
    }

    if (price.discount?.description) {
        return (
            <PromotionLabel
                variant={PromotionLabelVariant.DISCOUNT}
                bottomText={price.discount.description}
                size="m"
                forceVerticalOutline
            />
        );
    }

    return null;
};
