import {
    RecipeCollectionCategoriesDocument,
    useRecipeCollectionUpdateCategoryMutation,
} from '../../_generated-hooks';
import {
    MutationResultStatus,
    type RecipeCollectionCategoriesQuery,
} from '../../_generated-operation-types';

export interface UseUpdateCategory {
    loading: boolean;
    updateCategory: (id: number, name: string) => void;
}

export const useUpdateCategory = (): UseUpdateCategory => {
    const [updateRecipeCategory, { loading }] =
        useRecipeCollectionUpdateCategoryMutation({
            fetchPolicy: 'network-only',
        });

    const updateCategory = (id: number, name: string): void => {
        updateRecipeCategory({
            variables: { id, name },
            update: (store, { errors, data }) => {
                if (
                    !errors &&
                    data?.recipeCollectionUpdateCategoryV2?.status ===
                        MutationResultStatus.SUCCESS
                ) {
                    store.updateQuery<RecipeCollectionCategoriesQuery>(
                        {
                            query: RecipeCollectionCategoriesDocument,
                        },
                        data => ({
                            recipeCollectionCategories:
                                data?.recipeCollectionCategories.map(
                                    category => {
                                        if (category.id === id) {
                                            return {
                                                ...category,
                                                name,
                                            };
                                        }

                                        return category;
                                    },
                                ) || [],
                        }),
                    );
                }
            },
        }).catch();
    };
    return {
        loading,
        updateCategory,
    };
};
