import { ThemeListCard } from '@components/cms/theme-list-card-collection-lane/elements/theme-list-card/theme-list-card';
import type { ThemeListerCardLaneProps } from '@components/cms/theme-list-card-collection-lane/theme-list-card-collection-lane.interface';
import { ItemListSchema } from '@components/elements/json-ld/item-list-schema';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { useHostUrl } from '@helpers/client/host';
import { createThemeListCardCollectionLaneCard } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { usePathname } from 'next/navigation';
import type { FC } from 'react';
import { getCardUrl } from './helpers/get-card-url';

export const ThemeListCardCollectionLane: FC<ThemeListerCardLaneProps> = ({
    anchorId,
    themeCards,
}) => {
    const pathname = usePathname();
    const host = useHostUrl();

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId || undefined}
        >
            {themeCards && themeCards.length > 0 && (
                <>
                    <Grid alignItems="stretch">
                        {themeCards.map((card, i) => {
                            return (
                                <GridItem key={card.title} xs={4} lg={6} xl={3}>
                                    <ThemeListCard
                                        {...card}
                                        testHook={createThemeListCardCollectionLaneCard(
                                            i + 1,
                                        )}
                                    />
                                </GridItem>
                            );
                        })}
                    </Grid>

                    <ItemListSchema
                        items={themeCards.map(({ title, anchor }) => ({
                            name: title,
                            url: getCardUrl({ pathname, host, anchor }),
                        }))}
                    />
                </>
            )}
        </Container>
    );
};
