import type { NetworkStatus } from '@apollo/client';
import { useRecipeAutosuggestionLazyQuery } from '@graphql/_generated-hooks';
import { useCallback } from 'react';

export interface RecipeAutosuggestionHook {
    suggestions: string[];
    fetchRecipeAutosuggestion: (query: string) => void;
    networkStatus: NetworkStatus;
}

export const useRecipeAutosuggestion = (): RecipeAutosuggestionHook => {
    const [fetchRecipeAutosuggestion, { data, networkStatus, previousData }] =
        useRecipeAutosuggestionLazyQuery({
            fetchPolicy: 'network-only',
            ssr: false,
        });

    const handleFetchRecipeAutosuggestion = useCallback(
        (query: string) => fetchRecipeAutosuggestion({ variables: { query } }),
        [fetchRecipeAutosuggestion],
    );

    return {
        suggestions:
            data?.recipeAutoSuggestions ||
            previousData?.recipeAutoSuggestions ||
            [],
        fetchRecipeAutosuggestion: handleFetchRecipeAutosuggestion,
        networkStatus,
    };
};
