import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { FC } from 'react';
import { getContentThemeFromConfig } from '../../../helpers/theme/get-content-theme-from-config.helper';
import { RichContent } from '../rich-content/rich-content';
import type { ArticleParagraphProps } from './article-paragraph.interfaces';
import css from './article-paragraph.module.scss';

export const ArticleParagraph: FC<ArticleParagraphProps> = ({
    id,
    paragraph,
    className,
    themeConfig,
    ...restProps
}) => {
    if (!paragraph) {
        return null;
    }

    const { title, subtitle, text, contentTheme } = paragraph;
    if ((!title && !subtitle && !text) || !id) {
        return null;
    }

    const theme = getContentThemeFromConfig(contentTheme || '', themeConfig);
    const paragraphClassName = `paragraph-rich-content-${id}`;

    return (
        <div
            className={classNames(css.root, className)}
            {...createTestHook('article-paragraph')}
            {...restProps}
        >
            {contentTheme && theme && typeof theme !== 'string' && (
                <style>{`
                    .${paragraphClassName} ul li:before {
                        background-color: ${theme.primaryColor}
                    }
            `}</style>
            )}
            {title && (
                <Typography
                    variant="heading-1"
                    as="h2"
                    {...createTestHook('article-paragraph-title')}
                    className={css.title}
                >
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography
                    variant="heading-3"
                    as="h3"
                    {...createTestHook('article-paragraph-subtitle')}
                    className={css.subtitle}
                >
                    {subtitle}
                </Typography>
            )}
            {text && (
                <RichContent
                    className={paragraphClassName}
                    html={text}
                    {...createTestHook('article-paragraph')}
                    id={id}
                />
            )}
        </div>
    );
};
