import { useContext } from 'react';
import { CookieConsentContext } from '../contexts/cookie-consent-context';

export const useCookieConsent = () => {
    const context = useContext(CookieConsentContext);

    if (!context) {
        throw new Error(
            'useCookieConsent must be used within a CookieConsentProvider',
        );
    }

    return context;
};
