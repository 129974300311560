import { createContext } from 'react';

export interface CookieConsentContext {
    isAdConsent: boolean;
    isSocialConsent: boolean;
}

export const CookieConsentContext = createContext<CookieConsentContext>({
    isAdConsent: false,
    isSocialConsent: false,
});
