import { createImageSrcSet } from '@helpers/image';
import type { ContentImage } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import classNames from 'clsx';
import type { FC } from 'react';
import css from './content-list-image.module.scss';

export enum ImageOrientation {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface ContentListImageProps {
    imgSet: ContentImage;
    orientation: ImageOrientation;
}

export const ContentListImage: FC<ContentListImageProps> = ({
    imgSet,
    orientation,
}) => {
    const isLeftImage = orientation === ImageOrientation.LEFT;
    const isRightImage = orientation === ImageOrientation.RIGHT;
    const classes = classNames(
        css.image,
        isLeftImage && css.imageLeft,
        isRightImage && css.imageRight,
    );

    return (
        <>
            <Image
                className={classes}
                src={imgSet.variants[0].url}
                srcSet={createImageSrcSet(imgSet.variants)}
                alt="Content list"
            />
            <div className={css[`spacer-${orientation}`]} />
        </>
    );
};
