import { type FC, Suspense } from 'react';
import { RecipeDetails } from './elements/recipe-details/recipe-details';
import { RecipeDetailsSkeleton } from './elements/recipe-details/recipe-details-skeleton';
import type { RecipeDetailsProps } from './recipe-details.interface';

export const RecipeDetailsLane: FC<RecipeDetailsProps> = props => {
    return (
        <Suspense fallback={<RecipeDetailsSkeleton {...props} />}>
            <RecipeDetails {...props} />
        </Suspense>
    );
};
