import { ChevronRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-24';
import { LinkText } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import { LinkOrAnchor } from '../link-or-anchor/link-or-anchor';

import css from './labeled-link.module.scss';

export const LabeledLink: FC<
    ComponentProps<typeof LinkOrAnchor> & { label?: string }
> = ({ label, className, children, ...props }) => {
    return (
        <LinkOrAnchor className={classNames(css.link, className)} {...props}>
            {label && (
                <>
                    {' '}
                    <Typography variant="body-strong">{label}</Typography>
                    <hr className={css.dash} />
                </>
            )}

            <LinkText className={css.title}>{children}</LinkText>

            <ChevronRight24Icon className={css.svg} size={12} />
        </LinkOrAnchor>
    );
};
