import type { FilterItem } from '@interfaces/filter';
import type { FC } from 'react';
import { FilterChip } from '../filter-chip/filter-chip';

export type QuickFilterProps = FilterItem & {
    onSelect: (selected: boolean) => void;
};

export const QuickFilter: FC<QuickFilterProps> = ({
    count,
    label,
    name,
    onSelect,
    selected,
}) => {
    return (
        <FilterChip
            label={label || name || ''}
            amount={selected ? undefined : count}
            isSelected={selected}
            onClick={() => onSelect(!selected)}
        />
    );
};
