import { FilterChipBoolean } from '@royalaholddelhaize/design-system-pantry-web/components/filter-chip/templates/filter-chip-templates';
import type { FilterChipBooleanProps } from '@royalaholddelhaize/design-system-pantry-web/components/filter-chip/templates/filter-chip-templates.interfaces';
import classNames from 'clsx';
import type { FC } from 'react';
import css from './filter-chip.module.scss';

export const FilterChip: FC<FilterChipBooleanProps> = ({
    className,
    ...rest
}) => {
    const { isSelected } = rest;

    return (
        <FilterChipBoolean
            {...rest}
            className={classNames(!isSelected && css.root, className)}
        />
    );
};
