import type {
    ContentBaseCmsComponent,
    ContentComponent,
    ContentPreviewData,
} from '../../interfaces';
import type { Model } from '../../interfaces/model';
import { isVisible } from '../helpers/component.helper';
import { HiddenPlaceholder } from './hidden-placeholder';
import { PreviewComponentWrapper } from './preview-component-wrapper';

type PreviewComponentProps = {
    model: Model | null;
    component: ContentComponent;
    placeComments: (
        element: HTMLDivElement,
        begin: string,
        end: string,
    ) => void;
};

export const PreviewComponent = ({
    component,
    model,
    placeComments,
}: PreviewComponentProps) => {
    const cmsComponent = component as ContentBaseCmsComponent;

    const props = {
        id: cmsComponent.id,
        anchorId: cmsComponent.anchorId,
        tagLevel: cmsComponent.tagLevel,
        ...cmsComponent.data,
    };

    return (
        <PreviewComponentWrapper
            id={component.id}
            key={component.id}
            placeComments={placeComments}
            preview={component.previewData || ({} as ContentPreviewData)}
        >
            {isVisible(component) && model ? (
                <model.component {...props} />
            ) : (
                <HiddenPlaceholder component={component} />
            )}
        </PreviewComponentWrapper>
    );
};
