import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { ShopInShop } from '@royalaholddelhaize/ah-ui-cms/src/components/shop-in-shop/shop-in-shop';
import type { ShopInShopProps } from '@royalaholddelhaize/ah-ui-cms/src/components/shop-in-shop/shop-in-shop.interface';

import css from './shop-in-shop-lane.module.scss';

export const ShopInShopLane = (props: ShopInShopProps) => (
    <ShopInShop
        maxWidth={MAX_CONTAINER_WIDTH}
        className={css.root}
        {...props}
    />
);
