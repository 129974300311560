import { BasketContext } from '@contexts/basket';
import {
    type ProductCardFragment,
    ProductVariantType,
} from '@graphql/_generated-operation-types';
import {
    emitBasketUpdate,
    emitSelectProducts,
    productBatchService,
} from '@royalaholddelhaize/ah-analytics';
import type { ProductCardProps } from '@royalaholddelhaize/design-system-pantry-web/components/product-card/templates/product-card-templates.interfaces';
import { useDebounce } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-debounce/use-debounce';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    useAddBasketItemsMutation,
    useDeleteBasketItemMutation,
    useUpdateBasketItemMutation,
} from '../graphql/_generated-hooks';
import type { ProductsInBasket } from './use-products-in-basket';

interface UseProductCardProps {
    product: ProductCardFragment;
    index: number;
    listName?: string;
}

type UseProductCard = {
    isMutating: boolean;
    isAvailable: boolean;
    primaryLabel?: string;
    secondaryLabel?: string;
    quantity: number;
    product: ProductCardFragment;
    onView: () => void;
} & Pick<
    ProductCardProps,
    | 'onIncrease'
    | 'onDecrease'
    | 'onRemove'
    | 'onUpdate'
    | 'onClickPrimary'
    | 'onClickSecondary'
    | 'onClickCard'
>;

const requireMemberContext = {
    context: {
        headers: {
            'X-Require-Member': true,
        },
    },
};

const getProductVariant = (
    product: ProductCardFragment,
    type: ProductVariantType = ProductVariantType.PRIMARY,
) => {
    if (product.variant?.type === type) {
        return product.variant;
    }

    return product.variants?.find(item => item.type === type);
};

const getBasketQuantity = (
    productId: number,
    basket: ProductsInBasket | null,
): number | null => {
    return (
        basket?.products.find(item => item.id === productId)?.quantity || null
    );
};

const getProductInBasket = (
    basket: ProductsInBasket | null,
    product: ProductCardFragment,
    secondaryProduct?: ProductCardFragment,
): { product: ProductCardFragment; quantity: number } => {
    const mainProductQuantity = getBasketQuantity(product.id, basket);

    if (mainProductQuantity) {
        return { product, quantity: mainProductQuantity };
    }

    const secondaryQuantity = secondaryProduct?.id
        ? getBasketQuantity(secondaryProduct?.id, basket)
        : 0;

    if (secondaryQuantity && secondaryProduct) {
        return {
            product: secondaryProduct as ProductCardFragment,
            quantity: secondaryQuantity,
        };
    }

    return { product, quantity: 0 };
};

const isNewToBasket = (newQuantity: number, currentQuantity: number) =>
    newQuantity > 0 && currentQuantity === 0;

const isUpdatedInBasket = (newQuantity: number, currentQuantity: number) =>
    newQuantity > 0 && currentQuantity > 0;

const isRemovedFromBasket = (newQuantity: number, currentQuantity: number) =>
    newQuantity <= 0 && currentQuantity > 0;

export const useProductCard = ({
    product: inputProduct,
    index,
    listName = 'content',
}: UseProductCardProps): UseProductCard => {
    const context = useContext(BasketContext);
    const [quantity, setQuantity] = useState<number>(0);
    const [product, setProduct] = useState<ProductCardFragment>(inputProduct);
    const primaryProduct = getProductVariant(
        product,
        ProductVariantType.PRIMARY,
    );
    const secondaryProduct = getProductVariant(
        product,
        ProductVariantType.SECONDARY,
    );

    if (!context) {
        throw new Error(
            'The useBasket hook can only be used when wrapped in a BasketProvider',
        );
    }

    const { basket, loading } = context;

    const { quantity: basketQuantity, product: productInBasket } = useMemo(
        () =>
            getProductInBasket(
                basket,
                product,
                secondaryProduct?.product as ProductCardFragment,
            ),
        [product, secondaryProduct, basket],
    );

    const cidProductMeta = useMemo(
        () => ({
            id: productInBasket.id,
            index,
            listName,
        }),
        [productInBasket.id, index, listName],
    );

    const isAvailable =
        productInBasket.availability &&
        'isOrderable' in productInBasket.availability
            ? productInBasket.availability?.isOrderable
            : true;

    const [basketAddItems, { loading: basketAddItemsLoading }] =
        useAddBasketItemsMutation({
            refetchQueries: ['basket'],
            awaitRefetchQueries: true,
        });

    const [basketUpdateItem, { loading: basketUpdateItemLoading }] =
        useUpdateBasketItemMutation({
            refetchQueries: ['basket'],
            awaitRefetchQueries: true,
        });

    const [basketDeleteItem, { loading: basketDeleteItemLoading }] =
        useDeleteBasketItemMutation({
            refetchQueries: ['basket'],
            awaitRefetchQueries: true,
        });

    const isMutating =
        basketAddItemsLoading ||
        basketUpdateItemLoading ||
        basketDeleteItemLoading;

    const emitCidBasket = useCallback(
        (previousQuantity: number, newQuantity: number) => {
            if (cidProductMeta) {
                emitBasketUpdate(cidProductMeta, previousQuantity, newQuantity);
            }
        },
        [cidProductMeta],
    );

    const debouceQuantityUpdate = useCallback(
        (newQuantity: number) => {
            if (newQuantity === basketQuantity) {
                return;
            }

            // Track quantity change
            emitCidBasket(basketQuantity, newQuantity);

            if (isNewToBasket(newQuantity, basketQuantity)) {
                return basketAddItems({
                    variables: {
                        items: [
                            {
                                id: product.id,
                                description: undefined,
                                quantity: newQuantity,
                            },
                        ],
                    },
                    ...requireMemberContext,
                });
            }

            if (isUpdatedInBasket(newQuantity, basketQuantity)) {
                return basketUpdateItem({
                    variables: {
                        item: {
                            id: product.id,
                            quantity: newQuantity,
                            description: undefined,
                        },
                    },
                    ...requireMemberContext,
                });
            }

            if (isRemovedFromBasket(newQuantity, basketQuantity)) {
                setProduct(inputProduct);

                return basketDeleteItem({
                    variables: {
                        item: {
                            id: product.id,
                            description: undefined,
                        },
                    },
                    ...requireMemberContext,
                });
            }
        },
        [
            basketQuantity,
            basketAddItems,
            basketUpdateItem,
            basketDeleteItem,
            product,
            inputProduct,
            emitCidBasket,
        ],
    );

    useDebounce({
        value: quantity,
        delay: 1000,
        callback: debouceQuantityUpdate,
    });

    const memoizedOnView = useMemo(() => {
        return () => {
            // for CID, batch products
            productBatchService.load(cidProductMeta);
        };
    }, [cidProductMeta]);

    const memoizedOnClickCardHandler = useMemo(() => {
        return () => {
            emitSelectProducts([cidProductMeta]);
        };
    }, [cidProductMeta]);

    const onClickPrimary: UseProductCard['onClickPrimary'] = () => {
        if (!primaryProduct || !primaryProduct.product) {
            return;
        }

        setProduct(primaryProduct.product as ProductCardFragment);
        setQuantity(1);
    };

    const onClickSecondary: UseProductCard['onClickSecondary'] = () => {
        if (!secondaryProduct || !secondaryProduct.product) {
            return;
        }

        setProduct(secondaryProduct.product as ProductCardFragment);
        setQuantity(1);
    };

    const onIncrease: UseProductCard['onIncrease'] = () => {
        setQuantity(quantity + 1);
    };

    const onRemove: UseProductCard['onRemove'] = () => {
        setQuantity(0);
    };

    const onDecrease: UseProductCard['onIncrease'] = () => {
        setQuantity(Math.max(0, quantity - 1));
    };

    const onUpdate: UseProductCard['onUpdate'] = newQuantity => {
        setQuantity(newQuantity);
    };

    const setQuantityFromBasket = useCallback(() => {
        if (!loading) {
            setQuantity(basketQuantity);
        }
    }, [loading, basketQuantity]);

    useEffect(() => {
        if (!loading) {
            setQuantityFromBasket();
        }
    }, [loading, setQuantityFromBasket]);

    useEffect(() => {
        /* This can happen when the product variant is
        in the basket instead of the main product */
        if (quantity > 0 && productInBasket !== product) {
            setProduct(productInBasket);
        }
    }, [quantity, productInBasket, product]);

    return {
        isAvailable,
        product,
        isMutating,
        quantity,
        onIncrease,
        onDecrease,
        onUpdate,
        onRemove,
        onClickPrimary,
        onClickSecondary,
        onClickCard: memoizedOnClickCardHandler,
        onView: memoizedOnView,
        primaryLabel: primaryProduct?.label,
        secondaryLabel: secondaryProduct?.label,
    };
};
