import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { TypographyProps } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography.interfaces';
import classNames from 'clsx';
import type { FC, PropsWithChildren, ReactElement } from 'react';

import { typographyTagFromTaglevel } from '@helpers/cms';
import css from './header-title.module.scss';

function fromHeadingLevel(tagLevel: number) {
    switch (tagLevel) {
        case 1:
            return 'display';
        case 2:
            return 'heading-1';
        case 3:
            return 'heading-2';
        default:
            return 'heading-3';
    }
}

interface HeaderTitleProps extends TypographyProps {
    tagLevel?: number | null;
    isLoading?: boolean;
    suffix?: ReactElement | null;
    className?: string;
}

export const HeaderTitle: FC<PropsWithChildren<HeaderTitleProps>> = ({
    tagLevel,
    isLoading = false,
    suffix,
    variant,
    children,
    className,
}) => {
    return (
        <div className={classNames(css.title, className)}>
            <Typography
                variant={variant || fromHeadingLevel(tagLevel || 1)}
                as={typographyTagFromTaglevel(tagLevel || 1)}
            >
                {isLoading ? (
                    <Skeleton className={css.titlePlaceholder} width={150} />
                ) : (
                    children
                )}
            </Typography>
            {suffix}
        </div>
    );
};
