import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import { type PropsWithChildren, useEffect, useRef } from 'react';

interface ImpressionProps {
    onImpression: () => void;
    className?: string;
    testHook?: string;
    as?: string;
    threshold?: number;
}

export const Impression = ({
    children,
    onImpression,
    className,
    testHook,
    as,
    threshold = 0.5,
}: PropsWithChildren<ImpressionProps>) => {
    const ref = useRef<HTMLDivElement>(null);
    const { isInView } = useInView({
        ref,
        threshold,
        once: true,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies(onImpression): <explanation>
    useEffect(() => {
        if (isInView && onImpression) {
            onImpression();
        }
    }, [isInView]);

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const Tag: any = as || 'div';

    return (
        <Tag ref={ref} className={className} data-testhook={testHook}>
            {children}
        </Tag>
    );
};
