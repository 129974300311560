'use client';

import { useContext } from 'react';
import type { CMSData } from '../types';
import { CMSContext } from './context';

export const useCMS = (): CMSData => {
    const context = useContext(CMSContext);

    if (context === null) {
        throw new Error('useCMS must be used within a CMSProvider');
    }

    return context;
};
