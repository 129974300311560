import type { FC } from 'react';
import type { ContentComponent } from '../../../interfaces';
import { isVisible } from '../../helpers/component.helper';

export type HiddenPlaceholderProps = {
    component: ContentComponent;
};

export const HiddenPlaceholder: FC<HiddenPlaceholderProps> = ({
    component,
}) => {
    const visible = isVisible(component);
    return (
        <div data-testhook="hidden-placeholder">
            <h5>
                {visible
                    ? 'Needs viewType to render or component is not available'
                    : 'Hidden Component'}
            </h5>
            <h6>type: {component.type}</h6>
            {'name' in component && <h6>name: {component.name}</h6>}
        </div>
    );
};
