import {
    AH_UI_CMS_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { MediaCard } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/media-card';
import type { ComponentProps, FC } from 'react';
import { ContentImageVariantType } from '../../../../enums';
import { getImageByVariant } from '../../../../helpers/get-image-variant.helper';
import type { ContentImage } from '../../../../interfaces';
import { ImageFallback } from './image-fallback';

import css from './super-shop-card.module.scss';

interface SuperShopCardProps extends ComponentProps<'a'> {
    className?: string;
    title: string;
    href: string;
    images?: ContentImage;
    subTitle?: string | null;
    target?: string;
}

export const SuperShopCard: FC<SuperShopCardProps> = ({
    className,
    title = '',
    subTitle,
    images,
    href,
    target,
    rel,
    ...restProps
}) => {
    // for now we can only use MEDIUM for super shop card because of the specific different ratio of the MEDIUM variant which is only meant for super shop, the others are for marketing carousel,
    // an improvement will be made on the backend in the future to have better quality images + clear distinction
    const image = getImageByVariant(
        images?.variants,
        ContentImageVariantType.MEDIUM,
    );

    return (
        <MediaCard
            {...createTestHook(AH_UI_CMS_TESTHOOKS.SUPER_SHOP_CARD)}
            image={{
                src: image?.url ?? '',
                alt: image?.description ?? '',
                resize: 'fill',
                placeholderIcon: <ImageFallback />,
                placeholderClassName: css.fallbackImg,
                'aria-hidden': true,
            }}
            href={href}
            target={target}
            rel={rel}
            title={title}
            subtitle={subTitle || undefined}
            enableHoverEffect
            {...restProps}
        />
    );
};
