import type { ThemeListerCardLink } from '@components/cms/theme-list-card-collection-lane/theme-list-card-collection-lane.interface';
import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { pageAnchorOnItemClick } from '@helpers/anchor';
import { getCMSLinkUrl } from '@helpers/url';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import type { ComponentProps, FC, KeyboardEvent, MouseEvent } from 'react';

import css from './theme-list-cta-anchor.module.scss';

export interface ThemeListerCTAAnchorProps extends ComponentProps<'a'> {
    anchor: ThemeListerCardLink;
    isAnchorId?: boolean;
    testHook?: string;
}

export const ThemeListCTAAnchor: FC<ThemeListerCTAAnchorProps> = ({
    children,
    isAnchorId = false,
    anchor,
    testHook,
    ...rest
}) => {
    const href = isAnchorId ? `#${anchor.url}` : getCMSLinkUrl(anchor.url);

    const handleClick = (
        e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>,
    ) => {
        if (isAnchorId) {
            pageAnchorOnItemClick(e);
        }
    };

    return (
        <LinkOrAnchor
            className={css.root}
            href={href}
            target={isAnchorId ? undefined : anchor.target}
            onClick={handleClick}
            title={anchor.label}
            aria-label={anchor.label}
            {...createTestHook(testHook)}
            {...rest}
        >
            {children}
        </LinkOrAnchor>
    );
};
