import { ContentComponentType } from '../../enums';
import type { ContentComponent, ContentRootComponent } from '../../interfaces';

export const isContainerComponent = (
    component: ContentComponent,
): component is ContentRootComponent =>
    Boolean(
        component.type === ContentComponentType.COMPONENT &&
            component?.items?.length,
    );
