import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import type { SearchType } from '@constants/search';
import {
    RecipeAutosuggestionDocument,
    RecipeVideoAutosuggestionDocument,
} from '@graphql/_generated-hooks';
import { useRecipeAutosuggestion } from '@graphql/recipe-autosuggestion/recipe-autosuggestion-hook';
import { useRecipeVideoAutosuggestion } from '@graphql/recipe-autosuggestion/recipe-video-autosuggestion-hook';
import { useDebounce } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-debounce/use-debounce';

export interface UseSuggestionsOutput {
    suggestions: string[];
    resetSuggestions: () => void;
    fetchSuggestions: (query: string) => void;
}

const SEARCH_DEBOUNCE = 300;

export const useSuggestions = (
    searchType: SearchType,
): UseSuggestionsOutput => {
    const { cache } = useApolloClient();
    const { fetchRecipeAutosuggestion, suggestions } =
        useRecipeAutosuggestion();
    const { fetchRecipeVideoAutosuggestion, videoSuggestions } =
        useRecipeVideoAutosuggestion();
    const [query, setQuery] = useState<string | null>(null);

    const isVideoSearch = searchType === 'VIDEO';
    const queryDocument = isVideoSearch
        ? RecipeVideoAutosuggestionDocument
        : RecipeAutosuggestionDocument;
    const fetchSuggetions = isVideoSearch
        ? fetchRecipeVideoAutosuggestion
        : fetchRecipeAutosuggestion;

    const debouncedFetchSuggestions = useCallback(() => {
        if (query) {
            fetchSuggetions(query);
        }
    }, [query, fetchSuggetions]);

    const resetAutosuggestionsAction = () => {
        cache.writeQuery({
            variables: { query: '' },
            query: queryDocument,
            data: {
                [isVideoSearch
                    ? 'recipeVideoAutoSuggestions'
                    : 'recipeAutoSuggestions']: [],
            },
        });
        fetchSuggetions('');
    };

    useDebounce({
        callback: debouncedFetchSuggestions,
        delay: SEARCH_DEBOUNCE,
        value: query,
    });

    return {
        suggestions: isVideoSearch ? videoSuggestions : suggestions,
        fetchSuggestions: setQuery,
        resetSuggestions: resetAutosuggestionsAction,
    };
};
