import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import { type FC, useMemo } from 'react';
import css from './quick-filters.module.scss';

export interface QuickFiltersSkeletonProps {
    numItems: number;
}

const MIN_WIDTH = 90;
const MAX_WIDTH = 160;

export const QuickFiltersSkeleton: FC<QuickFiltersSkeletonProps> = ({
    numItems,
}) => {
    const widths = useMemo(() => {
        return Array(numItems)
            .fill(null)
            .map(() =>
                Math.round(MIN_WIDTH + Math.random() * (MAX_WIDTH - MIN_WIDTH)),
            );
    }, [numItems]);

    return (
        <div className={css.root}>
            <ul className={css.list}>
                {Array(numItems)
                    .fill(null)
                    .map((_, index) => {
                        return (
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            <li key={index}>
                                <Skeleton
                                    className={css.skeletonFilter}
                                    width={widths[index]}
                                />
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};
