import {
    AH_UI_CMS_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { useScrollOnHash } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll-on-hash/use-scroll-on-hash';
import { type FC, useRef } from 'react';
import {
    ContentArticleBlockType,
    type ContentThemeConfiguration,
} from '../../graphql/_generated-graphql-types';
import type { ArticleContentBlock, ArticleProps } from './article.interface';
import { ArticleButtons } from './elements/buttons/article-buttons';
import { ArticleMedia } from './elements/media/article-media';
import { ArticleParagraph } from './elements/paragraph/article-paragraph';
import { ArticleQuote } from './elements/quote/article-quote';
import { ArticleTable } from './elements/table/article-table';

import css from './article.module.scss';

const renderItem = (
    item: ArticleContentBlock,
    index: number,
    themeConfig?: ContentThemeConfiguration[],
    articleId?: string,
) => {
    switch (item?.type) {
        case ContentArticleBlockType.PARAGRAPH:
            return (
                <ArticleParagraph
                    paragraph={item}
                    key={`${item.type}${index}`}
                    id={`${articleId}${item.type}${index}`}
                    className={css.paragraph}
                    themeConfig={themeConfig}
                />
            );

        case ContentArticleBlockType.MEDIA:
            return (
                <ArticleMedia
                    media={item}
                    id={`${articleId}${item.type}${index}`}
                    key={`${item.type}${index}`}
                    className={css.media}
                />
            );

        case ContentArticleBlockType.BUTTONS:
            return (
                <ArticleButtons
                    buttons={item}
                    key={`${item.type}${index}`}
                    themeConfig={themeConfig}
                />
            );

        case ContentArticleBlockType.QUOTE:
            return <ArticleQuote quote={item} key={`${item.type}${index}`} />;

        case ContentArticleBlockType.TABLE:
            return <ArticleTable list={item} key={`${item.type}${index}`} />;

        default:
            return null;
    }
};

export const Article: FC<ArticleProps> = ({
    id,
    blocks,
    anchorId,
    themeConfig,
    tagLevel,
    locale,
    documentUUID, // to prevent passing it to the component
    __typename, // to prevent passing it to the component
    ...restProps
}) => {
    const renderItems = (blocks as ArticleContentBlock[])
        ?.map((item, index: number) => renderItem(item, index, themeConfig, id))
        .filter(Boolean);

    const articleRef = useRef(null);

    useScrollOnHash({
        hash: anchorId,
        ref: articleRef,
    });

    if (!renderItems?.length) {
        return null;
    }

    return (
        <Container maxWidth={1488} {...restProps}>
            <Grid className={css.root}>
                <GridItem
                    md={6}
                    lg={8}
                    xl={6}
                    lgOffset={2}
                    xlOffset={3}
                    mdOffset={1}
                >
                    <div
                        ref={articleRef}
                        {...createTestHook(AH_UI_CMS_TESTHOOKS.ARTICLE)}
                    >
                        {renderItems}
                    </div>
                </GridItem>
            </Grid>
        </Container>
    );
};
