import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import type {
    ContentVideoLaneFragmentFragment,
    RecipeVideoFragment,
} from '@graphql/_generated-operation-types';
import type { ContentBaseCmsComponent } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import { VideoLaneItem } from './elements/video-lane-item/video-lane-item';

export type VideoLaneProps = Omit<ContentBaseCmsComponent, 'type'> &
    ContentVideoLaneFragmentFragment['data'];

export const VideoLane: FC<VideoLaneProps> = ({
    id,
    videos,
    anchorId,
    tagLevel,
}) => {
    if (!videos) return null;

    const filteredVideos = videos
        .map(({ video }) => video)
        .filter((item): item is RecipeVideoFragment => Boolean(item));

    // The CMS only supports selecting one video, so we only take the first one
    const [firstVideo] = filteredVideos;

    if (!firstVideo) return null;

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId || undefined}
        >
            <Grid id={id}>
                <GridItem sm={4}>
                    <VideoLaneItem
                        video={firstVideo}
                        tagLevel={tagLevel || 2}
                    />
                </GridItem>
            </Grid>
        </Container>
    );
};
