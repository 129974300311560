import {
    AnchorButton,
    AnchorButtonText,
} from '@components/elements/button/button';
import { ItemListSchema } from '@components/elements/json-ld/item-list-schema';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { useHostUrl } from '@helpers/client/host';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { THEME_LANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import type { ThemeLaneProps } from './component.interface';
import { ThemeLaneGrid } from './elements/theme-lane-grid/theme-lane-grid';
import { ThemeLaneHeader } from './elements/theme-lane-header/theme-lane-header';
import css from './theme-lane.module.scss';

export const ThemeLane: FC<ThemeLaneProps> = ({
    title,
    subtitle,
    links,
    anchorId,
    tagLevel = 2,
    cta,
}) => {
    const { t } = useTranslations('cms');
    const host = useHostUrl();

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId}
            aria-label={t('theme.title')}
            {...createTestHook(THEME_LANE)}
        >
            <ThemeLaneHeader
                title={title}
                subtitle={subtitle}
                tagLevel={tagLevel}
            />

            <ThemeLaneGrid highlightedThemes={links} />

            {cta && (
                <Grid>
                    <GridItem className={css.callToAction}>
                        <AnchorButton
                            href={cta.url}
                            target={cta.target}
                            className={css.callToAction}
                        >
                            <AnchorButtonText>{cta.label}</AnchorButtonText>
                        </AnchorButton>
                    </GridItem>
                </Grid>
            )}

            <ItemListSchema
                items={links.map(({ title, link }) => ({
                    name: title,
                    url: `${host}${link.url}`,
                }))}
            />
        </Container>
    );
};
