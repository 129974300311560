'use client';

import { createContext } from 'react';
import type { CMSData } from '../types';

export const CMSContext = createContext<CMSData>({
    isPreview: false,
    isExternalPreview: false,
    timeTravelData: {},
    previewToken: null,
    serverId: null,
});
