import { RecipeSteps } from '@components/elements/recipe-steps/recipe-steps';
import {
    RecipeIngredients,
    RecipeIngredientsTitle,
} from '@components/features/recipe-ingredients/recipe-ingredients';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { type FC, useMemo } from 'react';

import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import css from './recipe-details-content.module.scss';

export type RecipeDetailsContentSkeletonProps = {
    numSteps: number;
};

export const RecipeDetailsContentSkeleton: FC<
    RecipeDetailsContentSkeletonProps
> = ({ numSteps }) => {
    const { t } = useTranslations('recipe-details');

    const steps = useMemo(() => {
        return Array(numSteps)
            .fill(null)
            .map((_, index) => {
                return (
                    <Skeleton
                        className={css.stepSkeleton}
                        height={200}
                        key={`recipe-details-content-skeleton-${index.toString()}`}
                    />
                );
            });
    }, [numSteps]);

    return (
        <Grid>
            <GridItem xs={4} md={6}>
                <div className={css.ingredients}>
                    <RecipeIngredients>
                        <RecipeIngredientsTitle variant="heading-1" />

                        <Skeleton className={css.servingsSkeleton} />

                        <Skeleton className={css.ingredientsSkeleton} />
                    </RecipeIngredients>
                </div>
            </GridItem>

            <GridItem xs={4} md={6} className={css.bereiding}>
                <div className={css.buttonHeadingContainer}>
                    <Typography variant="heading-1" as="h3">
                        {t('steps.alternateTitle')}
                    </Typography>
                </div>
                <RecipeSteps steps={steps} />
            </GridItem>
        </Grid>
    );
};
