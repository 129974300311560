import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { FOOD_PREFERENCES_SKELETON_LOADER } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';
import type { FC } from 'react';

import css from './food-preferences-selection.module.scss';

export const FoodPreferencesSkeleton: FC = () => {
    return (
        <div
            className={css.wrapper}
            {...createTestHook(FOOD_PREFERENCES_SKELETON_LOADER)}
        >
            <div className={css.imageWrapper}>
                <Skeleton className={css.image} width="100%" height="375px" />
            </div>
            <div className={css.selectionWrapper}>
                <Skeleton
                    width="560px"
                    height="40px"
                    style={{ marginBottom: '8px' }}
                />
                <Skeleton width="600px" height="65px" />

                <div className={css.selectionButtonsWrapper}>
                    <div className={css.logInButtons}>
                        <Skeleton
                            className={css.logInButton}
                            width="100%"
                            height="50px"
                        />
                        <Skeleton
                            className={css.logInButton}
                            width="100%"
                            height="50px"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
