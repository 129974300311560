'use client';

import { SearchInput } from '@components/elements/search-input/search-input';
import { SearchType } from '@constants/search';
import { useAutosuggest } from '@hooks/use-autosuggest';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_SUGGESTIONS } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { useOutsideClick } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-outside-click/use-outside-click';
import classNames from 'clsx';
import { type FC, type FormEvent, useRef } from 'react';
import { useSuggestions } from '../../../hooks/use-suggestions';
import { Suggestions } from '../../elements/suggestions/suggestions';

import css from './recipe-autosuggest.module.scss';

type RecipeAutosuggestProps = {
    className?: string;
    onSearch: (searchQuery: string) => void;
    defaultInputValue?: string;
    searchType: SearchType;
    placeholder?: string;
    variant?: 'default' | 'big';
};

export const RecipeAutosuggest: FC<RecipeAutosuggestProps> = ({
    defaultInputValue = '',
    onSearch,
    searchType,
    placeholder,
    className,
    variant = 'default',
    ...restProps
}) => {
    const { t } = useTranslations('search');

    const ref = useRef<HTMLFormElement>(null);

    const { suggestions, fetchSuggestions, resetSuggestions } =
        useSuggestions(searchType);

    const {
        form: { onSubmit, onBlur },
        list,
        input,
        searchQuery,
    } = useAutosuggest({
        suggestions,
        fetchSuggestions,
        resetSuggestions,
        defaultInputValue,
    });

    const handleSubmit = (e: FormEvent) => {
        onSubmit(e);
        onSearch(input.value || '');
    };

    const handleClear = () => {
        resetSuggestions();
        onSearch('');
    };

    const tPrefix = searchType === SearchType.VIDEO ? 'video' : 'recipe';

    useOutsideClick({
        ref,
        callback: () => {
            onBlur();
        },
    });

    return (
        <div className={classNames(css.root, className)} {...restProps}>
            <form onSubmit={handleSubmit} ref={ref}>
                <SearchInput
                    {...input}
                    id="search-input"
                    aria-label={t(`${tPrefix}.input.placeholder`)}
                    aria-required
                    placeholder={
                        placeholder || t(`${tPrefix}.input.placeholder`)
                    }
                    onClear={handleClear}
                    variant={variant}
                />

                {list.isOpen && list.items.length !== 0 && (
                    <div
                        className={css.suggestionsWrapper}
                        {...createTestHook(SEARCH_SUGGESTIONS)}
                    >
                        <Suggestions
                            suggestions={list.items}
                            query={searchQuery || ''}
                            onSuggestionInteract={suggestion => {
                                list.onSuggestionSelect(suggestion);
                                onSearch(suggestion);
                            }}
                            selectedSuggestionIndex={list.selectedSuggestion}
                        />
                    </div>
                )}
            </form>
        </div>
    );
};
