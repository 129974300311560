import(/* webpackMode: "eager", webpackExports: ["CMSPage"] */ "/__w/ah-venture/ah-venture/apps/ah-allerhande/src/app/allerhande/[[...slug]]/_components/cms-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/__w/ah-venture/ah-venture/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/__w/ah-venture/ah-venture/packages/ah-i18n/dist/context/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["I18NProvider"] */ "/__w/ah-venture/ah-venture/packages/ah-i18n/dist/provider/provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CMSContext"] */ "/__w/ah-venture/ah-venture/packages/ah-next-core/src/cms/client/context.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CMSProvider"] */ "/__w/ah-venture/ah-venture/packages/ah-next-core/src/cms/client/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useCMS"] */ "/__w/ah-venture/ah-venture/packages/ah-next-core/src/cms/client/use-cms.ts");
