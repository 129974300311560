import { RichContent } from '@components/elements/rich-content/rich-content';
import { HeaderTitle } from '@components/features/header/elements/header-title/header-title';
import { createImageSrcSet } from '@helpers/image';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import classNames from 'clsx';
import type { FC } from 'react';
import {
    type BlockProps,
    Orientation,
} from '../../text-image-items-content-lane.interfaces';

import css from './text-image-item.module.scss';

export const TextImageItem: FC<BlockProps> = ({
    isMobile,
    orientation,
    title,
    description,
    images,
    tagLevel,
}) => {
    const isLeftOrientation = orientation === Orientation.LEFT;

    if (isMobile || isLeftOrientation) {
        return (
            <Grid alignItems="stretch">
                <GridItem xs={4} lg={6}>
                    {images && images.length > 0 && (
                        <Image
                            className={css.image}
                            src={images[0]?.url}
                            srcSet={createImageSrcSet(images)}
                            alt={title}
                            resize="fill"
                        />
                    )}
                </GridItem>

                <GridItem xs={4} lg={6}>
                    <div
                        className={classNames(
                            css.textWrapper,
                            isLeftOrientation && css.textWrapperRight,
                        )}
                    >
                        <HeaderTitle
                            className={css.heading}
                            variant="heading-1"
                            tagLevel={tagLevel}
                        >
                            {title}
                        </HeaderTitle>

                        <RichContent html={description} />
                    </div>
                </GridItem>
            </Grid>
        );
    }

    return (
        <Grid alignItems="stretch">
            <GridItem xs={4} md={6}>
                <div
                    className={classNames(css.textWrapper, css.textWrapperLeft)}
                >
                    <HeaderTitle
                        className={css.heading}
                        variant="heading-1"
                        tagLevel={tagLevel}
                    >
                        {title}
                    </HeaderTitle>
                    <RichContent html={description} />
                </div>
            </GridItem>

            <GridItem xs={4} lg={6}>
                {images && images.length > 0 && (
                    <Image
                        className={css.image}
                        src={images[0]?.url}
                        srcSet={createImageSrcSet(images)}
                        alt={title}
                        resize="fill"
                    />
                )}
            </GridItem>
        </Grid>
    );
};
