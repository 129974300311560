import {
    RecipeCollectionCategoriesDocument,
    useRecipeCollectionDeleteCategoryMutation,
} from '../../_generated-hooks';
import {
    MutationResultStatus,
    type RecipeCollectionCategoriesQuery,
} from '../../_generated-operation-types';

export interface UseDeleteCategory {
    loading: boolean;
    deleteCategory: (id: number) => void;
}

export const useDeleteCategory = (): UseDeleteCategory => {
    const [deleteRecipeCategory, { loading }] =
        useRecipeCollectionDeleteCategoryMutation({
            fetchPolicy: 'network-only',
        });

    const deleteCategory = (id: number): void => {
        deleteRecipeCategory({
            variables: { id },
            update: (store, { errors, data }) => {
                if (
                    !errors &&
                    data?.recipeCollectionDeleteCategoryV2.status ===
                        MutationResultStatus.SUCCESS
                ) {
                    store.updateQuery<RecipeCollectionCategoriesQuery>(
                        {
                            query: RecipeCollectionCategoriesDocument,
                        },
                        data => ({
                            recipeCollectionCategories:
                                data?.recipeCollectionCategories.filter(
                                    category => category.id !== id,
                                ) || [],
                        }),
                    );
                }
            },
        }).catch();
    };
    return {
        loading,
        deleteCategory,
    };
};
