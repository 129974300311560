import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import type { ContentAllerhandeRecipeSearchBannerFragmentFragment } from '@graphql/_generated-operation-types';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_BANNER_LANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import type { ContentBaseCmsComponent } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import { Banner } from './elements/banner/banner';

export type RecipeSearchBannerLaneProps = Omit<
    ContentBaseCmsComponent,
    'type'
> &
    ContentAllerhandeRecipeSearchBannerFragmentFragment['data'];

export const RecipeSearchBannerLane: FC<RecipeSearchBannerLaneProps> = ({
    anchorId,
    tagLevel = 2,
    recipe,
    searchHintShort,
    searchHint,
    quickSearchEntries,
}) => {
    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId || undefined}
            {...createTestHook(SEARCH_BANNER_LANE)}
        >
            <Grid>
                <GridItem lg={12} xl={10} xlOffset={1}>
                    <Banner
                        recipe={recipe}
                        searchHint={searchHint}
                        searchHintShort={searchHintShort}
                        quickSearchEntries={quickSearchEntries}
                        tagLevel={tagLevel}
                    />
                </GridItem>
            </Grid>
        </Container>
    );
};
