import { RecipeSchema } from '@components/elements/json-ld/recipe-schema';
import { ImageSheetImage } from '@components/features/image-sheet/elements/image-sheet-image/image-sheet-image';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { useRecipeSuspenseQuery } from '@graphql/_generated-hooks';
import { useHostUrl } from '@helpers/client/host';
import { useAuthentication } from '@royalaholddelhaize/ah-next-core/src/authentication/client';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { RecipeProductSuggestionsProvider } from '@royalaholddelhaize/ah-ui-recipe-card/src/providers/recipe-product-suggestions-provider';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { FC } from 'react';
import { RecipeDetailsContent } from '../../elements/recipe-details-content/recipe-details-content';
import type { RecipeDetailsProps } from '../../recipe-details.interface';
import css from './recipe-details.module.scss';

export const RecipeDetails: FC<RecipeDetailsProps> = ({
    tagLevel,
    title,
    recipeId,
    anchorId,
}) => {
    const host = useHostUrl();
    const { isRegistered } = useAuthentication();
    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);

    if (!recipeId) {
        return null;
    }

    // biome-ignore lint/correctness/useHookAtTopLevel: Prevent component render & fetch when recipe id is undefined
    const { data } = useRecipeSuspenseQuery({
        variables: {
            id: recipeId,
        },
    });

    if (!data?.recipe || !data?.recipe.id) {
        return null;
    }

    const { recipe } = data;

    return (
        <RecipeProductSuggestionsProvider
            recipeId={recipe.id}
            initialServings={recipe.servings}
            member={{ isRegistered }}
        >
            <Container
                maxWidth={MAX_CONTAINER_WIDTH}
                className={classNames({ [css.containerMobile]: isMobile })}
                id={anchorId || undefined}
            >
                <section
                    className={css.root}
                    aria-label="Uitgelicht recept"
                    data-branding="allerhande"
                >
                    <div className={css.heroImageContainer}>
                        <ImageSheetImage
                            alt={title || recipe.title}
                            images={recipe.images}
                        />
                    </div>

                    <div className={css.contentContainer}>
                        <Container>
                            <Grid>
                                <GridItem xs={4} className={css.titleContainer}>
                                    <Typography
                                        variant="display"
                                        as="h2"
                                        className={css.heading}
                                    >
                                        {title || recipe.title}
                                    </Typography>
                                </GridItem>

                                <GridItem xs={4}>
                                    <div
                                        className={
                                            css.recipeDetailsContentContainer
                                        }
                                    >
                                        <RecipeDetailsContent
                                            recipe={recipe}
                                            tagLevel={tagLevel || 2}
                                        />
                                    </div>
                                </GridItem>
                            </Grid>
                        </Container>
                    </div>
                </section>
                <RecipeSchema recipe={recipe} host={host} />
            </Container>
        </RecipeProductSuggestionsProvider>
    );
};
