import { getCMSLinkUrl } from '@helpers/url';
import type { ThemeListerCardLink } from '../theme-list-card-collection-lane.interface';

export const getCardUrl = ({
    anchor,
    host,
    pathname,
}: {
    anchor?: ThemeListerCardLink;
    host: string;
    pathname: string;
}) => {
    if (!anchor) {
        return host;
    }

    if (anchor.target === 'anchorId') {
        return `${host}${pathname}#${anchor.url}`;
    }

    return `${host}${getCMSLinkUrl(anchor.url)}`;
};
