import {
    type ComponentMeta,
    emitClickComponent,
} from '@royalaholddelhaize/ah-analytics';

interface CidLabelProps {
    groupTitle?: string;
    linkLabel: string;
    href?: string;
}

export const emitContentListLinkGroupClick = ({
    groupTitle,
    linkLabel,
    href,
}: CidLabelProps) => {
    const cidComponent: ComponentMeta = {
        componentType: 'Link group',
        componentSubType: 'link',
        componentInnerText: linkLabel || '',
        componentTitle: groupTitle || '',
        componentTargetUrl: href || '',
        componentSection: 'Footer',
    };

    emitClickComponent(cidComponent);
};
