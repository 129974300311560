import type { ApolloError } from '@apollo/client/errors';
import type { RecipeSummaryFragment } from '@graphql/_generated-operation-types';
import { useMember } from '@graphql/member/member-hook';
import { useRecipeRecommendations } from '@graphql/recipe-recommendations/recipe-recommendations-hook';
import { useAuthentication } from '@royalaholddelhaize/ah-next-core/src/authentication/client';
import { LoginState } from '@royalaholddelhaize/ah-next-core/src/enums/login-state';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import type { RecipeCollectionCategoryFragment } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/_generated-operation-types';
import { useRecipeCollectionCategories } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/recipe-collections/recipe-collection-categories-hook';

type UseWeekmenuLane = {
    isLoading: boolean;
    error?: ApolloError;
    isMobile: boolean;
    categories: RecipeCollectionCategoryFragment[];
    recipeRecommendations: RecipeSummaryFragment[];
    firstName: string;
};

export const useWeekmenuLane = (): UseWeekmenuLane => {
    const { memberId, state, isRegistered } = useAuthentication();
    const isLoggedOut = state === LoginState.ANONYMOUS || !memberId;

    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);

    const { categories, isLoading: isRecipeCollectionLoading } =
        useRecipeCollectionCategories({ member: { isRegistered } });

    const {
        firstName,
        isLoading: isMemberLoading,
        error: memberError,
    } = useMember();

    const {
        recipeRecommendations,
        isLoading: isRecipeRecomendationsLoading,
        error: recipeRecomendationsError,
    } = useRecipeRecommendations(isLoggedOut);

    const isLoading =
        isRecipeCollectionLoading ||
        isMemberLoading ||
        isRecipeRecomendationsLoading;
    const error = memberError || recipeRecomendationsError;

    return {
        isLoading,
        error,
        isMobile,
        categories,
        recipeRecommendations,
        firstName,
    };
};
