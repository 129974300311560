import { ChevronRightFilled16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-filled-16';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './play-button.module.scss';

interface PlayButtonProps extends ComponentProps<'button'> {
    // eslint-disable-next-line react/require-default-props
    className?: string;
}

export const PlayButton: FC<PlayButtonProps> = ({
    className,
    ...restProps
}) => {
    return (
        <button
            {...restProps}
            className={classNames(className, css.root)}
            aria-label="Video afspelen"
        >
            <div className={css.circle}>
                <ChevronRightFilled16Icon
                    className={css.icon}
                    size={48}
                    aria-hidden
                />
            </div>
        </button>
    );
};
