import { type FC, Suspense } from 'react';
import type { RecipeGridProps } from './component.interface';
import { RecipeGrid } from './elements/recipe-grid/recipe-grid';
import { RecipeGridSkeleton } from './elements/recipe-grid/recipe-grid-skeleton';

export const RecipeGridLane: FC<RecipeGridProps> = props => {
    return (
        <Suspense fallback={<RecipeGridSkeleton {...props} />}>
            <RecipeGrid {...props} />
        </Suspense>
    );
};
