// Component update handler to handle component updates.

type ComponentUpdateProperties = {
    cmsOrigin: string;
    componentId: string;
    componentUpdateCall: () => void;
};

export const registerComponentUpdateCall = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    event: MessageEvent<any>,
    properties: ComponentUpdateProperties,
) => {
    const componentEventPool = new Map();
    if (event.origin !== properties.cmsOrigin) {
        console &&
            console.debug &&
            console.debug(`Skip request with origin: ${event.origin}`);
    } else if (
        event.data?.type === 'brxm:event' &&
        event.data?.payload?.id === properties.componentId
    ) {
        properties.componentUpdateCall();
        sendSyncMessage(properties.cmsOrigin, componentEventPool);
    } else if (
        event.data?.type === 'brxm:response' &&
        componentEventPool.has(event.data?.id)
    ) {
        processResponseEvent(componentEventPool, event);
    }
};

const sendSyncMessage = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    cmsOrigin: any,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    eventPool: any,
    ...payload: unknown[]
) => {
    return new Promise((resolve, reject) => {
        const id = Math.random();
        eventPool.set(id, [resolve, reject]);

        window.parent.postMessage(
            {
                id,
                payload,
                type: 'brxm:request',
                command: 'sync',
            },
            cmsOrigin,
        );
    });
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const processResponseEvent = (eventPool: any, event: any) => {
    const response = event.data;
    if (!eventPool.has(response.id)) {
        console.log(
            `Not part of this update response: ${JSON.stringify(event.data)}`,
        );
        return;
    }

    console.log(`Component update response: ${JSON.stringify(event.data)}`);

    const [resolve, reject] = eventPool.get(response.id);
    eventPool.delete(response.id);
    if (response.state === 'rejected') {
        reject(response.result);
        return;
    }
    resolve(response.resolve);
};
