import type { FC } from 'react';
import type { FAQPage } from 'schema-dts';
import { JsonLdSchema } from './json-ld-schema';

interface FaqQuestion {
    question: string;
    answer: string;
}

interface FaqLaneSchemaProps {
    questions: FaqQuestion[];
}

export const FaqSchema: FC<FaqLaneSchemaProps> = ({ questions }) => {
    const json: FAQPage = {
        '@type': 'FAQPage',
        mainEntity: questions.map(item => ({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer,
            },
        })),
    };

    return <JsonLdSchema content={json} />;
};
