'use client';

import { PlayButton } from '@components/elements/play-button/play-button';
import { InlineVideo } from '@components/features/inline-video/inline-video';
import type { RecipeVideoFragment } from '@graphql/_generated-operation-types';
import { type FC, useState } from 'react';
import { VideoInfo, type VideoInfoProps } from '../video-info/video-info';

import css from './video-lane-item.module.scss';

interface VideoLaneItemProps {
    video: RecipeVideoFragment;
    tagLevel?: number;
}

export const VideoLaneItem: FC<VideoLaneItemProps> = ({
    video,
    tagLevel = 2,
}) => {
    const {
        id,
        title,
        descriptionHtml,
        duration,
        images: { sd },
    } = video;
    const [isPlayerVisible, setIsPlayerVisible] = useState(false);

    const handlePlayerThumbnailClick = () => {
        setIsPlayerVisible(true);
    };

    return (
        <div className={css.root}>
            {isPlayerVisible ? (
                <InlineVideo
                    id={id}
                    title={title}
                    videoPlayerType="allerhande-v3-videodetail"
                    autoPlay
                />
            ) : (
                <>
                    <PlayButton
                        onClick={handlePlayerThumbnailClick}
                        image={sd}
                        title={title}
                    />
                    <VideoInfo
                        title={title}
                        description={descriptionHtml}
                        duration={duration}
                        tagLevel={tagLevel as VideoInfoProps['tagLevel']}
                    />
                </>
            )}
        </div>
    );
};
