import { ShopInShopPlaceholder } from '../../components/shop-in-shop/placeholder';
import { ShopInShop as ShopInShopComp } from '../../components/shop-in-shop/shop-in-shop';
import type { ShopInShopProps } from '../../components/shop-in-shop/shop-in-shop.interface';
import { ContentCmsComponentType } from '../../enums';
import type { Model } from '../../interfaces';

/**
 * @deprecated Will be replaced with the spotlight list
 */
export const ShopInShop: Model<ShopInShopProps> = {
    component: ShopInShopComp,
    name: ContentCmsComponentType.SHOP_IN_SHOP,
    placeholder: ShopInShopPlaceholder,
};
