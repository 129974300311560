import type { ComponentMeta } from '@royalaholddelhaize/ah-analytics';
import {
    emitClickComponent,
    emitViewComponent,
} from '@royalaholddelhaize/ah-analytics';

// component interaction
export const onComponentImpression = (cidComponent: ComponentMeta): void => {
    if (cidComponent) {
        emitViewComponent(cidComponent);
    }
};

export const onComponentClick = (cidComponent: ComponentMeta): void => {
    if (cidComponent) {
        emitClickComponent(cidComponent);
    }
};
