import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { AllerhandeRoute } from '@enums/route';
import {
    type ComponentMeta,
    emitClickComponent,
} from '@royalaholddelhaize/ah-analytics';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SEARCH_BANNER_LANE_QUICK_ENTRIES,
    createSearchBannerQuickEntryLink,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-24';
import { LinkText } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC } from 'react';
import type { RecipeSearchBannerLaneProps } from '../../recipe-search-banner-lane';

import css from './banner-quick-entries.module.scss';

export const BannerQuickEntries: FC<
    Pick<RecipeSearchBannerLaneProps, 'quickSearchEntries'>
> = ({
    quickSearchEntries = [
        {
            label: 'Alle recepten',
            url: AllerhandeRoute.RecipeSearch,
            target: '_self',
        },
    ],
}) => {
    return (
        <div
            className={css.root}
            {...createTestHook(SEARCH_BANNER_LANE_QUICK_ENTRIES)}
        >
            {quickSearchEntries.map(({ label, url, target }) => {
                const handleOnClick = () => {
                    const cidComponent: ComponentMeta = {
                        componentType: 'Quick search link',
                        componentSubType: 'link',
                        componentInnerText: label,
                        componentTargetUrl: url,
                        componentSection: 'Allerhande - Overview',
                    };

                    emitClickComponent(cidComponent);
                };

                return (
                    <LinkOrAnchor
                        key={label}
                        href={url}
                        title={label}
                        target={target}
                        aria-label={label}
                        className={css.searchBarLink}
                        onClick={handleOnClick}
                    >
                        <LinkText
                            {...createTestHook(
                                createSearchBannerQuickEntryLink(label),
                            )}
                        >
                            {label}
                        </LinkText>
                        <ChevronRight24Icon size="12" className={css.icon} />
                    </LinkOrAnchor>
                );
            })}
        </div>
    );
};
