import type { FC } from 'react';
import type { ContentPreviewData } from '../../../interfaces';
import { addContentLinkComments } from '../../helpers/preview-comments';

export interface EditButtonProps {
    preview: ContentPreviewData;
}

export const EditButton: FC<EditButtonProps> = ({ preview }) => {
    const addMetaData = (element: HTMLDivElement) => {
        if (preview?.contentLink) {
            addContentLinkComments(element, preview.contentLink);
        }
    };
    return <span ref={addMetaData} />;
};
