export enum Holiday {
    EASTER = 'easter',
    CHRISTMAS = 'christmas',
}

const holidays = [
    {
        holiday: Holiday.CHRISTMAS,
        start: {
            month: 11,
            day: 6,
        },
        end: {
            month: 11,
            day: 31,
        },
    },
];

export const currentHoliday = (): Holiday | null => {
    const today = new Date();

    const found = holidays.find(({ start, end }) => {
        const startDate = new Date(today.getFullYear(), start.month, start.day);
        const endDate = new Date(today.getFullYear(), end.month, end.day);

        return today >= startDate && today <= endDate;
    });

    return found?.holiday || null;
};
