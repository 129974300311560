import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import {
    IMAGE_CARD_COLLECTION_LANE_BANNER_CARD,
    IMAGE_CARD_COLLECTION_LANE_LEFT_BOTTOM_CARD,
    IMAGE_CARD_COLLECTION_LANE_RIGHT_BOTTOM_CARD,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import { Card } from './elements/card/card';
import { ImageText } from './elements/image-text/image-text';
import type {
    ImageCard,
    ImageCardCollectionLaneProps,
    ImageCardItem,
    ImageRecipeCard,
} from './image-card-collection-lane.interfaces';

const getImageAlt = (item: ImageCardItem) => {
    const { recipe } = item as ImageRecipeCard;
    const { caption } = item as ImageCard;

    if (recipe?.title) {
        return recipe.title;
    }

    if (caption?.label) {
        return caption.label;
    }

    return '';
};

export const ImageCardCollectionLane: FC<ImageCardCollectionLaneProps> = ({
    bannerImage,
    otherImages,
    anchorId,
}) => {
    // In Hippo this component gets added initially without any props during preview mode
    if (
        !(
            (bannerImage as ImageCard)?.image ||
            (bannerImage as ImageRecipeCard)?.recipe?.images
        )
    ) {
        return null;
    }

    function renderBottomCards() {
        // In Hippo this component gets added initially without any props during preview mode
        if (!otherImages || otherImages.length === 0) {
            return null;
        }

        const [leftBottomImageCard, rightBottomImageCard] = otherImages;

        // Prevent bad input from breaking Hippo's preview mode
        if (
            ((leftBottomImageCard as ImageCard)?.image ||
                (leftBottomImageCard as ImageRecipeCard)?.recipe?.images) &&
            ((rightBottomImageCard as ImageCard)?.image ||
                (rightBottomImageCard as ImageRecipeCard)?.recipe?.images)
        ) {
            return (
                <>
                    <GridItem xs={4} lgOffset={2} lg={4}>
                        <Card
                            short
                            imageRenditions={
                                (leftBottomImageCard as ImageCard).image
                                    ?.variants ||
                                (leftBottomImageCard as ImageRecipeCard).recipe
                                    ?.images ||
                                []
                            }
                            alt={getImageAlt(leftBottomImageCard)}
                            testHook={
                                IMAGE_CARD_COLLECTION_LANE_LEFT_BOTTOM_CARD
                            }
                        >
                            <ImageText card={leftBottomImageCard} />
                        </Card>
                    </GridItem>

                    <GridItem lg={4} xs={4}>
                        <Card
                            short
                            imageRenditions={
                                (rightBottomImageCard as ImageCard).image
                                    ?.variants ||
                                (rightBottomImageCard as ImageRecipeCard).recipe
                                    ?.images ||
                                []
                            }
                            alt={getImageAlt(rightBottomImageCard)}
                            testHook={
                                IMAGE_CARD_COLLECTION_LANE_RIGHT_BOTTOM_CARD
                            }
                        >
                            <ImageText card={rightBottomImageCard} />
                        </Card>
                    </GridItem>
                </>
            );
        }

        return null;
    }

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId || undefined}
        >
            <Grid>
                <GridItem lgOffset={2} lg={8} xs={4}>
                    <Card
                        imageRenditions={
                            (bannerImage as ImageCard).image?.variants ||
                            (bannerImage as ImageRecipeCard).recipe?.images ||
                            []
                        }
                        alt={getImageAlt(bannerImage)}
                        testHook={IMAGE_CARD_COLLECTION_LANE_BANNER_CARD}
                    >
                        <ImageText card={bannerImage} />
                    </Card>
                </GridItem>

                {renderBottomCards()}
            </Grid>
        </Container>
    );
};
