import {
    AH_UI_CMS_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { ExternalLink24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/external-link-24';
import {
    RegularAnchor,
    RegularAnchorText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { type FC, useEffect, useState } from 'react';
import { linkForward } from '../../../../helpers/link-forward';
import { getContentThemeFromConfig } from '../../../helpers/theme/get-content-theme-from-config.helper';
import type { ArticleButtonProps } from './article-buttons.interfaces';

import css from './article-button.module.scss';

export const ArticleButton: FC<ArticleButtonProps> = ({
    button,
    level,
    themeConfig,
}) => {
    const [pageUrl, setPageUrl] = useState<string | undefined>();
    const theme = getContentThemeFromConfig(button.theme || '', themeConfig);

    useEffect(() => {
        setPageUrl(window.location.href);
    }, []);

    if (!button.href) {
        return null;
    }

    const buttonStyle = () => {
        if (!theme || typeof theme === 'string') {
            return undefined;
        }

        const isPrimaryType = level === 'primary';
        const primaryColor = theme.primaryColor;
        const textColor = theme.textColor;

        const backgroundColor = isPrimaryType ? primaryColor : textColor;
        const color = isPrimaryType ? textColor : primaryColor;

        return {
            backgroundColor: backgroundColor || '',
            color: color || '',
            borderColor: primaryColor || '',
            boxShadow: `0 0 0 var(--border-width-core-thick) ${primaryColor} inset`,
        };
    };

    return (
        <RegularAnchor
            target={button.isExternalLink ? '_blank' : button.target}
            level={level}
            type="button"
            href={linkForward(button?.href || '', pageUrl)}
            className={css.root}
            style={buttonStyle()}
            data-brand="ah"
            {...createTestHook(AH_UI_CMS_TESTHOOKS.ARTICLE_LINK_BUTTON)}
        >
            {button.isExternalLink && <ExternalLink24Icon />}
            <RegularAnchorText className={css.text}>
                {button.title}
            </RegularAnchorText>
        </RegularAnchor>
    );
};
