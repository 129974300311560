import { useBreadcrumbs } from '@contexts/breadcrumbs';
import { AllerhandeRoute } from '@enums/route';
import type { Breadcrumb } from '@interfaces/breadcrumb';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { usePathname } from 'next/navigation';

export const useContentBreadcrumbs = (): Breadcrumb[] => {
    const pathname = usePathname();
    const { t } = useTranslations();
    const parents = useBreadcrumbs();

    const breadcrumbs = parents.map(({ name, href }) => ({
        name,
        href: `${AllerhandeRoute.Home}${href}`,
    }));

    if (pathname.includes(`${AllerhandeRoute.RecipeThemeOverview}/`)) {
        breadcrumbs.unshift({
            name: t('recipe', { count: 2 }),
            href: AllerhandeRoute.RecipeThemeOverview,
        });
    }

    return breadcrumbs;
};
