import { createImageSrcSet } from '@helpers/image';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    IMAGE_CARD_COLLECTION_LANE_INNER_IMAGE_TEXT,
    IMAGE_CARD_COLLECTION_LANE_OUTER_IMAGE_TEXT,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import type { ContentImageSet } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import classNames from 'clsx';
import type { FC, PropsWithChildren } from 'react';

import css from './card.module.scss';

export interface CardProps {
    imageRenditions: ContentImageSet[];
    alt: string;
    testHook?: string;
    short?: boolean;
}

export const Card: FC<PropsWithChildren<CardProps>> = ({
    imageRenditions,
    children,
    short,
    alt,
    testHook,
}) => {
    const cardClass = classNames(css.root, short && css.shortCard);
    const { viewport } = useViewport();
    const isDesktop = [Viewport.LARGE, Viewport.EXTRA_LARGE].includes(viewport);

    return (
        <div {...createTestHook(testHook)}>
            <div className={cardClass}>
                <Image
                    src={imageRenditions[0]?.url || ''}
                    srcSet={createImageSrcSet(imageRenditions)}
                    alt={alt}
                    className={css.image}
                />

                {isDesktop && children && (
                    <div
                        className={css.textWrapper}
                        {...createTestHook(
                            IMAGE_CARD_COLLECTION_LANE_INNER_IMAGE_TEXT,
                        )}
                    >
                        <span className={css.text}>{children}</span>
                    </div>
                )}
            </div>

            {!isDesktop && children && (
                <div
                    className={css.textWrapper}
                    {...createTestHook(
                        IMAGE_CARD_COLLECTION_LANE_OUTER_IMAGE_TEXT,
                    )}
                >
                    <span className={css.text}>{children}</span>
                </div>
            )}
        </div>
    );
};
