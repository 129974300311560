import { RECIPE_SEARCH_MAX_RESULTS } from '@constants/search';
import { useRecipeSearchV2SuspenseQuery } from '@graphql/_generated-hooks';
import {
    type RecipeSearchFilterFragment,
    type RecipeSearchFilterGroupFragment,
    type RecipeSearchParams,
    type RecipeSearchQueryFilter,
    RecipeSearchSortOption,
    type RecipeSummaryFragment,
} from '@graphql/_generated-operation-types';
import { calculatePagination } from '@helpers/search';
import { useSearch } from '@hooks/use-search';
import { useSearchInteractionEvent } from '@hooks/use-search-interaction-event';
import { SearchAction } from '@royalaholddelhaize/ah-analytics';
import type { RecipeGridSearchQuery } from '../../component.interface';
import { RECIPE_SIZE } from './recipe-grid.constants';

export interface UseRecipeGridLane {
    totalPages: number;
    currentPage: number;
    recipes: RecipeSummaryFragment[];
    changePage: (page: number) => void;
    changeFilter: (filter: RecipeSearchFilterFragment) => void;
    filters: RecipeSearchFilterGroupFragment[];
    originalFilters: RecipeSearchQueryFilter[];
}

export interface UseRecipeGridLaneDataLayerParams {
    id: string;
    type: string;
    recipes: RecipeSummaryFragment[];
}

const RecipeGridLaneDefaults: RecipeGridSearchQuery = {
    filters: [],
    priorityRecipeIds: [],
    searchText: '',
    sortBy: RecipeSearchSortOption.NEWEST,
};

export const useRecipeGridLane = (
    searchQueryInput: RecipeSearchParams,
): UseRecipeGridLane => {
    const { page, setPage, setFilter, filters: searchFilters } = useSearch();

    const searchQuery = {
        ...RecipeGridLaneDefaults,
        ...searchQueryInput,
    };

    const filters =
        searchQuery.filters?.reduce<RecipeSearchQueryFilter[]>(
            (result, filter) => {
                const { group, values } = filter;
                const existingGroup = result.find(
                    filterGroup => filterGroup.group === group,
                );
                if (existingGroup) {
                    existingGroup.values.push(...values);
                } else {
                    result.push({
                        group,
                        values,
                    });
                }
                return result;
            },
            [],
        ) || [];

    const combinedFilters = [...filters, ...searchFilters];

    const { start, size } = calculatePagination(
        page,
        RECIPE_SIZE,
        RECIPE_SEARCH_MAX_RESULTS,
    );

    const queryParams: RecipeSearchParams = {
        searchText: searchQuery.searchText,
        filters: combinedFilters,
        sortBy: searchQuery.sortBy,
        priorityRecipeIds: searchQuery.priorityRecipeIds,
        favoriteRecipeIds: null,
        recipeIds: null,
        size,
        start,
        includeAggregations: undefined,
        ingredients: [],
    };

    const { data } = useRecipeSearchV2SuspenseQuery({
        skip: size === 0,
        variables: queryParams,
    });

    const changePage = (newPage: number) => {
        setPage(newPage);
    };

    const changeFilter = (filter: RecipeSearchFilterFragment) => {
        setFilter(filter);
    };

    const totalPages = Math.ceil(
        Math.min(
            data?.recipeSearchV2.page.total || 0,
            RECIPE_SEARCH_MAX_RESULTS,
        ) / RECIPE_SIZE,
    );

    useSearchInteractionEvent({
        searchParams: {
            page,
            filters: combinedFilters,
        },
        recipeSearchParams: queryParams,
        recipeSearchData: data,
        lastFilterInteraction: SearchAction.QUICK_LINK_FILTER,
    });

    return {
        recipes: data?.recipeSearchV2.result || [],
        currentPage: page,
        totalPages,
        changePage,
        changeFilter,
        filters: data?.recipeSearchV2.filters || [],
        originalFilters: filters,
    };
};
