import { Fragment } from 'react';
import type { ContentPreviewData } from '../../interfaces';
import {
    PAGE_META_DATA_ID,
    addBodyComments,
} from '../helpers/preview-comments';
import { buildRobotsMetaTag } from './helpers/build-robots-meta-tag';

export type PageMetaDataProps = {
    title: string;
    description: string;
    canonical?: string | null;
    index: boolean;
    follow: boolean;
    fallbackCanonical?: string;
    previewData?: ContentPreviewData | null;
};

export const PageMetaData = ({
    title,
    description,
    canonical,
    index,
    follow,
    fallbackCanonical,
    previewData,
}: PageMetaDataProps) => {
    const robotsValue = buildRobotsMetaTag({ index, follow });

    // We are not in preview mode
    if (!previewData) {
        return (
            <Fragment key="cms-meta">
                {title && (
                    <>
                        <title>{title}</title>
                        <meta property="og:title" content={title} />
                    </>
                )}
                {description && (
                    <>
                        <meta name="description" content={description} />
                        <meta property="og:description" content={description} />
                    </>
                )}
                {(canonical || fallbackCanonical) && (
                    <link
                        rel="canonical"
                        href={canonical || fallbackCanonical}
                    />
                )}
                {robotsValue && <meta name="robots" content={robotsValue} />}
            </Fragment>
        );
    }

    return (
        <div
            id={PAGE_META_DATA_ID}
            ref={element => {
                // add required hippo page meta data for preview mode
                const comment = `${previewData?.begin || ''}${
                    previewData?.end || ''
                }${previewData?.contentLink || ''}`;
                addBodyComments(element, PAGE_META_DATA_ID, comment);
            }}
        />
    );
};
