import {
    type ProductCardFragment,
    ProductImageAngle,
} from '@graphql/_generated-operation-types';
import { useProductCard } from '@hooks/use-product-card';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { PRODUCT_LANE_PRODUCT_CARD } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import { ProductCardProductLogoContainer } from '@royalaholddelhaize/design-system-pantry-web/components/product-card/elements/container/product-card-container';
import {
    ProductCardStyle,
    ProductCardVariant,
} from '@royalaholddelhaize/design-system-pantry-web/components/product-card/templates/product-card-templates.interfaces';
import { ProductCardVertical } from '@royalaholddelhaize/design-system-pantry-web/components/product-card/templates/product-card-vertical-templates';
import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import { type FC, useEffect, useRef } from 'react';
import { getPromotionShield } from './helpers/get-promotion-shield';
import { mapProductIcons } from './helpers/map-product-icon';

interface ProductCardProps {
    product: ProductCardFragment;
    index: number;
}

const getProductCardVariant = (
    product: ProductCardFragment,
    isAvailable: boolean,
): ProductCardVariant => {
    if (!isAvailable) {
        return ProductCardVariant.CHEVRON;
    }

    return product.variants && Boolean(product.variants.length)
        ? ProductCardVariant.ACTION
        : ProductCardVariant.DEFAULT;
};

export const ProductCard: FC<ProductCardProps> = ({
    product: inputProduct,
    index,
}) => {
    const ref = useRef(null);

    const { isInView } = useInView({
        ref,
        threshold: 0.5,
        once: true,
    });

    const {
        onDecrease,
        onIncrease,
        onUpdate,
        quantity,
        onRemove,
        onClickSecondary,
        onClickPrimary,
        product,
        primaryLabel,
        secondaryLabel,
        isAvailable,
        onClickCard,
        onView,
    } = useProductCard({
        product: inputProduct,
        index,
    });

    useEffect(() => {
        if (isInView) {
            onView();
        }
    }, [isInView, onView]);

    const { title, webPath, imagePack, priceV2, availability, salesUnitSize } =
        product;

    const handleOnClick = () => {
        onClickCard?.();

        if (webPath) {
            window.location.assign(webPath);
        }
    };

    return (
        <ProductCardVertical
            ref={ref}
            defaultAriaProps={{}}
            title={title || ''}
            priceInCents={Math.round((priceV2?.now?.amount || 0) * 100)}
            originalPriceInCents={
                priceV2?.discount?.wasPriceVisible
                    ? Math.round((priceV2?.was?.amount || 0) * 100)
                    : undefined
            }
            productCardStyle={
                priceV2?.discount
                    ? ProductCardStyle.DISCOUNT
                    : ProductCardStyle.REGULAR
            }
            productCardVariant={getProductCardVariant(product, isAvailable)}
            chooseButtonText="kies"
            primaryActionText={primaryLabel}
            secondaryActionText={secondaryLabel}
            priceDescription={salesUnitSize || ''}
            availabilityText={availability?.availabilityLabel || ''}
            discountPeriodText={
                isAvailable
                    ? priceV2?.discount?.smartLabel || undefined
                    : undefined
            }
            quantity={quantity}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
            onUpdate={onUpdate}
            onRemove={onRemove}
            onClickCard={handleOnClick}
            onClickSecondary={onClickSecondary}
            onClickPrimary={onClickPrimary}
            {...createTestHook(PRODUCT_LANE_PRODUCT_CARD)}
            seoAnchorAttributes={{ href: webPath || '#' }}
        >
            <Image
                src={
                    imagePack?.find(e =>
                        [
                            ProductImageAngle.ANGLE_2D1,
                            ProductImageAngle.HERO,
                        ].includes(e.angle),
                    )?.small?.url || ''
                }
                alt=""
            />

            {product.icons && (
                <ProductCardProductLogoContainer>
                    {mapProductIcons(product.icons)}
                </ProductCardProductLogoContainer>
            )}

            {priceV2 && getPromotionShield(priceV2)}
        </ProductCardVertical>
    );
};
