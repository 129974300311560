import type { FC, PropsWithChildren } from 'react';
import { CookieConsentContext } from '../contexts/cookie-consent-context';

export const CookieConsentProvider: FC<
    PropsWithChildren<CookieConsentContext>
> = ({ children, isAdConsent, isSocialConsent }) => {
    return (
        <CookieConsentContext.Provider value={{ isAdConsent, isSocialConsent }}>
            {children}
        </CookieConsentContext.Provider>
    );
};
