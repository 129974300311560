import { RichContent } from '@components/elements/rich-content/rich-content';
import { typographyTagFromTaglevel } from '@helpers/cms';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { VIDEO_LANE_INFO } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import css from './video-info.module.scss';

export interface VideoInfoProps {
    title: string;
    description: string;
    duration: string;
    tagLevel: number;
}

const MAX_DESCRIPTION_LENGTH = 430;

export const VideoInfo: FC<VideoInfoProps> = ({
    title,
    description,
    duration,
    tagLevel,
}) => {
    const videoDescription =
        description.length > MAX_DESCRIPTION_LENGTH
            ? `${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`
            : description;
    return (
        <div className={css.root} {...createTestHook(VIDEO_LANE_INFO)}>
            <div className={css.background} />
            <div className={css.headingContainer}>
                <Typography
                    className={css.title}
                    variant="display"
                    as={typographyTagFromTaglevel(tagLevel)}
                >
                    {title}
                </Typography>
                <RichContent
                    className={css.description}
                    html={videoDescription}
                />
                <Typography className={css.duration}>{duration}</Typography>
            </div>
        </div>
    );
};
