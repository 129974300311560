import classNames from 'clsx';
import type { FC, HTMLAttributes } from 'react';

import css from './header.module.scss';

export const Header: FC<HTMLAttributes<HTMLDivElement>> = ({
    children,
    className,
}) => {
    return <div className={classNames(css.wrapper, className)}>{children}</div>;
};
