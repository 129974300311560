import { ReadonlyURLSearchParams } from 'next/navigation';
import { QueryParameter } from '../enums/query-parameter';
import type { SearchParams } from '../interfaces/search-params';

export const isCMSPreview = (
    searchParams?: SearchParams | ReadonlyURLSearchParams,
) => {
    const isURLSearchParams = searchParams instanceof ReadonlyURLSearchParams;

    const hasCMSToken = Boolean(
        isURLSearchParams
            ? searchParams?.get(QueryParameter.CMS_TOKEN)
            : searchParams?.[QueryParameter.CMS_TOKEN],
    );
    const hasServerId = Boolean(
        isURLSearchParams
            ? searchParams?.get(QueryParameter.SERVER_ID)
            : searchParams?.[QueryParameter.SERVER_ID],
    );

    return hasCMSToken || hasServerId;
};
