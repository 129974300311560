import {
    RecipeCollectionCategoriesDocument,
    useRecipeCollectionCreateCategoryMutation,
} from '../../_generated-hooks';
import {
    MutationResultStatus,
    type RecipeCollectionCategoriesQuery,
} from '../../_generated-operation-types';

export interface UseCreateCategory {
    loading: boolean;
    hasError: boolean;
    createCategory: (
        name: string,
        onCreateCategoryCompleted?: (hasError: boolean) => void,
    ) => void;
}

export const useCreateCategory = (): UseCreateCategory => {
    const [createRecipeCategory, { loading, error }] =
        useRecipeCollectionCreateCategoryMutation({
            fetchPolicy: 'network-only',
        });

    const createCategory = (
        name: string,
        onCreateCategoryCompleted?: (hasError: boolean) => void,
    ): void => {
        createRecipeCategory({
            variables: { name },
            onCompleted: () =>
                onCreateCategoryCompleted && onCreateCategoryCompleted(false),
            onError: error => {
                console.log(error);
                return (
                    onCreateCategoryCompleted && onCreateCategoryCompleted(true)
                );
            },
            update: (store, { data: newCategory, errors }) => {
                if (
                    !errors &&
                    newCategory &&
                    newCategory.recipeCollectionCreateCategoryV2.status ===
                        MutationResultStatus.SUCCESS
                ) {
                    store.updateQuery<RecipeCollectionCategoriesQuery>(
                        {
                            query: RecipeCollectionCategoriesDocument,
                        },
                        data => {
                            const [defaultCategory, ...restCategories] =
                                data?.recipeCollectionCategories || [];

                            return {
                                recipeCollectionCategories: [
                                    defaultCategory,
                                    {
                                        ...newCategory
                                            .recipeCollectionCreateCategoryV2
                                            .result,
                                    },
                                    ...restCategories,
                                ],
                            };
                        },
                    );
                }
            },
        }).catch();
    };
    return {
        loading,
        createCategory,
        hasError: !!error,
    };
};
