import {
    type PromoMetaProps,
    emitClickPromo,
} from '@royalaholddelhaize/ah-analytics';
import { ChevronRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-24';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
// import { Sticker, StickerType } from '../../../elements/sticker/sticker';
import type { FC } from 'react';
import { PromoImage } from '../promo-image/promo-image';

import { Sticker, StickerType } from '../sticker/sticker';
import css from './small-promo-block.module.scss';

export type PromoImageType = {
    title: string;
    url: string;
    width: number;
};

export type ResponsivePromoImageType = {
    desktop: PromoImageType;
    mobile: PromoImageType;
};

export interface ShopItem {
    title: string;
    href: string;
    link: string;
    bgColor: string;
    target?: string;
    titleTagLevel?: number;
    promoImages?: ResponsivePromoImageType;
    promoSticker?: StickerType;
    cidPromo?: PromoMetaProps;
    className?: string;
}

export const SmallPromoBlock: FC<ShopItem> = ({
    title,
    titleTagLevel = 6,
    link,
    href,
    target = '_top',
    promoImages,
    bgColor,
    promoSticker = StickerType.NONE,
    cidPromo,
}) => (
    <div className={classNames(css.body, css[`background-${bgColor}`])}>
        <a
            href={href}
            className={css.anchor}
            target={target}
            title={link}
            onClick={() => cidPromo && emitClickPromo(cidPromo)}
        >
            <div className={css.content}>
                <Typography
                    variant="heading-2"
                    as="strong"
                    aria-level={titleTagLevel}
                    className={css.title}
                >
                    {title}
                </Typography>
                <Typography as="span" variant="body-regular">
                    {link.trim()}
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {/* Zero Width No-Break Space */}
                        {/* prevents chevron from being wrapped on its own */}
                        &#xfeff;
                        <ChevronRight24Icon size={10} />
                    </span>
                </Typography>
            </div>

            <Sticker
                type={promoSticker as StickerType}
                className={css.sticker}
            />
            {promoImages && (
                <PromoImage
                    className={css.imagePlaceholder}
                    images={promoImages}
                />
            )}
        </a>
    </div>
);
