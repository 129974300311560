import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { WarningOutlined24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/warning-outlined-24';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import css from './information-block.module.scss';

export interface InformationBlockProps extends ComponentProps<'div'> {
    /**
     * the classnames of the form-field information
     */
    className?: string;
    /**
     * Hide the icon
     */
    hideIcon?: boolean;
    /**
     * Testhook
     */
    testHook?: string;
}

export const InformationBlock: FC<InformationBlockProps> = ({
    hideIcon,
    className,
    testHook,
    children,
    ...restProps
}) => {
    return (
        <div
            className={classNames(
                css.root,
                css.attention,

                className,
            )}
            {...createTestHook(testHook)}
            {...restProps}
        >
            {!hideIcon && (
                <WarningOutlined24Icon
                    size={48}
                    className={css.icon}
                    name="icon-waarschuwing"
                    {...createTestHook('information-block-icon')}
                />
            )}

            <Typography variant="body-regular" className={css.text} as="span">
                {children}
            </Typography>
        </div>
    );
};
