import { type PropsWithChildren, useEffect } from 'react';
import type { ContentPreviewData } from '../../../interfaces';
import { EditButton } from '../edit-button';
import { registerComponentUpdateCall } from './component-update-helper';

type PreviewComponentProps = {
    preview: ContentPreviewData | null;
    id: string;
    name?: string;
    placeComments: (
        element: HTMLDivElement,
        begin: string,
        end: string,
    ) => void;
};

export const PreviewComponentWrapper = ({
    preview,
    children,
    id,
    placeComments,
    name,
}: PropsWithChildren<PreviewComponentProps>) => {
    const hasChildren = !Array.isArray(children);

    useEffect(() => {
        const params = Object.fromEntries(
            new URLSearchParams(window?.location.search),
        );
        const originalHost = params.originHost ? params.originHost : '';

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const registerComponent = (event: MessageEvent<any>) =>
            registerComponentUpdateCall(event, {
                cmsOrigin: originalHost.replace('/site', ''),
                componentId: id,
                componentUpdateCall: () => {
                    const span = document.createElement('span');
                    span.innerHTML = `Update: ${id}`;
                    const componentDiv = document.querySelector(
                        `[data-cms-id='${id}]`,
                    );
                    if (componentDiv) {
                        componentDiv.appendChild(span);
                    }
                },
            });

        window.addEventListener('message', registerComponent);

        return () => {
            window.removeEventListener('message', registerComponent);
        };
    }, [id]);

    return (
        <div
            data-cms-id={id}
            data-cms-name={name}
            ref={(element: HTMLDivElement) => {
                if (preview?.begin && preview?.end) {
                    placeComments(element, preview.begin, preview.end);
                }
            }}
            className="_preview-component-container"
            style={{
                position: 'relative', // hippo needs this to inject edit buttons
                minHeight: '40px', // give the container a min height so editors dont have to click on 1px line when adding a component to it
            }}
        >
            {children}
            {hasChildren && (
                <EditButton preview={preview as ContentPreviewData} />
            )}
        </div>
    );
};
