import { GlobalRoute } from '@enums/route';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    FOOD_PREFERENCES_LOGIN_BUTTON,
    FOOD_PREFERENCES_REGISTER_BUTTON,
    FOOD_PREFERENCES_SELECTION,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import {
    RegularAnchor,
    RegularAnchorText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import {
    ChoiceChip,
    ChoiceChipGroup,
    ChoiceChipImage,
    ChoiceChipText,
} from '@royalaholddelhaize/design-system-pantry-web/components/choice-chip/choice-chip';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { usePathname } from 'next/navigation';
import type React from 'react';
import { useFoodPreferencesSelection } from './food-preferences-selection-hook';

import css from './food-preferences-selection.module.scss';

const bannerLink =
    'https://static.ah.nl/ah-static/images/allerhande/weekmenu-banner';

export const FoodPreferencesSelection: React.FC = () => {
    const { t } = useTranslations('cms');
    const { preferences, onFoodPreferenceClick, isLoggedOut } =
        useFoodPreferencesSelection();
    const pathname = usePathname();
    const loginUrl = `${GlobalRoute.Login}?ref=${pathname}`;
    const registerUrl = `${GlobalRoute.Register}&ref=${pathname}`;

    return (
        <Container
            className={css.wrapper}
            {...createTestHook(FOOD_PREFERENCES_SELECTION)}
        >
            <div className={css.imageWrapper}>
                <img
                    aria-hidden="true"
                    alt=""
                    className={css.image}
                    srcSet={`
                        ${bannerLink}/343x200.jpg 343w,
                        ${bannerLink}/573x344.jpg 573w,
                        ${bannerLink}/720x404.jpg 720w,
                        ${bannerLink}/770x432.jpg 770w`}
                    sizes="
                (min-width: 1488px): 720px,
                (min-width: 1024px): 570px,
                100vw"
                />
            </div>
            <div className={css.selectionWrapper}>
                <Typography variant="heading-1" as="p">
                    {t('weekmenu.food.preferences.selection.title')}
                </Typography>
                <Typography variant="body-regular">
                    {t(
                        isLoggedOut
                            ? 'weekmenu.food.preferences.selection.subtitle-logout'
                            : 'weekmenu.food.preferences.selection.subtitle',
                    )}
                </Typography>

                <div className={css.selectionButtonsWrapper}>
                    {isLoggedOut ? (
                        <div className={css.logInButtons}>
                            <RegularAnchor
                                data-brand="ah"
                                className={css.logInButton}
                                level="secondary"
                                href={registerUrl}
                                {...createTestHook(
                                    FOOD_PREFERENCES_REGISTER_BUTTON,
                                )}
                            >
                                <RegularAnchorText data-brand="allerhande">
                                    {t('weekmenu.create.account.button')}
                                </RegularAnchorText>
                            </RegularAnchor>
                            <RegularAnchor
                                data-brand="ah"
                                className={css.logInButton}
                                href={loginUrl}
                                {...createTestHook(
                                    FOOD_PREFERENCES_LOGIN_BUTTON,
                                )}
                            >
                                <RegularAnchorText data-brand="allerhande">
                                    {t('weekmenu.login.button')}
                                </RegularAnchorText>
                            </RegularAnchor>
                        </div>
                    ) : (
                        <ChoiceChipGroup
                            className={css.preferences}
                            role="radiogroup"
                            aria-label={t(
                                'weekmenu.food.preferences.selection.aria',
                            )}
                            data-brand="ah"
                        >
                            {preferences.map(preference => (
                                <ChoiceChip
                                    key={preference.value}
                                    type="radio"
                                    value={preference.value}
                                    onClick={() =>
                                        onFoodPreferenceClick(preference.value)
                                    }
                                >
                                    <ChoiceChipImage
                                        className={css.chipImage}
                                        src={preference.image.url}
                                    />
                                    <ChoiceChipText data-brand="allerhande">
                                        {preference.label}
                                    </ChoiceChipText>
                                </ChoiceChip>
                            ))}
                        </ChoiceChipGroup>
                    )}
                </div>
            </div>
        </Container>
    );
};
