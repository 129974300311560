import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { BlueBillywigPlayer } from '@royalaholddelhaize/design-system-pantry-web/components/blue-billywig-player/blue-billywig-player';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import type { ContentMediaVideoBlueBillyWig } from '../../../../../../graphql/_generated-graphql-types';
import { useCookieConsent } from '../../../../../../hooks/use-cookie-consent';
import { VideoPreview } from '../video-preview/video-preview';
import css from './article-media-bluebillywig-player.module.scss';

interface ArticleMediaBluebillywigPlayerProps {
    media: ContentMediaVideoBlueBillyWig;
    testHookPrefix?: string;
}

export const ArticleMediaBluebillywigPlayer: FC<
    ArticleMediaBluebillywigPlayerProps & ComponentProps<'div'>
> = ({ className, media, testHookPrefix = 'content', ...restProps }) => {
    const { imageSet } = media;
    const { isAdConsent } = useCookieConsent();

    return (
        <div className={classNames(css.root, className)} {...restProps}>
            <VideoPreview
                previewSrc={imageSet}
                {...createTestHook(`${testHookPrefix}-media-video-preview`)}
                className={css.root}
            >
                <BlueBillywigPlayer
                    className={css.player}
                    playerOptions={{
                        autoPlay: true,
                    }}
                    isAdvertisementEnabled={isAdConsent}
                    videoId={media.videoId}
                />
            </VideoPreview>
        </div>
    );
};
