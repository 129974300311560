import { Skeleton } from '@royalaholddelhaize/design-system-pantry-web/components/experimental/skeleton/skeleton';

export const CardMediaSkeleton = () => {
    return (
        <Skeleton
            variant="rectangle"
            animation="wave"
            height="auto"
            style={{ aspectRatio: 21 / 10 }}
        />
    );
};
