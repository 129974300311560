import type { SpotlightItem } from '@components/cms/spotlight-lane/spotlight-lane.interface';
import type { JsonLdListItem } from '@components/elements/json-ld/item-list-schema';
import { AllerhandeRoute } from '@enums/route';
import {
    type AllerhandeMagazineFragment,
    ContentAllerhandeSpotlightType,
    type RecipeFragment,
    type RecipeImageFragment,
    type RecipeNutritionFragment,
    type RecipeSummaryFragment,
    type RecipeSummaryV2Fragment,
    type RecipeVideoFragment,
    RenditionKey,
} from '@graphql/_generated-operation-types';
import { getRecipeHref } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/utils/href';
import type {
    AggregateRating,
    HowToStep,
    NutritionInformation,
    Recipe,
    RestrictedDiet,
    VideoObject,
} from 'schema-dts';
import { normalizeIngredient } from './ingredient';

const magazineToItem = (
    magazine: AllerhandeMagazineFragment,
): JsonLdListItem => ({
    url: magazine.url,
    name: magazine.edition,
});

export const magazinesToItemList = (
    magazines: AllerhandeMagazineFragment[],
): JsonLdListItem[] => magazines.map(magazineToItem);

const videoToItem =
    (host: string) =>
    (video: RecipeVideoFragment): JsonLdListItem => ({
        url: `${host}${video.href}`,
        name: video.title,
    });

export const videosToItemList = (
    host: string,
    videos: RecipeVideoFragment[],
): JsonLdListItem[] => videos.map(videoToItem(host));

export const getRecipeVideoSchema = (
    video: RecipeVideoFragment,
): VideoObject => {
    const [minutes, seconds] = video.duration.split(':');
    const duration =
        minutes !== '00'
            ? `PT${Number(minutes)}M${Number(seconds)}S`
            : `PT${Number(seconds)}S`;
    return {
        '@type': 'VideoObject',
        name: video.title,
        description: video.description,
        thumbnailUrl: [video.images.thumbnail, video.images.sd],
        contentUrl: video.streams.sd ?? '',
        duration,
        uploadDate: video.publication,
        interactionStatistic: {
            '@type': 'InteractionCounter',
            userInteractionCount: video.views,
        },
    };
};

const recipeToItem =
    (host: string, offset = 0) =>
    (
        recipe: RecipeSummaryFragment | RecipeSummaryV2Fragment,
    ): JsonLdListItem => {
        const slug =
            (recipe as RecipeSummaryFragment).slug ||
            (recipe as RecipeSummaryV2Fragment).slugifiedTitle;

        return {
            url: getRecipeHref({
                recipeId: recipe.id,
                slug,
                author: recipe.author,
                host,
                basePath: AllerhandeRoute.Home,
            }),
            name: recipe.title,
            positionOffset: offset,
        };
    };

export const recipesToItemList = ({
    offset,
    host,
    recipes,
}: {
    offset?: number;
    host: string;
    recipes: RecipeSummaryFragment[];
}): JsonLdListItem[] => recipes.map(recipeToItem(host, offset));

const DIET_MAPPING: Record<string, RestrictedDiet> = {
    vegetarisch: 'VegetarianDiet',
    lactosevrij: 'LowLactoseDiet',
    veganistisch: 'VeganDiet',
    glutenvrij: 'GlutenFreeDiet',
};

const getDiet = (recipe: RecipeFragment): RestrictedDiet[] => {
    return (recipe.classifications || [])
        .map(classification => DIET_MAPPING[classification])
        .filter(Boolean);
};

const getRating = (recipe: RecipeFragment): AggregateRating | undefined => {
    const { rating } = recipe;
    return rating?.count
        ? {
              '@type': 'AggregateRating',
              ratingValue: rating.average || 2.5,
              ratingCount: rating.count,
          }
        : undefined;
};

const getNutritionString = (
    nutrition?: RecipeNutritionFragment | null,
): string => {
    if (!nutrition) {
        return '';
    }
    const { name, unit, value } = nutrition;
    return name ? `${value} ${unit} ${name}` : `${value} ${unit}`;
};

const getNutrition = (
    recipe: RecipeFragment,
): NutritionInformation | undefined => {
    const { nutritions } = recipe;
    return nutritions
        ? {
              '@type': 'NutritionInformation',
              calories: getNutritionString(nutritions?.energy),
              fatContent: getNutritionString(nutritions?.fat),
              saturatedFatContent: getNutritionString(nutritions?.saturatedFat),
              carbohydrateContent: getNutritionString(
                  nutritions?.carbohydrates,
              ),
              proteinContent: getNutritionString(nutritions?.protein),
          }
        : undefined;
};

const getIngredients = (recipe: RecipeFragment): string[] => {
    return recipe.ingredients.map(ingredient => {
        const { name, unit } = normalizeIngredient(ingredient);
        return unit
            ? `${ingredient.quantity} ${unit} ${name.normalized}`
            : `${ingredient.quantity} ${name.normalized}`;
    });
};

const getImages = (recipe: RecipeFragment): string[] => {
    const { images }: { images: RecipeImageFragment[] } = recipe;
    return [
        images?.find(image => image.rendition === RenditionKey.D890X594)?.url ??
            '',
        images?.find(image => image.rendition === RenditionKey.D1224X900)
            ?.url ?? '',
    ];
};

export const getInstructions = (recipe: RecipeFragment): HowToStep[] => {
    return recipe.preparation.steps.map((step, index) => ({
        '@type': 'HowToStep',
        position: index + 1,
        name: `stap ${index + 1}`,
        text: step,
    }));
};

export const getTime = (recipe: RecipeFragment): string => {
    const { cookTime, ovenTime, waitTime } = recipe;
    const totalTime = cookTime + (ovenTime || 0) + (waitTime || 0);
    const hours = Math.trunc(totalTime / 60);
    const minutes = totalTime % 60;
    return hours ? `PT${hours}H${minutes}M` : `PT${minutes}M`;
};

export const getRecipeSchema = (
    recipe: RecipeFragment,
    host: string,
): Recipe => {
    const {
        href,
        title,
        alternateTitle,
        description,
        tags,
        servings,
        courses,
        cuisines,
        publishedAt,
        videos,
        meta,
    } = recipe;

    const url = `${host}${href}`;
    const aggregateRating = getRating(recipe);
    const nutrition = getNutrition(recipe);
    const recipeIngredient = getIngredients(recipe);
    const recipeInstructions = getInstructions(recipe);
    const suitableForDiet = getDiet(recipe);
    const recipeImages = getImages(recipe);
    const totalTime = getTime(recipe);
    const keywords = tags.map(tag => tag.value).join(', ');
    const recipeCategory = courses ? courses.join(', ') : '';
    const recipeCuisine = cuisines ? cuisines.join(', ') : '';
    const recipeYield = `${servings.number}`;
    const datePublished = publishedAt.split('T')[0];
    const videoSchema = videos.preparation
        ? {
              video: getRecipeVideoSchema(videos?.preparation),
          }
        : {};

    return {
        '@type': 'Recipe',
        isPartOf: {
            '@id': 'https://www.ah.nl/allerhande#website',
        },
        author: {
            '@type': 'Organization',
            '@id': 'https://www.ah.nl/allerhande#organization',
            name: 'Albert Heijn',
        },
        name: meta?.title || title,
        alternateName: alternateTitle || '',
        url,
        totalTime,
        image: recipeImages,
        description,
        keywords,
        recipeYield,
        recipeCategory,
        recipeCuisine,
        suitableForDiet,
        nutrition,
        recipeIngredient,
        recipeInstructions,
        aggregateRating,
        datePublished,
        ...videoSchema,
    };
};

export const spotlightToItemList = (
    host: string,
    items: SpotlightItem[],
): JsonLdListItem[] =>
    items
        .map(item => {
            if (
                item.type === ContentAllerhandeSpotlightType.RECIPE &&
                item.recipe
            ) {
                return recipeToItem(host)(item.recipe);
            }

            if (
                item.type ===
                    ContentAllerhandeSpotlightType.VIDEO_BLUE_BILLYWIG &&
                item.recipeVideo
            ) {
                return videoToItem(host)(item.recipeVideo);
            }
        })
        .filter(e => !!e);
