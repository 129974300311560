import { Locale } from '@royalaholddelhaize/ah-web-core';
import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import { useEffect, useRef, useState } from 'react';
import type { LayoutContext } from '../../enums';
import type { ContentBaseCmsComponent, Model } from '../../interfaces';
import { CookieConsentProvider } from '../../providers/cookie-consent-provider';
import { findModel } from '../helpers/component.helper';
import { ContentBlockComponent } from './content-block.component';

export type LazyContentBlockProps = {
    fetchComponents: (ids: string[]) => void;
    chunk: ContentBaseCmsComponent[];
    models: Record<string, Model[]>;
    viewport: string;
    isAdConsent: boolean;
    isSocialConsent: boolean;
    locale?: Locale;
    layout?: LayoutContext;
};

// support old viewport (fresh) and new viewport (Pantry) types as string
// todo: remove fresh viewport types when all components are coming from Pantry
const getInViewMargin = (viewport: string): string => {
    if (
        viewport === 'PHONE' ||
        viewport === 'PHABLET' ||
        viewport === 'extra-small' ||
        viewport === 'small'
    ) {
        return '60px 40px 140px 0px';
    }

    return '60px 40px 280px 0px';
};

export const LazyContentBlock = ({
    chunk,
    fetchComponents,
    models,
    viewport,
    isAdConsent,
    isSocialConsent,
    locale = Locale.nl_NL,
    layout,
}: LazyContentBlockProps) => {
    const [componentFetchIds, setComponentFetchIds] = useState<string[]>([]);
    const ref = useRef<HTMLDivElement>(null);
    const { isInView } = useInView({
        ref,
        threshold: 0,
        once: true,
        rootMargin: getInViewMargin(viewport),
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isInView) {
            const componentIds = chunk.map(x => x.id);
            const componentIdsToFetch = componentIds.filter(
                id =>
                    !componentFetchIds.find(
                        (fetchId: string) => fetchId === id,
                    ),
            );

            const newComponentFetchIds = [
                ...componentFetchIds,
                ...componentIdsToFetch,
            ];

            setComponentFetchIds(newComponentFetchIds);

            if (componentIdsToFetch?.length) {
                fetchComponents(componentIdsToFetch);
            }
        }
    }, [chunk, fetchComponents, isInView]);

    return (
        <CookieConsentProvider
            isAdConsent={isAdConsent}
            isSocialConsent={isSocialConsent}
        >
            <div ref={ref}>
                {chunk.map((component: ContentBaseCmsComponent) => {
                    const model = findModel(models, component);

                    if (!model) {
                        return null;
                    }

                    return (
                        <ContentBlockComponent
                            key={component.id}
                            model={model}
                            component={component}
                            locale={locale}
                            layout={layout}
                        />
                    );
                })}
            </div>
        </CookieConsentProvider>
    );
};
