import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { TEXT_IMAGE_ITEMS_CONTENT_LANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import type { FC } from 'react';
import { TextImageItem } from './elements/text-image-item/text-image-item';
import {
    Orientation,
    type TextImageItemsContentLaneProps,
} from './text-image-items-content-lane.interfaces';
import css from './text-image-items-content-lane.module.scss';

export const TextImageItemsContentLane: FC<TextImageItemsContentLaneProps> = ({
    contentLaneItems,
    anchorId,
    tagLevel,
}) => {
    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            id={anchorId || undefined}
        >
            <Grid>
                <GridItem xs={4}>
                    {contentLaneItems?.map((item, index) => {
                        const { title, description, recipe, image } = item;
                        const isOddRow = index % 2 !== 0;

                        const orientation = isOddRow
                            ? Orientation.RIGHT
                            : Orientation.LEFT;

                        const normalizedTitle = title || recipe?.title || '';
                        const normalizedImages =
                            image?.variants || recipe?.images;
                        const normalizedDescription =
                            description || recipe?.description || '';

                        return (
                            <div
                                className={css.recipeLane}
                                key={`${title}-${index + 1}`}
                                {...createTestHook(
                                    TEXT_IMAGE_ITEMS_CONTENT_LANE,
                                )}
                            >
                                <Grid>
                                    <GridItem xs={4}>
                                        <TextImageItem
                                            images={normalizedImages}
                                            title={normalizedTitle}
                                            description={normalizedDescription}
                                            orientation={orientation}
                                            isMobile={isMobile}
                                            tagLevel={tagLevel || 2}
                                        />
                                    </GridItem>
                                </Grid>
                            </div>
                        );
                    })}
                </GridItem>
            </Grid>
        </Container>
    );
};
