'use client';

import type {
    FilterItem as FilterItemType,
    FilterItemWithGroup,
} from '@interfaces/filter';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_FILTERS_GROUP_LIST } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { type FC, type PropsWithChildren, memo, useRef, useState } from 'react';
import { FilterItem } from './elements/filter-item/filter-item';
import { MoreButton } from './elements/more-button/more-button';

import css from './filter-group.module.scss';

export interface FilterGroupProps {
    id: string;
    filters: FilterItemType[];
    name: string;
    label?: string;
    onSelect: (filter: FilterItemWithGroup) => void;
    isExpandable?: boolean;
}

const INITIAL_FILTERS_AMOUNT = 3;

export const moveSelectedFiltersToTheTop = (
    filters: FilterItemType[],
): FilterItemType[] => {
    return [...filters].sort((a, b) => {
        if (a.selected && !b.selected) {
            return -1;
        }

        if (!a.selected && b.selected) {
            return 1;
        }

        return 0;
    });
};

export const FilterGroup: FC<PropsWithChildren<FilterGroupProps>> = memo(
    ({
        filters,
        onSelect,
        isExpandable = false,
        id,
        label: groupLabel,
        name: group,
    }) => {
        const lastItem = useRef<HTMLLIElement>(null);
        const [isExpanded, setExpanded] = useState(false);
        let filterItems = moveSelectedFiltersToTheTop(filters);

        if (isExpandable) {
            filterItems = isExpanded
                ? filters
                : filters.slice(0, INITIAL_FILTERS_AMOUNT);
        }

        const handleOnClickMore = () => {
            setExpanded(!isExpanded);
            lastItem.current?.focus();
        };

        return (
            <div className={css.body} id={id}>
                <ul
                    id={`${id}-list`}
                    className={css.list}
                    {...createTestHook(SEARCH_FILTERS_GROUP_LIST)}
                >
                    {filterItems.map((filter, index) => (
                        <FilterItem
                            ref={
                                index === INITIAL_FILTERS_AMOUNT - 1
                                    ? lastItem
                                    : null
                            }
                            key={filter.name}
                            count={filter.count}
                            label={filter.label}
                            name={filter.name}
                            selected={filter.selected}
                            onSelect={selected =>
                                onSelect({ group, ...filter, selected })
                            }
                        />
                    ))}
                </ul>
                {isExpandable && filters.length > INITIAL_FILTERS_AMOUNT && (
                    <MoreButton
                        isExpanded={isExpanded}
                        onClick={handleOnClickMore}
                        aria-controls={`${id}-list`}
                        aria-expanded={isExpanded}
                        ariaLabelPrefix={groupLabel}
                    />
                )}
                <div className={css.border} />
            </div>
        );
    },
);
