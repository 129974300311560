'use client';

import { useSearchParams } from 'next/navigation';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { QueryParameter } from '../../enums/query-parameter';
import { isCMSPreview } from '../is-cms-preview';
import type { CMSData } from '../types';
import { CMSContext } from './context';

interface CMSProviderProps extends PropsWithChildren {
    data: CMSData;
}

export const CMSProvider: FC<CMSProviderProps> = ({ data, children }) => {
    const [isPreview, setIsPreview] = useState<boolean>(data.isPreview);
    const [previewToken, setPreviewToken] = useState<string | null>(
        data.previewToken,
    );
    const [serverId, setServerId] = useState<string | null>(data.serverId);

    const searchParams = useSearchParams();

    useEffect(() => {
        setIsPreview(isCMSPreview(searchParams));
        setPreviewToken(
            searchParams.get(QueryParameter.CMS_TOKEN) ||
                searchParams.get(QueryParameter.TOKEN),
        );
        setServerId(searchParams.get(QueryParameter.SERVER_ID));
    }, [searchParams]);

    return (
        <CMSContext.Provider
            value={{ ...data, isPreview, previewToken, serverId }}
        >
            {children}
        </CMSContext.Provider>
    );
};
