import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { ENTER_KEY, SPACE_KEY } from '@constants/keycode';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    RECIPE_GROUP_HEADER_LANE_ANCHORS,
    createRecipeGroupHeaderLaneAnchorTestHook,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import { LinkText } from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import type { Anchor } from '../../component.interface';
import css from './page-anchors.module.scss';

export interface PageAnchorsProps {
    anchors: Anchor[];

    onClickItem?(
        e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>,
    ): void;
}

export const PageAnchors: FC<PageAnchorsProps> = ({ anchors, onClickItem }) => {
    const pageAnchors = anchors.slice(0, 6).map(anchor => ({
        ...anchor,
        href: `#${anchor.id}`,
    }));

    return (
        <nav
            className={css.root}
            aria-label="Themapagina paginalinks"
            {...createTestHook(RECIPE_GROUP_HEADER_LANE_ANCHORS)}
        >
            {pageAnchors.map((anchor, index) => (
                <LinkOrAnchor
                    key={`${anchor.label}-${index}`}
                    href={anchor.href}
                    title={anchor.label}
                    className={css.anchor}
                    onClick={e => onClickItem && onClickItem(e)}
                    onKeyDown={e => {
                        if (e.code === ENTER_KEY || e.code === SPACE_KEY) {
                            return onClickItem && onClickItem(e);
                        }

                        return true;
                    }}
                    {...createTestHook(
                        createRecipeGroupHeaderLaneAnchorTestHook(anchor.href),
                    )}
                >
                    <LinkText className={css.anchorText}>
                        {anchor.label}
                    </LinkText>

                    <ChevronRight16Icon className={css.chevron} size={8} />
                </LinkOrAnchor>
            ))}
        </nav>
    );
};
