import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import { type FC, useMemo } from 'react';
import type { ArticleTableProps } from './article-table.interfaces';
import css from './article-table.module.scss';

export const ArticleTable: FC<ArticleTableProps> = ({
    list,
    className,
    ...restProps
}) => {
    const element = useMemo(() => (list.isNumbered ? 'ol' : 'ul'), [list]);
    const isSplitInColumns = useMemo(() => list.items.length >= 6, [list]);

    return (
        <div className={classNames(css.root, className)} {...restProps}>
            <Typography
                variant="body-regular"
                as={element}
                className={classNames(
                    css.list,
                    isSplitInColumns && css.columns,
                )}
            >
                {list.items.map((item, index) => (
                    <Typography
                        // biome-ignore lint/suspicious/noArrayIndexKey: Cannot be sure items are unique
                        key={index}
                        variant="body-regular"
                        as="li"
                        //biome-ignore lint/security/noDangerouslySetInnerHtml: This is a CMS controlled content
                        dangerouslySetInnerHTML={{
                            __html: item,
                        }}
                    />
                ))}
            </Typography>
        </div>
    );
};
