import { ENTER_KEY } from '@constants/keycode';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_FILTERS_GROUP_MORE_BUTTON } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Minus16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/minus-16';
import { Plus16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/plus-16';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, KeyboardEvent, PropsWithChildren } from 'react';

import css from './more-button.module.scss';

type MoreButtonProps = {
    isExpanded: boolean;
    onClick: () => void;
    ariaLabelPrefix?: string;
};

export const MoreButton: FC<PropsWithChildren<MoreButtonProps>> = ({
    isExpanded,
    onClick,
    ariaLabelPrefix,
    ...rest
}) => {
    const { t } = useTranslations('search');
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === ENTER_KEY) {
            onClick();
        }
    };

    return (
        <button
            {...createTestHook(SEARCH_FILTERS_GROUP_MORE_BUTTON)}
            className={css.root}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            aria-label={`${ariaLabelPrefix || ''} ${
                isExpanded ? t('filters.less') : t('filters.all')
            }`}
            {...rest}
        >
            <div className={css.outline} aria-hidden="true">
                {isExpanded ? (
                    <Minus16Icon className={css.icon} />
                ) : (
                    <Plus16Icon className={css.icon} />
                )}
                <Typography variant="body-strong">
                    {isExpanded ? t('filters.less') : t('filters.all')}
                </Typography>
            </div>
        </button>
    );
};
