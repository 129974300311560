import type { ApolloQueryResult } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import {
    useBasketLazyQuery,
    useOrderLazyQuery,
} from '../graphql/_generated-hooks';
import type {
    BasketItemProductFragment,
    BasketQuery,
    Exact,
    OrderLineV2Fragment,
    OrderQuery,
} from '../graphql/_generated-operation-types';

export interface UseProductsInBasket {
    loading: boolean;
    refetchBasket: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined,
    ) => Promise<ApolloQueryResult<BasketQuery>>;
    refetchOrder: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined,
    ) => Promise<ApolloQueryResult<OrderQuery>>;
    data: ProductsInBasket;
    fetch: () => void;
}

export type ProductsInBasket = {
    isOrderMode: boolean;
    products: ProductsInBasketItem[];
};

export type ProductsInBasketItem = {
    id: number;
    quantity: number;
    allocatedQuantity?: number | null;
};

export const normalizeProductsInBasket = (
    basketData: BasketQuery,
    orderData: OrderQuery,
): ProductsInBasket => {
    const { basket } = basketData;
    const { order } = orderData;

    const isOrderMode = !!order?.id;

    const products = isOrderMode
        ? (order.orderLines as OrderLineV2Fragment[])
        : (basket?.products?.filter(
              item => item.__typename === 'BasketItemProduct',
          ) as BasketItemProductFragment[]) || [];

    return {
        isOrderMode,
        products: products.map(item => ({
            id: item?.product?.id,
            quantity: item.quantity,
            allocatedQuantity:
                item.__typename === 'OrderLineV2'
                    ? item.allocatedQuantity
                    : undefined,
        })),
    };
};

export const useProductsInBasket = (): UseProductsInBasket => {
    const [
        fetchBasket,
        {
            loading: basketLoading,
            data: basket,
            refetch: refetchBasket,
            called,
        },
    ] = useBasketLazyQuery({
        fetchPolicy: 'cache-first',
    });

    const [
        fetchOrder,
        { loading: orderLoading, data: order, refetch: refetchOrder },
    ] = useOrderLazyQuery({
        fetchPolicy: 'cache-first',
    });

    const loading = orderLoading && basketLoading;

    const fetch = useCallback(() => {
        fetchOrder();
        fetchBasket();
    }, [fetchBasket, fetchOrder]);

    useEffect(() => {
        if (!called) {
            fetch();
        }
    }, [called, fetch]);

    if (typeof basket === 'undefined' || typeof order === 'undefined') {
        return {
            refetchBasket,
            refetchOrder,
            loading,
            data: {
                isOrderMode: false,
                products: [],
            },
            fetch,
        };
    }

    return {
        refetchBasket,
        refetchOrder,
        loading,
        data: normalizeProductsInBasket(basket, order),
        fetch,
    };
};
