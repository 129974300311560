import { AllerhandeLogo } from '@components/elements/allerhande-logo/allerhande-logo';
import { LabeledLink } from '@components/elements/labeled-link/labeled-link';
import { RecipeAutosuggest } from '@components/features/recipe-autosuggest/recipe-autosuggest';
import { QueryParamKeys, SearchType } from '@constants/search';
import { AllerhandeRoute } from '@enums/route';
import { Holiday, currentHoliday } from '@helpers/current-holiday';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SEARCH_BANNER_LANE_BOTTOM,
    SEARCH_BANNER_LANE_IMAGE,
    SEARCH_BANNER_LANE_RECIPE_LINK,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { typographyTagFromTaglevel } from '@royalaholddelhaize/ah-ui-cms/src/helpers/tag-level';
import { generateRecipeImageRenditions } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/utils/image-renditions';
import type { RecipeImage } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/_generated-graphql-types';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { useRouter } from 'next/navigation';
import type { FC } from 'react';
import type { RecipeSearchBannerLaneProps } from '../../recipe-search-banner-lane';
import { BannerQuickEntries } from '../banner-quick-entries/banner-quick-entries';

import css from './banner.module.scss';

type BannerType =
    | 'recipe'
    | 'searchHint'
    | 'searchHintShort'
    | 'quickSearchEntries'
    | 'tagLevel';

export const Banner: FC<Pick<RecipeSearchBannerLaneProps, BannerType>> = ({
    tagLevel = 2,
    recipe,
    searchHint,
    searchHintShort,
    quickSearchEntries,
}) => {
    const { t } = useTranslations();
    const router = useRouter();
    const { viewport } = useViewport();
    const isMobile = [Viewport.EXTRA_SMALL, Viewport.SMALL].includes(viewport);

    const navigateToSearch = (suggestion?: string): void => {
        const url = suggestion
            ? `${AllerhandeRoute.RecipeSearch}?${QueryParamKeys.SEARCH}=${encodeURIComponent(
                  suggestion,
              )}`
            : AllerhandeRoute.RecipeSearch;

        router.push(url);
    };

    const handleSubmit = (searchQuery?: string): void => {
        navigateToSearch(searchQuery);
    };

    const titleAltText = recipe?.title
        ? t('recipe-details:image.label', {
              title: recipe.title,
          })
        : t('recipe-details:image.generic');

    return (
        <div className={css.root}>
            {recipe?.images && (
                <div {...createTestHook(SEARCH_BANNER_LANE_IMAGE)}>
                    <img
                        className={css.image}
                        aria-hidden
                        alt={titleAltText}
                        title={titleAltText}
                        srcSet={generateRecipeImageRenditions(
                            (recipe?.images as RecipeImage[]) || [],
                        )}
                        sizes="(min-width: 1488px): 720px,
                        (min-width: 1024px): calc((100vw - 48px) / 2),
                        calc(100vw - 32px)"
                        fetchPriority="high"
                    />
                </div>
            )}

            <div className={css.overlay} />

            <div className={css.contentWrapper}>
                <Typography
                    className={css.heading}
                    variant="display"
                    as={typographyTagFromTaglevel(tagLevel || 1)}
                    align="center"
                >
                    {t('cms:recipe-search-banner.title')}
                </Typography>

                <RecipeAutosuggest
                    className={css.searchInput}
                    searchType={SearchType.RECIPE}
                    variant="big"
                    placeholder={isMobile ? searchHintShort : searchHint}
                    onSearch={handleSubmit}
                />
                <BannerQuickEntries quickSearchEntries={quickSearchEntries} />
                <div
                    className={css.bottom}
                    {...createTestHook(SEARCH_BANNER_LANE_BOTTOM)}
                >
                    <AllerhandeLogo
                        aria-hidden="true"
                        className={css.logoImg}
                        variant={
                            currentHoliday() === Holiday.CHRISTMAS
                                ? 'christmas'
                                : 'white'
                        }
                    />

                    {recipe?.href && recipe.title && (
                        <LabeledLink
                            label={t('recipe', { count: 2 })}
                            aria-label={`${t('recipe', {
                                count: 1,
                            })}: ${recipe.title}`}
                            href={recipe?.href}
                            target="_self"
                            className={css.recipeLink}
                            {...createTestHook(SEARCH_BANNER_LANE_RECIPE_LINK)}
                        >
                            {recipe.title}
                        </LabeledLink>
                    )}
                </div>
            </div>
        </div>
    );
};
