import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { getCMSLinkUrl } from '@helpers/url';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { createContentListLinkGroupTestHook } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronRight24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-24';
import { Divider } from '@royalaholddelhaize/design-system-pantry-web/components/divider/divider';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { emitContentListLinkGroupClick } from '../../helpers/analytics';

import css from './content-list-link-group.module.scss';

export interface ContentListLink {
    url: string;
    label: string;
    target?: string;
}

export interface LinkGroupProps {
    title?: string;
    links: ContentListLink[];
    hasLeftSibling?: boolean;
    hasRightSibling?: boolean;
}

export const ContentListLinkGroup: FC<
    LinkGroupProps & { tagLevel: number }
> = ({ title, links }) => {
    const handleClick = (linkTitle: string, href: string) => () => {
        emitContentListLinkGroupClick({
            linkLabel: linkTitle,
            groupTitle: title,
            href,
        });
    };

    return (
        <>
            <div className={css.titleWrapper}>
                <Typography className={css.title} variant="heading-2" as="p">
                    {title}
                </Typography>
            </div>

            <Divider />

            <div className={css.links}>
                {links.map(({ label, url, target }, idx) => {
                    const href = getCMSLinkUrl(url);
                    return (
                        <div
                            className={css.link}
                            key={`${label}-${url}-${
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                idx
                            }`}
                        >
                            <LinkOrAnchor
                                className={css.linkAnchor}
                                href={href}
                                target={target || '_self'}
                                onClick={handleClick(label, href)}
                                title={label}
                                aria-label={label}
                                {...createTestHook(
                                    createContentListLinkGroupTestHook(
                                        label,
                                        idx,
                                    ),
                                )}
                            >
                                <ChevronRight24Icon
                                    size="12"
                                    className={css.linkIcon}
                                />
                                {label}
                            </LinkOrAnchor>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
