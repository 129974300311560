import type { FC } from 'react';
import { CardBody } from './elements/card-body/card-body';
import { CardMediaSkeleton } from './elements/card-media-skeleton/card-media-skeleton';
import { Card } from './elements/card/card';

export const SkeletonCard: FC = () => (
    <Card>
        <CardMediaSkeleton />

        <CardBody />
    </Card>
);
