export type BuildRobotsMetaTagOptions = Partial<{
    index: boolean;
    follow: boolean;
}>;

export const buildRobotsMetaTag = ({
    index = true,
    follow = true,
}: BuildRobotsMetaTagOptions) => {
    return [
        'noodp',
        index === false ? 'noindex' : 'index',
        follow === false ? 'nofollow' : 'follow',
    ].join(', ');
};
