import type { ComponentProps, FC } from 'react';

export const StickerEtos: FC<ComponentProps<'svg'>> = props => (
    <svg viewBox="0 0 101 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>Etos</title>
        <g transform="translate(.5)" fill="none" fill-rule="evenodd">
            <circle fill="#FFF" fillRule="nonzero" cx="50" cy="50" r="50" />
            <path
                d="M26.433 65.117c8.784-4.1 25.884-8.484 47.134-10.767l-.25 1.833c-10.167 1.367-31.567 7.284-42 12.767l-4.884-3.833zm7.75-8.017c2.8 0 5.35-1.167 6.2-1.783-.483-.717-.916-1.85-.983-2.6-.983.433-2.3.783-3.383.783-2.984 0-4.15-1.667-4.15-3.617 0-3.466 3.516-5.766 7.783-6.433l.783-2.7h-.016a6.73 6.73 0 0 1-1.134.083c-2.916 0-4.533-1.5-4.533-3.85 0-1.966 1.25-3.5 3.433-3.5.834 0 1.8.35 2.15.884-.966.183-1.6.883-1.6 1.95 0 1.116.95 1.883 2.067 1.883 1.733 0 2.667-1.333 2.667-2.8 0-3.017-3.167-4.283-5.467-4.283-4.267 0-7.55 2.55-7.55 6.25 0 2.633 2.25 4.416 4.717 4.616-5.084 1.084-8.8 4.334-8.8 8.767 0 2.933 2.05 6.35 7.816 6.35zm36.65-16c0-.883.634-1.55 1.267-1.85l-1.033-1.867c-2.034.55-4.167 3.984-8.434 4.367-.6-1.867-1.8-2.9-3.116-2.9-1.184 0-2 .767-2 2.017 0 1.683 1.366 2.783 3.233 3 .1.283.15.916.15 1.583 0 2.3-1.283 5.5-3.717 5.5-1.583 0-2.366-1.483-2.366-3.25 0-2.167.816-4.2 1.583-5a5.716 5.716 0 0 1-.283-1.733c0-.5.083-1 .266-1.517-3 1.2-5.35 4.917-5.35 9.033 0 .134.017.55.067.817-1.2 1.667-3.15 2.9-4.517 2.9-1 0-1.316-.733-1.316-1.35 0-.383.083-1.017.383-2.133l1.667-6.284 3-.3.733-2.633-3 .3 1.367-4.983-3.284 1.016c-.533 2.1-1.2 4.167-4.033 4.7l-.717 2.617c.534-.1.967-.167 1.684-.267-.584 1.9-1.35 4.8-1.6 5.9-.267 1.15-.417 2.034-.417 2.617 0 2.633 1.767 4.05 4.167 4.05 2.683 0 5.25-1.983 6.483-3.783.55 1.333 2.183 2.55 4.3 2.55 3.75 0 7.217-3.7 7.217-8.767 0-.65-.05-1.3-.134-1.617 1.45-.116 3.334-.716 4.417-1.533-.033.1-.05.267-.05.367 0 1.866 2.417 4.4 2.417 6.2 0 2.116-1.517 2.85-2.5 2.85-.334 0-.65-.05-.8-.117.133-.2.183-.6.183-.817 0-.883-.7-1.516-1.533-1.516-.934 0-1.734.766-1.734 1.8 0 1.9 1.834 2.633 3.867 2.633 2.85 0 6.267-1.833 6.267-5.683 0-3.134-2.784-5.25-2.784-6.917z"
                fill="#000"
            />
        </g>
    </svg>
);
