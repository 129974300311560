import type { ContentCmsComponentType } from '@graphql/_generated-operation-types';
import type { ContentBaseCmsComponent } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import type {
    ContentImage,
    ContentImageSet,
} from '@royalaholddelhaize/ah-ui-cms/src/interfaces/image-set.interface';

export interface TextImageItemsContentLaneProps
    extends Omit<ContentBaseCmsComponent, 'type'> {
    type: ContentCmsComponentType;
    contentLaneItems: TextImageItemsContentLaneItem[];
}

export type TextImageItemsContentLaneItem = {
    title: string;
    description: string;
    image?: ContentImage;
    recipe?: {
        title: string;
        description: string;
        images: ContentImageSet[];
    };
};

export enum Orientation {
    LEFT = 'left',
    RIGHT = 'right',
}

export type BlockProps = {
    orientation: Orientation;
    title: string;
    description: string;
    images?: ContentImageSet[];
    isMobile: boolean;
    tagLevel: number;
};
