import type { ApolloError } from '@apollo/client/errors';
import type { RecipeImage } from '../../_generated-graphql-types';
import {
    RecipeCollectionCategoriesDocument,
    useRecipeCollectionAssignRecipeToCategoriesMutation,
} from '../../_generated-hooks';
import {
    type CollectionCategoryRecipeType,
    MutationResultStatus,
    type RecipeCollectionCategoriesQuery,
} from '../../_generated-operation-types';

export interface UseAssignRecipeToCategories {
    loading: boolean;
    error?: ApolloError;
    assignRecipeToCategories: (
        recipe: {
            id: number;
            type: CollectionCategoryRecipeType;
            images?: RecipeImage[];
        },
        categoryIds: number[],
        onSaveListCompleted?: (hasError: boolean) => void,
    ) => void;
}

export const useAssignRecipeToCategories = (): UseAssignRecipeToCategories => {
    const [assignRecipeToCategoriesMutation, { loading, error }] =
        useRecipeCollectionAssignRecipeToCategoriesMutation({
            fetchPolicy: 'network-only',
        });

    const assignRecipeToCategories = (
        recipe: {
            id: number;
            type: CollectionCategoryRecipeType;
            images?: RecipeImage[];
        },
        categoryIds: number[],
        onSaveListCompleted?: (hasError: boolean) => void,
    ): void => {
        assignRecipeToCategoriesMutation({
            variables: {
                recipeId: recipe.id,
                collectedRecipeType: recipe.type,
                categoryIds,
            },
            onCompleted: () =>
                onSaveListCompleted && onSaveListCompleted(false),
            onError: () => onSaveListCompleted && onSaveListCompleted(true),
            update: (store, { data: newData, errors }) => {
                if (
                    newData &&
                    !errors &&
                    newData.recipeCollectionAssignRecipeToCategories.status ===
                        MutationResultStatus.SUCCESS
                ) {
                    store.updateQuery<RecipeCollectionCategoriesQuery>(
                        {
                            query: RecipeCollectionCategoriesDocument,
                        },
                        data => ({
                            recipeCollectionCategories:
                                data?.recipeCollectionCategories.map(
                                    category => {
                                        if (
                                            categoryIds.includes(category.id) &&
                                            !category.list.find(
                                                collectedRecipe =>
                                                    collectedRecipe.id ===
                                                    recipe.id,
                                            )
                                        ) {
                                            return {
                                                ...category,
                                                list: [
                                                    {
                                                        __typename:
                                                            'RecipeCollectionCategoryRecipe',
                                                        id: recipe.id,
                                                        type: recipe.type,
                                                    },
                                                    ...category.list,
                                                ],
                                                recipes: [
                                                    {
                                                        __typename:
                                                            'RecipeSummary',
                                                        id: recipe.id,
                                                        images:
                                                            recipe.images?.map(
                                                                e => ({
                                                                    ...e,
                                                                    url:
                                                                        e.url ||
                                                                        '',
                                                                    height:
                                                                        e.height ||
                                                                        0,
                                                                    width:
                                                                        e.width ||
                                                                        0,
                                                                }),
                                                            ) || [],
                                                    },
                                                    ...category.recipes,
                                                ],
                                            };
                                        }

                                        if (
                                            !categoryIds.includes(category.id)
                                        ) {
                                            return {
                                                ...category,
                                                list: category.list.filter(
                                                    collectedRecipe =>
                                                        collectedRecipe.id !==
                                                        recipe.id,
                                                ),
                                                recipes:
                                                    category.recipes.filter(
                                                        collectedRecipe =>
                                                            collectedRecipe.id !==
                                                            recipe.id,
                                                    ),
                                            };
                                        }

                                        return category;
                                    },
                                ) || [],
                        }),
                    );
                }
            },
        }).catch();
    };

    return {
        assignRecipeToCategories,
        loading,
        error,
    };
};
