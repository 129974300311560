import { type PageModel, initialize } from '@bloomreach/spa-sdk';
import { useEffect } from 'react';
import { ContentComponentType } from '../../enums';
import type {
    ContentBaseCmsComponent,
    ContentComponent,
    ContentPage,
    ContentRootComponent,
    Model,
} from '../../interfaces';
import { isContainerComponent } from '../helpers/is-container-component.helper';
import {
    addComponentComments,
    addContainerComments,
} from '../helpers/preview-comments';
import { PreviewComponent } from './preview-component';
import { PreviewComponentWrapper } from './preview-component-wrapper';

export interface PreviewProps {
    content: ContentPage | ContentPage<ContentBaseCmsComponent>;
    findModel: (component: ContentComponent) => Model | null;
}

type Params = { [key: string]: string };

type HttpHeaders = Record<string, string | number | boolean>;

type HttpClientConfig = {
    method: 'GET' | 'POST';
    url: string;
    headers?: HttpHeaders;
    /* biome-ignore lint/suspicious/noExplicitAny: <explanation> */
    data?: any;
};

type HttpClient<T> = (config: HttpClientConfig) => Promise<HttpResponse<T>>;

interface HttpResponse<T> {
    data: T;
}

const pool = new Map();
const FALLBACK_ORIGIN = 'https://ah-cms-tst.kaas.nonprd.k8s.ah.technology/';

const getSearchParams = (location: Location): Params =>
    Object.fromEntries(new URLSearchParams(location.search));

const getOriginHost = (params: Params): string =>
    params.originHost ? params.originHost : FALLBACK_ORIGIN;

const getOriginsFromParams = () => {
    const params = getSearchParams(window.location);
    const originalHost = getOriginHost(params);
    return originalHost.replace('/site', '');
};

const postMessage = (command: unknown, ...payload: unknown[]) => {
    return new Promise((resolve, reject) => {
        const id = Math.random();
        pool.set(id, [resolve, reject]);
        window.parent.postMessage(
            {
                id,
                payload,
                type: 'brxm:request',
                command,
            },
            getOriginsFromParams(),
        );
    });
};
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const processRequest = (request: any) => {
    const { payload } = request;
    try {
        window.parent.postMessage(
            {
                payload,
                id: request.id,
                state: 'fulfilled',
                type: 'brxm:response',
            },
            getOriginsFromParams(),
        );
    } catch {
        window.parent.postMessage(
            {
                payload,
                id: request.id,
                state: 'rejected',
                type: 'brxm:response',
            },
            getOriginsFromParams(),
        );
    }
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const processResponse = (pool: any, response: any) => {
    if (!pool.has(response.id)) {
        return;
    }

    const [resolve, reject] = pool.get(response.id);
    pool.delete(response.id);

    if (response.state === 'rejected') {
        reject(response.result);

        return;
    }

    resolve(response.resolve);
};

export const Preview = ({ content, findModel }: PreviewProps) => {
    useEffect(() => {
        const { location } = window;
        const params = getSearchParams(location);

        if (params.rootPath && location.pathname === '/') {
            location.href =
                window?.location.origin + params.rootPath + location.search;
        }
    }, []);

    useEffect(() => {
        const { location } = window;
        const params = getSearchParams(location);
        const originalHost = getOriginHost(params);
        const origin = getOriginsFromParams();

        const httpClient: HttpClient<PageModel> = async (
            clientConfig: HttpClientConfig,
        ): Promise<HttpResponse<PageModel>> => {
            const response = await fetch(clientConfig.url, {
                method: clientConfig.method,
                body: clientConfig.data
                    ? JSON.stringify(clientConfig.data)
                    : undefined,
                headers: clientConfig.headers as HeadersInit,
                credentials: 'include',
            });

            const data: PageModel = await response.json();

            return { data };
        };

        initialize({
            debug: true,
            origin, // example: "https://ah-cms-tst.kaas.nonprd.k8s.ah.technology/"
            path: params?.apiRoot,
            httpClient,
            apiBaseUrl: originalHost + params.apiPath, // example: "https://ah-cms-tst.kaas.nonprd.k8s.ah.technology/site/ah-nl-core/resourceapi"
            endpoint: originalHost + params.apiPath, // example: "https://ah-cms-tst.kaas.nonprd.k8s.ah.technology/site/ah-nl-core/resourceapi"
            spaBaseUrl: `${location.origin}/`, // example: https://ah-cms-tst.kaas.nonprd.k8s.ah.technology/
            baseUrl: `${location.origin}/`, // example: https://ah-cms-tst.kaas.nonprd.k8s.ah.technology/
            apiVersion: '1.0',
            authorizationHeader: 'X-CMS-Authorization',
            authorizationToken: params['cms-token'] || params.token,
            serverId: `SERVERID=${params['server-id']}`,
            serverIdHeader: 'Cookie',
        });

        // Create event listener to handle post message events from CMS
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const handleMessage = (event: any) => {
            if (event.origin !== origin) {
                console.debug(`Ignored event from origin: ${event.origin}`);
                return;
            }

            if (event?.data?.type === 'brxm:request') {
                processRequest(event.data);
            }
            if (event?.data?.type === 'brxm:response') {
                processResponse(pool, event.data);
            }
        };

        window.addEventListener('message', handleMessage);

        // Perform a sync
        postMessage('sync');

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    if (!content) {
        return null;
    }

    const submenu = content?.submenu
        ? {
              ...content.submenu,
              type: ContentComponentType.SUBMENU,
          }
        : null;

    const submenuModel = submenu
        ? findModel(submenu as unknown as ContentComponent)
        : null;

    if (content?.submenu && !submenuModel) {
        throw new Error('Submenu Model is not provided in the app');
    }

    return (
        <>
            {content?.submenu && (
                <PreviewComponent
                    key={content?.submenu?.id}
                    model={submenuModel}
                    component={content?.submenu as unknown as ContentComponent}
                    placeComments={addComponentComments}
                />
            )}
            {content.components.map(component => {
                const rootComponent = component as ContentRootComponent;

                if (isContainerComponent(rootComponent)) {
                    return (
                        <PreviewComponentWrapper
                            id={component.id}
                            key={component.id}
                            placeComments={addContainerComments}
                            preview={
                                rootComponent.previewData || {
                                    begin: '',
                                    end: '',
                                    contentLink: '',
                                    visible: true,
                                }
                            }
                        >
                            {rootComponent.items.map(nestedComponent => {
                                return (
                                    <PreviewComponent
                                        key={nestedComponent.id}
                                        model={findModel(nestedComponent)}
                                        component={nestedComponent}
                                        placeComments={addComponentComments}
                                    />
                                );
                            })}
                        </PreviewComponentWrapper>
                    );
                }

                return (
                    <PreviewComponent
                        key={component.id}
                        model={findModel(component)}
                        component={component as ContentBaseCmsComponent}
                        placeComments={addComponentComments}
                    />
                );
            })}
        </>
    );
};
