import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { CONTENT_LIST_LANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import classNames from 'clsx';
import type { FC } from 'react';
import type { ContentListLaneProps } from './component.interface';
import {
    ContentListImage,
    ImageOrientation,
} from './elements/content-list-image/content-list-image';
import { ContentListLinkGroups } from './elements/content-list-link-groups/content-list-link-groups';

import css from './content-list-lane.module.scss';

export const ContentListLane: FC<ContentListLaneProps> = ({
    title,
    groups,
    image,
    imagePosition = ImageOrientation.LEFT,
    anchorId,
    tagLevel,
}) => {
    const { viewport } = useViewport();
    const isDesktop = [Viewport.LARGE, Viewport.EXTRA_LARGE].includes(viewport);

    const isImageVisible = isDesktop && image;
    const isLeftImageVisible =
        isImageVisible &&
        (imagePosition === ImageOrientation.LEFT || imagePosition === null);
    const isRightImageVisible =
        isImageVisible && imagePosition === ImageOrientation.RIGHT;

    if (!groups) {
        return null;
    }

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            className={css.root}
            id={anchorId || undefined}
            {...createTestHook(CONTENT_LIST_LANE)}
        >
            {image && isLeftImageVisible && (
                <div className={css.image}>
                    <ContentListImage
                        imgSet={image}
                        orientation={imagePosition || ImageOrientation.LEFT}
                    />
                </div>
            )}

            <ContentListLinkGroups
                className={classNames(css.content, {
                    [css.contentWithImage]:
                        image && (isLeftImageVisible || isRightImageVisible),
                })}
                title={title}
                groups={groups}
                fullWidth={Boolean(!isImageVisible || !image)}
                tagLevel={tagLevel || 2}
            />

            {image && isRightImageVisible && (
                <div className={classNames(css.image, css.imageRight)}>
                    <ContentListImage
                        imgSet={image}
                        orientation={imagePosition}
                    />
                </div>
            )}
        </Container>
    );
};
