import { typographyTagFromTaglevel } from '@helpers/cms';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { ThemeLaneProps } from '../../component.interface';

import css from './theme-lane-header.module.scss';

type ThemeLaneHeaderProps = Pick<
    ThemeLaneProps,
    'title' | 'subtitle' | 'tagLevel'
>;

export const ThemeLaneHeader: FC<ThemeLaneHeaderProps> = ({
    title,
    subtitle,
    tagLevel,
}: ThemeLaneHeaderProps) => {
    return (
        <div className={css.root}>
            {title && (
                <Typography
                    variant="heading-1"
                    align="center"
                    as={typographyTagFromTaglevel(tagLevel || 2)}
                >
                    {title}
                </Typography>
            )}
            {subtitle && (
                <>
                    <div className={css.separator} />
                    <Typography variant="body-strong" as="span">
                        {subtitle}
                    </Typography>
                </>
            )}
        </div>
    );
};
