import type { Breadcrumb } from '@interfaces/breadcrumb';
import {
    type FC,
    type PropsWithChildren,
    createContext,
    useContext,
} from 'react';

export const BreadcrumbsContext = createContext<Breadcrumb[]>([]);

export const BreadcrumbsProvider: FC<
    PropsWithChildren<{ value: Breadcrumb[] }>
> = ({ value, children }) => (
    <BreadcrumbsContext.Provider value={value}>
        {children}
    </BreadcrumbsContext.Provider>
);

export const useBreadcrumbs = (): Breadcrumb[] => {
    const context = useContext(BreadcrumbsContext);

    if (!context) {
        return [];
    }

    return context;
};
