import { NetworkStatus } from '@apollo/client';
import type { RecipeImage } from '../_generated-graphql-types';
import { useRecipeCollectionCategoriesQuery } from '../_generated-hooks';
import type {
    CollectionCategoryRecipeType,
    RecipeCollectionCategoryFragment,
} from '../_generated-operation-types';
import { useAssignRecipeToCategories } from './hooks/assign-recipe-to-categories';
import { useCreateCategory } from './hooks/create-category';
import { useDeleteCategory } from './hooks/delete-category';
import { useRemoveRecipeFromCategories } from './hooks/delete-recipe-from-categories';
import { useUpdateCategory } from './hooks/update-category';

interface UseRecipeCollectionCategoriesProps {
    member?: {
        isRegistered: boolean;
    };
}

export interface UseRecipeCollectionCategories {
    networkStatus: NetworkStatus;
    isLoading: boolean;
    isChangeLoading: boolean;
    isChangeError: boolean;
    createCategoryError: boolean;
    isCreateCategoryLoading: boolean;
    isDeleteCategoryLoading: boolean;
    isUpdateCategoryLoading: boolean;
    categories: RecipeCollectionCategoryFragment[];
    assignRecipe: (
        recipe: {
            id: number;
            type: CollectionCategoryRecipeType;
            images?: RecipeImage[];
        },
        categoryIds: number[],
        onSaveListCompleted?: () => void,
    ) => void;
    removeRecipe: (
        recipeId: number,
        type: CollectionCategoryRecipeType,
        onSaveListCompleted?: (hasError: boolean) => void,
    ) => void;
    createCategory: (
        name: string,
        onCreateCategoryCompleted?: () => void,
    ) => void;
    deleteCategory: (id: number) => void;
    updateCategory: (id: number, name: string) => void;
}

export const useRecipeCollectionCategories = ({
    member,
}: UseRecipeCollectionCategoriesProps): UseRecipeCollectionCategories => {
    const { data, networkStatus } = useRecipeCollectionCategoriesQuery({
        skip: !member || !member.isRegistered,
        ssr: false,
        notifyOnNetworkStatusChange: true,
    });

    const isLoading = [
        NetworkStatus.loading,
        NetworkStatus.setVariables,
    ].includes(networkStatus);

    const categories = data?.recipeCollectionCategories || [];

    const {
        assignRecipeToCategories,
        loading: isAssignLoading,
        error: isAssignError,
    } = useAssignRecipeToCategories();
    const {
        removeRecipeFromCategories,
        loading: isRemoveLoading,
        error: isRemoveError,
    } = useRemoveRecipeFromCategories();
    const {
        createCategory,
        loading: isCreateCategoryLoading,
        hasError: createCategoryError,
    } = useCreateCategory();
    const { deleteCategory, loading: isDeleteCategoryLoading } =
        useDeleteCategory();
    const { updateCategory, loading: isUpdateCategoryLoading } =
        useUpdateCategory();

    return {
        categories,
        isChangeLoading: isAssignLoading || isRemoveLoading,
        isChangeError: Boolean(isAssignError || isRemoveError),
        networkStatus,
        isLoading,
        isCreateCategoryLoading,
        isDeleteCategoryLoading,
        isUpdateCategoryLoading,
        assignRecipe: assignRecipeToCategories,
        removeRecipe: removeRecipeFromCategories,
        createCategory,
        deleteCategory,
        updateCategory,
        createCategoryError,
    };
};
