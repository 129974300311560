import { useMemberFoodPreferencesQuery } from '@graphql/_generated-hooks';

export const useMemberPreferences = (skip = false) => {
    const { data, previousData, loading, error } =
        useMemberFoodPreferencesQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            skip,
        });

    return {
        preferences: data?.recipePreferences || previousData?.recipePreferences,
        loading,
        error,
    };
};
