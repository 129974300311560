import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import {
    type RecipeAuthorFragment,
    type RecipeFragment,
    type RecipeImageFragment,
    RecipeOriginType,
} from '@graphql/_generated-operation-types';
import type { ContentImageSet } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import classNames from 'clsx';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { ImageSheetFavorite } from './elements/image-sheet-favorite/image-sheet-favorite';
import { ImageSheetImage } from './elements/image-sheet-image/image-sheet-image';
import { ImageSheetSponsor } from './elements/image-sheet-sponsor/image-sheet-sponsor';
import { ImageSheetVideo } from './elements/image-sheet-video/image-sheet-video';

import css from './image-sheet.module.scss';

interface ImageSheetProps extends PropsWithChildren {
    recipeId?: number;
    alt: string;
    images?: (RecipeImageFragment | ContentImageSet)[];
    video?: RecipeFragment['videos']['preparation'];
    author?: RecipeAuthorFragment;
    lazyLoad?: boolean;
    mediaChildren?: ReactNode;
}

export const ImageSheet: FC<ImageSheetProps> = ({
    alt,
    recipeId,
    author,
    images,
    video,
    children,
    mediaChildren,
    ...restProps
}) => (
    <Container
        className={css.containerDesktop}
        maxWidth={MAX_CONTAINER_WIDTH}
        {...restProps}
    >
        <header className={css.root}>
            <div className={css.media}>
                {images && <ImageSheetImage alt={alt} images={images} />}

                <div className={css.toolbar}>
                    {author?.brand && (
                        <div className={css.logo}>
                            <ImageSheetSponsor brand={author.brand} />
                        </div>
                    )}

                    {recipeId && (
                        <div className={css.favorite}>
                            <ImageSheetFavorite
                                recipeId={recipeId}
                                recipeType={
                                    author?.origin?.type ||
                                    RecipeOriginType.ALLERHANDE
                                }
                            />
                        </div>
                    )}
                </div>

                {video && (
                    <div
                        className={classNames(
                            css.video,
                            author?.brand && css.videoWithBrand,
                        )}
                    >
                        <ImageSheetVideo
                            videoId={video.id}
                            videoTitle={video.title}
                        />
                    </div>
                )}

                {mediaChildren}
            </div>

            <Container
                className={css.containerMobile}
                maxWidth={MAX_CONTAINER_WIDTH}
            >
                <div
                    className={classNames(
                        css.content,
                        images?.length === 0 && css.contentIndex,
                    )}
                >
                    {children}
                </div>
            </Container>
        </header>
    </Container>
);
