import ArrowRight from '@assets/icons/arrow-right.svg';
import { ENTER_KEY } from '@constants/keycode';
import { highlightQueryKeyword } from '@helpers/highlight-query-keyword';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_BANNER_LANE_SUGGESTION_ITEM } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { FC, KeyboardEvent, PropsWithChildren } from 'react';

import css from './suggestions.module.scss';

interface SuggestionsProps {
    query: string;
    suggestions: string[];
    onSuggestionInteract(suggestion: string): void;
    selectedSuggestionIndex: number | null;
}

export const Suggestions: FC<PropsWithChildren<SuggestionsProps>> = ({
    query,
    suggestions = [],
    onSuggestionInteract,
    selectedSuggestionIndex,
}) => {
    return (
        <div className={css.root}>
            <ul className={css.list}>
                {suggestions.map((suggestion, i) => {
                    return (
                        <li
                            key={suggestion}
                            className={classNames(
                                css.listItem,
                                selectedSuggestionIndex === i &&
                                    css.selectedItem,
                            )}
                        >
                            <button
                                className={css.listItemWrapper}
                                onClick={() => onSuggestionInteract(suggestion)}
                                onKeyDown={(e: KeyboardEvent) => {
                                    if (e.code === ENTER_KEY) {
                                        onSuggestionInteract(suggestion);
                                    }
                                }}
                                tabIndex={0}
                                {...createTestHook(
                                    SEARCH_BANNER_LANE_SUGGESTION_ITEM,
                                )}
                            >
                                <span className={css.listItemTextWrapper}>
                                    <Typography
                                        as="span"
                                        className={css.listItemText}
                                        // biome-ignore lint/security/noDangerouslySetInnerHtml: input is only set by us
                                        dangerouslySetInnerHTML={{
                                            __html: highlightQueryKeyword(
                                                suggestion,
                                                query,
                                            ),
                                        }}
                                    />

                                    <ArrowRight
                                        className={css.listItemIcon}
                                        width={24}
                                        height={24}
                                    />
                                </span>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
