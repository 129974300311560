import { Carousel, CarouselItem } from '@components/elements/carousel/carousel';
import { BasketProvider } from '@contexts/basket';
import { typographyTagFromTaglevel } from '@helpers/cms';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { PRODUCT_LANE } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ScrollToAnchorId } from '@royalaholddelhaize/ah-ui-cms/src/components/scroll-to-anchor-id';
import {
    LinkAnchor,
    LinkText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/link-button/link-button';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { ProductCard } from './elements/product-card/product-card';
import type { ProductsProps } from './product-lane.interface';

import css from './product-lane.module.scss';

export const Products: FC<ProductsProps> = ({
    id,
    products,
    locale,
    anchorId,
    title,
    subtitle,
    tagLevel,
    link,
    color,
    ...restProps
}) => {
    return (
        <BasketProvider>
            <Container {...restProps} maxWidth={1488} data-brand="ah">
                <ScrollToAnchorId anchorId={anchorId} />
                <Grid {...createTestHook(PRODUCT_LANE)} className={css.root}>
                    <GridItem xs={4} className={css.titleRow}>
                        {title && (
                            <Typography
                                variant="heading-1"
                                as={typographyTagFromTaglevel(tagLevel || 2)}
                            >
                                {title}
                            </Typography>
                        )}

                        {subtitle && (
                            <Typography variant="body-regular" as="h3">
                                {subtitle}
                            </Typography>
                        )}

                        {link && link.href && (
                            <LinkAnchor
                                href={link.href}
                                className={css.seeMore}
                            >
                                <LinkText>{link.title}</LinkText>
                            </LinkAnchor>
                        )}
                    </GridItem>

                    <GridItem xs={4}>
                        <Carousel locale={locale} data-brand="ah">
                            {products
                                .filter(p => !!p)
                                .map((product, index) => {
                                    return (
                                        <CarouselItem
                                            key={`${id}-${product.id}-${index.toString()}`}
                                        >
                                            <ProductCard
                                                product={product}
                                                index={index}
                                            />
                                        </CarouselItem>
                                    );
                                })}
                        </Carousel>
                    </GridItem>
                </Grid>
            </Container>
        </BasketProvider>
    );
};
