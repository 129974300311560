import { moveSelectedFiltersToTheTop } from '@components/elements/filter-group/filter-group';
import { QuickFilters } from '@components/features/quick-filters/quick-filters';
import type {
    RecipeSearchFilterFragment,
    RecipeSearchFilterGroupFragment,
    RecipeSearchQueryFilter,
} from '@graphql/_generated-operation-types';
import type { FilterItemWithGroup } from '@interfaces/filter';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

/**
 * Selects filters by name from a list of RecipeSearchFilterGroupFragment objects.
 * For now we are hardcoding the filters for this A/B test and so we create a list and populate it manually
 * @param filters - An array of RecipeSearchFilterGroupFragment objects.
 * @param categories - An array of filter names to select.
 * @returns An object containing two arrays of RecipeSearchFilterFragment objects: fullFilters and combined.
 */
export const selectFiltersByName = ({
    filters,
    exclusions,
    categories,
}: {
    filters: RecipeSearchFilterGroupFragment[];
    exclusions: string[];
    categories: string[];
}) => {
    const fullFilters: RecipeSearchFilterGroupFragment[] = [];
    const popularFilters: RecipeSearchFilterFragment[] = [];
    const combinedQuickFilters: RecipeSearchFilterFragment[] = [];

    // Go through the categories and find the filters that match
    categories.forEach(category => {
        const found = filters.find(filter => filter.name === category);
        if (!found) {
            return;
        }
        // We need to keep track of the full filters so we can send the correct analytics data
        fullFilters.push(found);

        // If the category is 'veel-gebruikt' we need to keep track of the filters so we can exclude them from the other categories
        // veel-gebruikt is a special case because it takes prio
        if (category === 'veel-gebruikt') {
            popularFilters.push(...found.filters);
            return;
        }

        // remove duplicates from the combined list using popular filters as reference
        const filtersWithoutPopular = found.filters.filter(specialFilter => {
            return !popularFilters.some(
                popularFilter => popularFilter.name === specialFilter.name,
            );
        });

        combinedQuickFilters.push(...filtersWithoutPopular);
    });

    // We need to remove the exclusions from the combined list
    // They can either be a full filter name or a string that contains the filter name. This case happens when we pass in the recipe page name.
    const withoutExclusions = [
        ...popularFilters,
        ...combinedQuickFilters,
    ].filter(filter => {
        return (
            !exclusions.some(exclusion => exclusion === filter.name) &&
            !exclusions.some(exclusion => exclusion.includes(filter.name))
        );
    });

    return {
        fullQuickFilters: fullFilters,
        quickFilters: moveSelectedFiltersToTheTop(
            withoutExclusions,
        ) as FilterItemWithGroup[],
    };
};

/**
 * Creates an array of strings to exclude from a recipe search filters based on the provided filters and strings.
 * @param filters An array of recipe search query filters.
 * @param strings An array of strings to exclude from the recipe search query.
 * @returns An array of strings to exclude from the recipe search query.
 */
const createExclusions = ({
    filters,
    strings,
}: {
    filters: RecipeSearchQueryFilter[];
    strings: string[];
}) => {
    const exclusions: string[] = [...strings];
    filters.forEach(item => {
        exclusions.push(...item.values);
    });
    return exclusions;
};

interface RecipeGridLaneQuickLinksProps {
    filters: RecipeSearchFilterGroupFragment[];
    originalFilters: RecipeSearchQueryFilter[];
    changeFilter: (filter: RecipeSearchFilterFragment) => void;
}

export const RecipeGridLaneQuickLinks: React.FC<
    RecipeGridLaneQuickLinksProps
> = ({ filters, changeFilter, originalFilters }) => {
    const pathname = usePathname();

    const selectedQuickFilters = useMemo(() => {
        const recipePageName = pathname.startsWith('/allerhande/recepten')
            ? pathname.substring('/allerhande/recepten'.length + 1)
            : '';

        const exclusions = createExclusions({
            filters: originalFilters,
            strings: [recipePageName],
        });

        return selectFiltersByName({
            filters,
            categories: ['veel-gebruikt', 'speciale-wensen'],
            exclusions,
        });
    }, [filters, originalFilters, pathname]);

    const { quickFilters } = selectedQuickFilters;

    const handleFilterClick = (filter: RecipeSearchFilterFragment) => {
        changeFilter(filter);
    };

    return <QuickFilters filters={quickFilters} onSelect={handleFilterClick} />;
};
