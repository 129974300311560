import { LabeledLink } from '@components/elements/labeled-link/labeled-link';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    IMAGE_CARD_COLLECTION_LANE_LINK_NO_LINK_TEXT,
    IMAGE_CARD_COLLECTION_LANE_LINK_WITHOUT_LABEL,
    IMAGE_CARD_COLLECTION_LANE_LINK_WITH_LABEL,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import type { ImageCardItem } from '../../image-card-collection-lane.interfaces';

export interface ImageTextProps {
    card: ImageCardItem;
}

export const ImageText: FC<ImageTextProps> = ({ card }) => {
    const { t } = useTranslations();

    if (
        'recipe' in card &&
        card.recipe?.id &&
        card.recipe.title &&
        card.recipe.slugifiedTitle
    ) {
        const href = `/allerhande/recept/R-R${card.recipe.id}/${card.recipe.slugifiedTitle}`;

        return (
            <LabeledLink
                label={t('recipe', { count: 1 })}
                href={href}
                {...createTestHook(IMAGE_CARD_COLLECTION_LANE_LINK_WITH_LABEL)}
            >
                {card.recipe.title}
            </LabeledLink>
        );
    }

    if ('caption' in card && card.caption?.link) {
        return (
            <LabeledLink
                href={card.caption.link.url}
                target={card.caption.link.target}
                {...createTestHook(
                    IMAGE_CARD_COLLECTION_LANE_LINK_WITHOUT_LABEL,
                )}
            >
                {card.caption.label}
            </LabeledLink>
        );
    }

    if ('caption' in card && card.caption?.label) {
        return (
            <Typography
                {...createTestHook(
                    IMAGE_CARD_COLLECTION_LANE_LINK_NO_LINK_TEXT,
                )}
            >
                {card.caption.label}
            </Typography>
        );
    }

    return null;
};
