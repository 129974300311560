import {
    type ProductCardFragment,
    ProductIcon,
} from '@graphql/_generated-operation-types';
import {
    Bake,
    Dutch,
    Frozen,
    LargePackage,
    NutriscoreA,
    NutriscoreB,
    NutriscoreC,
    NutriscoreD,
    NutriscoreE,
    PriceFavorite,
    PriceWinner,
    Vegan,
    Vegetarian,
} from '@royalaholddelhaize/design-system-pantry-web/assets/logos/product';
import type { ComponentProps, JSXElementConstructor } from 'react';

const iconMap: Record<
    keyof typeof ProductIcon,
    JSXElementConstructor<ComponentProps<'svg'>> | null
> = {
    [ProductIcon.AVAILABLE_IN_STORE]: null,
    [ProductIcon.BAKE_OFF]: Bake,
    [ProductIcon.DISCOUNT_PACK]: LargePackage,
    [ProductIcon.FROZEN]: Frozen,
    [ProductIcon.LOCALLY_PRODUCED]: Dutch,
    [ProductIcon.NEW]: null,
    [ProductIcon.NUTRISCORE_A]: NutriscoreA,
    [ProductIcon.NUTRISCORE_B]: NutriscoreB,
    [ProductIcon.NUTRISCORE_C]: NutriscoreC,
    [ProductIcon.NUTRISCORE_D]: NutriscoreD,
    [ProductIcon.NUTRISCORE_E]: NutriscoreE,
    [ProductIcon.ORGANIC]: null,
    [ProductIcon.PRICE_FAVORITE]: PriceFavorite,
    [ProductIcon.PRIZE_WINNER]: PriceWinner,
    [ProductIcon.VEGAN]: Vegan,
    [ProductIcon.VEGETARIAN]: Vegetarian,
    [ProductIcon.XL_STORE]: null,
};

export const mapProductIcons = (icons: ProductCardFragment['icons']) => {
    return icons
        ?.map(icon => {
            if (icon in iconMap) {
                const Component = iconMap[icon];

                return Component ? <Component key={icon} /> : null;
            }
        })
        .filter(Boolean);
};
