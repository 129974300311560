import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { RecipeInteractionProperties } from '@enums/recipe-interaction';
import { AllerhandeRoute } from '@enums/route';
import type { RecipeSummaryFragment } from '@graphql/_generated-operation-types';
import { listNameCreator } from '@helpers/analytics';
import {
    ComponentAction,
    type ComponentMeta,
    emitComponentEvent,
} from '@royalaholddelhaize/ah-analytics';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { RecipeCard } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v3/recipe-card';
import { RecipeProductSuggestionsContext } from '@royalaholddelhaize/ah-ui-recipe-card/src/contexts/recipe-product-suggestions-context';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { type FC, type MouseEvent, useContext } from 'react';

import css from './weekmenu-recipe-card.module.scss';

export const WeekmenuRecipeCard: FC<{ recipe: RecipeSummaryFragment }> = ({
    recipe,
}) => {
    const { handleOnOpen } = useContext(RecipeProductSuggestionsContext);
    const { locale, t } = useTranslations('cms');

    const handleOnCtaClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const cidComponent: ComponentMeta = {
            componentType: 'Regular button',
            componentInnerText: 'Add-to-cart',
            componentTitle: 'Recipecard',
            componentAdditional: recipe.id.toString(),
        };

        emitComponentEvent({
            component: cidComponent,
            componentAction: ComponentAction.CLICK,
        });

        handleOnOpen();
    };

    return (
        <RecipeCard
            as={LinkOrAnchor}
            recipe={recipe}
            locale={locale}
            cta={
                <div className={css.ctaWrapper}>
                    <RegularButton
                        onClick={handleOnCtaClick}
                        data-brand="ah"
                        className={css.cta}
                    >
                        <RegularButtonText data-brand="allerhande">
                            {t('weekmenu.list.cta')}
                        </RegularButtonText>
                    </RegularButton>
                </div>
            }
            cid={{
                listType: RecipeInteractionProperties.RecipeList,
                listName: listNameCreator(),
            }}
            basePath={AllerhandeRoute.Home}
        />
    );
};
