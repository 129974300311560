import type { SpotlightItem } from '@components/cms/spotlight-lane/spotlight-lane.interface';
import { LinkOrAnchor } from '@components/elements/link-or-anchor/link-or-anchor';
import { RecipeInteractionProperties } from '@enums/recipe-interaction';
import { AllerhandeRoute } from '@enums/route';
import {
    ContentAllerhandeSpotlightType,
    type RecipeFragment,
} from '@graphql/_generated-operation-types';
import { listNameCreator } from '@helpers/analytics';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { RecipeCard } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/recipe-card';
import { RecipeVideoCard } from '@royalaholddelhaize/ah-ui-recipe-card/src/components/cards/v2/recipe-video-card';
import type React from 'react';
import type { FC } from 'react';

export interface SpotlightCardProps {
    title?: string;
    index?: number;
    item: SpotlightItem;
}

export const SpotlightCard: FC<React.PropsWithChildren<SpotlightCardProps>> = ({
    item,
    title,
    index,
}) => {
    const { locale } = useTranslations();

    if (
        item.type === ContentAllerhandeSpotlightType.VIDEO_BLUE_BILLYWIG &&
        item.recipeVideo
    ) {
        return (
            <RecipeVideoCard as={LinkOrAnchor} recipeVideo={item.recipeVideo} />
        );
    }

    if (item.type === ContentAllerhandeSpotlightType.RECIPE && item.recipe) {
        return (
            <RecipeCard
                as={LinkOrAnchor}
                locale={locale}
                recipe={item.recipe as RecipeFragment}
                cid={{
                    index,
                    listType: RecipeInteractionProperties.RecipeCarousel,
                    listName: listNameCreator(title),
                }}
                basePath={AllerhandeRoute.Home}
            />
        );
    }
    return null;
};
