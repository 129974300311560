import { ContentComponentType } from '../../enums';
import type {
    ContentBaseCmsComponent,
    ContentPage,
    ContentRootComponent,
} from '../../interfaces';

export const getContentByContainer = (
    content: ContentPage,
    containerName?: string,
): ContentPage | ContentPage<ContentBaseCmsComponent> => {
    if (!containerName || !content?.components) {
        return content;
    }

    const container = content.components.find(
        x =>
            x.type === ContentComponentType.COMPONENT &&
            x.name === containerName,
    ) as ContentRootComponent | undefined;

    const components = container?.items || [];

    return {
        ...content,
        components: components,
    };
};

export const getContent = (
    content: ContentPage,
    containerName?: string,
): ContentPage | ContentPage<ContentBaseCmsComponent> => {
    if (containerName && content) {
        return getContentByContainer(content, containerName);
    }
    return content;
};
