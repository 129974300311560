import type { NetworkStatus } from '@apollo/client';
import { useRecipeVideoAutosuggestionLazyQuery } from '@graphql/_generated-hooks';
import { useCallback } from 'react';

export interface RecipeVideoAutosuggestionHook {
    videoSuggestions: string[];
    fetchRecipeVideoAutosuggestion: (query: string) => void;
    networkStatus: NetworkStatus;
}

export const useRecipeVideoAutosuggestion =
    (): RecipeVideoAutosuggestionHook => {
        const [
            fetchRecipeVideoAutosuggestion,
            { data, networkStatus, previousData },
        ] = useRecipeVideoAutosuggestionLazyQuery({
            fetchPolicy: 'cache-and-network',
            ssr: false,
        });

        const handleFetchRecipeVideoAutosuggestion = useCallback(
            (query: string) =>
                fetchRecipeVideoAutosuggestion({ variables: { query } }),
            [fetchRecipeVideoAutosuggestion],
        );

        return {
            videoSuggestions:
                data?.recipeVideoAutoSuggestions ||
                previousData?.recipeVideoAutoSuggestions ||
                [],
            fetchRecipeVideoAutosuggestion:
                handleFetchRecipeVideoAutosuggestion,
            networkStatus,
        };
    };
