import { FilterChip } from '@components/elements/filter-chip/filter-chip';
import { QuickFilter } from '@components/elements/quick-filter/quick-filter';
import type { FilterItemWithGroup } from '@interfaces/filter';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import type { FC } from 'react';
import css from './quick-filters.module.scss';

export interface QuickFiltersProps {
    filters: FilterItemWithGroup[];
    onSelect: (filter: FilterItemWithGroup) => void;
    onFiltersReset?: () => void;
}

export const QuickFilters: FC<QuickFiltersProps> = ({
    filters = [],
    onSelect,
    onFiltersReset,
}) => {
    const { t } = useTranslations('search');

    const selectedFilters = filters.filter(filter => filter.selected);
    const unselectedFilters = filters.filter(filter => !filter.selected);

    if (filters.length === 0) {
        return null;
    }

    return (
        <div className={css.root}>
            <ul className={css.list} aria-label={t('quick.filters')}>
                {onFiltersReset && selectedFilters.length >= 2 && (
                    <li>
                        <FilterChip
                            label={t('filters.reset')}
                            onClick={onFiltersReset}
                            aria-label={t('filters.reset') || ''}
                            className={css.filterReset}
                        />
                    </li>
                )}

                {[...selectedFilters, ...unselectedFilters].map(filter => {
                    return (
                        <li key={`${filter.group}-${filter.label}`}>
                            <QuickFilter
                                count={filter.count}
                                label={filter.label}
                                name={filter.name}
                                selected={filter.selected}
                                onSelect={selected =>
                                    onSelect({ ...filter, selected })
                                }
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
