'use client';

import { FaqSchema } from '@components/elements/json-ld/faq-schema';
import { RichContent } from '@components/elements/rich-content/rich-content';
import { MAX_CONTAINER_WIDTH } from '@components/layouts/allerhande-page/allerhande-page';
import type { ContentCmsComponentType } from '@graphql/_generated-operation-types';
import { typographyTagFromTaglevel } from '@helpers/cms';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    FAQ_LANE,
    FAQ_LANE_ANSWER,
    FAQ_LANE_QUESTION,
    FAQ_LANE_SHOW_MORE,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import type { ContentBaseCmsComponent } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { ChevronDown16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-down-16';
import {
    Accordion,
    AccordionContent,
    AccordionTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/accordion/accordion';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import { useScrollOnHash } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-scroll-on-hash/use-scroll-on-hash';
import { type FC, useCallback, useRef, useState } from 'react';

import css from './faq-lane.module.scss';

const QUESTIONS_PAGINATION_STEP = 3;

export interface FaqQuestion {
    question: string;
    answer: string;
}

export interface FaqLaneProps extends Omit<ContentBaseCmsComponent, 'type'> {
    type: ContentCmsComponentType;
    faqTitle: string;
    questions: FaqQuestion[];
}

export const FaqLane: FC<FaqLaneProps> = ({
    tagLevel,
    faqTitle,
    questions,
    anchorId,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslations();
    const [questionsLimit, setQuestionsLimit] = useState(
        questions?.length >= 3 ? 3 : questions?.length,
    );
    const items = questions
        ?.map(question => ({
            ...question,
            isExpanded: false,
        }))
        .slice(0, questionsLimit);

    const handleShowMoreClick = useCallback(() => {
        const nextLength =
            items.length + QUESTIONS_PAGINATION_STEP > questions.length
                ? questions.length
                : items.length + QUESTIONS_PAGINATION_STEP;

        setQuestionsLimit(nextLength);
    }, [items, questions]);

    useScrollOnHash({
        ref,
        hash: anchorId,
    });

    if (!questions || !questions.length) {
        return null;
    }

    return (
        <Container
            maxWidth={MAX_CONTAINER_WIDTH}
            as="section"
            ref={ref}
            id={anchorId ? anchorId : undefined}
            {...createTestHook(FAQ_LANE)}
        >
            <FaqSchema questions={questions} />
            <Grid>
                <GridItem xs={4} className={css.root}>
                    <Typography
                        variant="heading-1"
                        as={typographyTagFromTaglevel(tagLevel || 2)}
                        className={css.title}
                    >
                        {faqTitle}
                    </Typography>
                    {items.map((item, idx) => (
                        <Accordion
                            key={`${item.question}-${idx}`}
                            className={css.accordion}
                        >
                            <AccordionTitle
                                className={css.question}
                                {...createTestHook(FAQ_LANE_QUESTION)}
                            >
                                <Typography variant="heading-3">
                                    {item.question}
                                </Typography>
                            </AccordionTitle>
                            <AccordionContent
                                {...createTestHook(FAQ_LANE_ANSWER)}
                            >
                                <RichContent html={item.answer} />
                            </AccordionContent>
                        </Accordion>
                    ))}

                    {items.length < questions.length && (
                        <button
                            className={css.showMore}
                            tabIndex={0}
                            type="button"
                            onClick={handleShowMoreClick}
                            {...createTestHook(FAQ_LANE_SHOW_MORE)}
                        >
                            <span className={css.showMoreContent} tabIndex={-1}>
                                <Typography variant="body-regular" as="span">
                                    {t('actions.moreResults')}
                                </Typography>

                                <ChevronDown16Icon
                                    className={css.showMoreIcon}
                                    size={12}
                                    aria-hidden
                                />
                            </span>
                        </button>
                    )}
                </GridItem>
            </Grid>
        </Container>
    );
};
